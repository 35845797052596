
import { isEmpty } from 'lodash-es';
import Axios from '../lib/axios';
/* globals API_URL */
/* globals NEW_API_URL */

const axios = Axios.create({});

function callApi(request) {
  const {
    endpoint, method, body, params, token, acceptHeader, contentType,
  } = request;

  const config = {
    url: endpoint,
    method,
  };

  if (body) {
    config.data = body;
  }

  if (params) {
    config.params = params;
  }

  config.headers = {};

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (!isEmpty(acceptHeader)) {
    config.headers.Accept = acceptHeader;
  }
  if (!isEmpty(contentType)) {
    config.headers['Content-Type'] = contentType;
  }

  return axios.request(config);
}

const setPassword = (password, token) => {
  const params = {
    endpoint: `${NEW_API_URL}/api/app/user/password`,
    method: 'POST',
    body: { password },
    token,
  };

  return callApi(params);
};

// TODO: rename function
/**
 * @param {JSON} request
 * @param {string} request.email
 * @param {string} request.password
 */
const login = ({ email, password }) => {
  const params = {
    endpoint: `${API_URL}/login`,
    method: 'POST',
    body: { email, password },
  };

  return callApi(params);
};

const recoverPassword = (email) => {
  const params = {
    endpoint: `${API_URL}/forgot-password`,
    method: 'POST',
    body: {
      email,
    },
  };

  return callApi(params);
};

const resetPassword = (password, token) => {
  const params = {
    endpoint: `${API_URL}/reset-password`,
    method: 'POST',
    body: {
      password,
    },
    token,
  };

  return callApi(params);
};

// eslint-disable-next-line no-unused-vars
const getLoans = (filter = '') => {
  const params = {
    endpoint: `${API_URL.replace('/user', '')}/loan`,
    method: 'GET',
    params: {},
  };

  return callApi(params);
};

const getPersonalInfo = () => {
  const params = {
    endpoint: `${API_URL.replace('/user', '')}/personal-info`,
    method: 'GET',
    params: {},
  };

  return callApi(params);
};

/**
 * @param {string} token
 */
const loadUser = token => (
  Promise.all([
    callApi({
      endpoint: `${NEW_API_URL}/api/app/user/personal-info`,
      method: 'GET',
      token,
    }),
    callApi({
      endpoint: `${NEW_API_URL}/api/app/user/loans`,
      method: 'GET',
      token,
    }),
  ])
);

/**
 * Configure token for requests
 * @param {string} token
 */
export const setToken = (token) => {
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.Authorization;
  }
};

export default {
  callApi,
  setPassword,
  login,
  recoverPassword,
  resetPassword,
  getLoans,
  setToken,
  loadUser,
  getPersonalInfo,
};
