import enums from '../../constants/enums';
/* globals API_URL */
/* globals NEW_API_URL */

function setTerm(idLoan, installments) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'installments' },
      body: { installments },
    },
  };
}

function setPaymentDay(idLoan, firstPaymentAt) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'first-payment' },
      body: { firstPaymentAt },
    },
  };
}

function getAmortizationInfo(idLoan) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'options' },
    },
  };
}

function setBank(idLoan, number, bank) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'bank-account' },
      body: { number, bank },
    },
  };
}

function setMati() {
  const response = new Promise(
    (resolve) => {
      setTimeout(() => {
        resolve({
          data: {
          },
        });
      }, 1000);
    },
  );
  return {
    [enums.middleware.PROMISE]: {
      action: response,
    },
  };
}

function setConfirmPasswordData({ id: idLoan, password, signature }) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'sign' },
      body: { password, signature },
    },
  };
}

function uploadFile(request) {
  const form = new FormData();
  form.append('file', request.image);
  form.append('id_type', request.id_type);
  form.append('image_name', request.image_name);
  form.append('loan_request', request.loan_request);

  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${API_URL}/upload-file`,
      method: 'POST',
      body: form,
      contentType: 'multipart/form-data',
    },
  };
}

function startIdentityValidation(loanRequestId, passport) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${API_URL}/start-identity-validation/${loanRequestId}`,
      method: 'POST',
      body: { passport },
    },
  };
}

function changeClabe(request) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${API_URL}/update-bank-account`,
      method: 'POST',
      body: request,
    },
  };
}

function getLoanDetails({ loan }) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${API_URL}/get-loan-details/${loan}`,
      method: 'GET',
    },
  };
}

function getIdValidationInfo({ idLoan }) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'id-validation-urls' },
    },
  };
}

function setIdentityValidationSuccess({ idLoan, documentType }) {
  return {
    [enums.middleware.CALL_API]: {
      endpoint: `${NEW_API_URL}/api/app/user/loans/${idLoan}/sign`,
      method: 'POST',
      params: { operation: 'id-validation-details' },
      body: { type: documentType },
    },
  };
}

export default {
  getAmortizationInfo,
  setTerm,
  setPaymentDay,
  setBank,
  setMati,
  setConfirmPasswordData,
  uploadFile,
  startIdentityValidation,
  changeClabe,
  getLoanDetails,
  getIdValidationInfo,
  setIdentityValidationSuccess,
};
