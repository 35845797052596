import React, { Component } from 'react';
import { bool, func, string, oneOf } from 'prop-types';
import classnames from 'classnames';
import headShape from '@images/head-shape.svg';
import head from '@images/head.svg';
import cardFrame from '@images/card-frame.svg';
import ine from '@images/ine.png';
import ineBack from '@images/ine-back.png';
import passport from '@images/passport.png';
import Button from '../Button';
import { base64toBlobFile, trackCameraEvents } from '../../lib/util';

import './styles.scss';

class MobileCameraInput extends Component {
  static propTypes = {
    isBack: bool,
    frameType: string,
    contentEncoding: oneOf(['blob', 'base64']),
    onSucces: func,
    disableButton: bool,
  }

  static defaultProps = {
    isBack: false,
    frameType: 'INE',
    contentEncoding: 'blob',
    disableButton: false,
  }

  fileInput = null;

  getFrame = () => {
    const { frameType, isBack } = this.props;

    switch (frameType) {
      case 'INE':
        return (
          <div styleName="document-container">
            <img src={cardFrame} alt="Card Frame" styleName="card-frame" />
            <img src={isBack ? ineBack : ine} alt="INE" styleName="document" />
          </div>
        );
      case 'PASSPORT':
        return (
          <div styleName="document-container">
            <img src={cardFrame} alt="Card Frame" styleName="card-frame" />
            <img src={passport} alt="Passport" styleName="passport" />
          </div>
        );
      case 'HEAD':
      default:
        return (
          <React.Fragment>
            <img src={headShape} alt="Head Shape" styleName="head-shape" />
            <img src={head} height={330} width={332} alt="Head" />
          </React.Fragment>
        );
    }
  }

  openCamera  = () => {
    const { frameType, isBack } = this.props;
    this.fileInput.click();
    trackCameraEvents({ frameType, isBack, enableCamera: true });
  }

  setFileInputRef = element => {
    this.fileInput = element;
  }

  onFileInputChange = (e) => {
    const { onSucces, frameType, isBack, contentEncoding } = this.props;
    const file = e.target.files[0];
    if (file) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = function() {
        let newFile = btoa(reader.result)
        if (contentEncoding === 'blob') {
          newFile = base64toBlobFile(btoa(reader.result), 'image/png');
        }
        onSucces(newFile);
      };
      trackCameraEvents({ frameType, isBack, enableCamera: false });
    }
  }

  render() {
    const { frameType, disableButton} = this.props;
    const containerClasses = classnames({
      'camera-mobile-component': true,
      head: frameType === 'HEAD',
    });

    return (
      <div styleName={containerClasses}>
        {this.getFrame()}
        <div>
          <Button
            id="take_picture"
            type="button"
            color="primary"
            onClick={this.openCamera}
            disabled={disableButton}
          >
            Abrir cámara
          </Button>
          <input
            onChange={this.onFileInputChange}
            ref={this.setFileInputRef}
            styleName="no-visible"
            type="file"
            accept="image/*"
            capture
          />
        </div>
      </div>
    );
  }
}

export default MobileCameraInput;
