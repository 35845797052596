import React from 'react';
import {
  string, number, shape,
} from 'prop-types';
import formats from '@utils/formats';
import './style.scss';

const CreditSummary = ({
  user, debtAmount, rate, amortization,
}) => {
  const { installments, paymentsMetadata } = amortization;

  return (
    <div className="clearfix" styleName="credit-summary">
      <div className="row">
        <div styleName="summary-info">
          <span styleName="description">
            Nombre del cliente:
          </span>
          <span styleName="value">
            {`${user.name} ${user.firstSurname} ${user.secondSurname}`}
          </span>
        </div>
        <div styleName="summary-info">
          <span styleName="description">
            Monto del crédito:
          </span>
          <span styleName="value">
            {formats.moneyFormat(debtAmount)}
            {' '}
            MXN
          </span>
        </div>
      </div>

      <div className="row">
        <div styleName="summary-info">
          <span styleName="description">
            Plazo:
          </span>
          <span styleName="value">
            {installments}
            {' '}
            meses
          </span>
        </div>
        <div styleName="summary-info">
          <span styleName="description">
            Frecuencia de pagos:
          </span>
          <span styleName="value">
            Mensual
          </span>
        </div>
      </div>

      <div className="row">
        <div styleName="summary-info">
          <span styleName="description">
            Tasa de Interés Anual:
          </span>
          <span styleName="value">
            {rate}
            % anual sin IVA
          </span>
        </div>
        <div styleName="summary-info">
          <span styleName="description">
            Monto total a pagar:
          </span>
          <span styleName="value">
            {formats.moneyFormat(paymentsMetadata.totalAmount)}
            {' '}
            MXN
          </span>
        </div>
      </div>

    </div>
  );
};

CreditSummary.propTypes = {
  user: shape({}),
  name: string,
  debtAmount: number,
  rate: number,
  amortization: shape({}),
};

CreditSummary.defaultProps = {
  amortization: {},
};

export default CreditSummary;
