import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from '@components';
import './style.scss';

class Modal extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    overModal: PropTypes.node,
    focusInput: PropTypes.bool,
    close: PropTypes.bool,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    focusInput: true,
    close: false,
    onClose: null,
  }

  componentDidMount() {
    const { focusInput } = this.props;

    if (focusInput) {
      const inputs = document.querySelectorAll('.modal-container .input-container input') || [];
      if (inputs.length > 0) {
        inputs[0].focus();
      }
    }
  }

  render() {
    const {
      id,
      className,
      children,
      overModal,
      close,
      onClose,
    } = this.props;

    const classes = classnames({
      modal: true,
      [className]: !!className,
    });

    return (
      <div id={id} styleName={classes}>
        <div styleName="modal-overlay" />
        <div styleName="modal-view">
          <div styleName="over-modal">{overModal}</div>
          <div styleName="modal-container">
            {close && (
              <button type="button" styleName="close" onClick={onClose}>
                <Icon type="close" width="14" height="14" />
              </button>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
