import React, { useEffect } from 'react';
import { string, number, shape } from 'prop-types';
import numeral from 'numeral';
import googleAnalytics from '@lib/googleAnalytics';
import happy from '../../../../images/icon-happy_face-blue.svg';
import './style.scss';

function FinalScreen(props) {
  const {
    className,
    debtAmount,
    user: userInfo,
  } = props;

  useEffect(() => {
    googleAnalytics.visitPage('final-screen');
  });

  return (
    <section id="final-screen-page" styleName="step-form step-10" className={className}>
      <img src={happy} alt="feliz" width="100" height="100" />
      <h1>¡Eso es todo!</h1>
      <h1>Gracias por obtener tu crédito Digitt</h1>
      <p>
        Te hemos enviado tus documentos firmados a
        {' '}
        <b>{userInfo.email}</b>
      </p>
      <p>
        Realizaremos el pago de
        {' '}
        <b>
          {numeral(debtAmount).format('$0,0[.]00')}
          {' '}
          MXN
        </b>
        {' '}
        a tu tarjeta de crédito en un máximo de 24 horas hábiles.
        {' '}
        Te enviaremos el comprobante del pago a tu correo electronico
        {' '}
        y podrás ver reflejado el pago en tu tarjeta de crédito en un máximo de 72 horas hábiles.
        {' '}
        ¡Te recomendamos estar al pendiente y verificar que el saldo de tus tarjetas de crédito sea el correcto!
      </p>
      <p>
        No dudes en contactarnos si tienes alguna duda o comentario al respecto, puedes hacerlo por chat,
        {' '}
        llamando al
        {' '}
        <b>(33) 4162-2277</b>
        {' '}
        o escribiendo a
        {' '}
        <a href="mailto:hola@digitt.com" styleName="mailto">
          hola@digitt.com.
        </a>
      </p>
    </section>
  );
}

FinalScreen.propTypes = {
  className: string,
  debtAmount: number.isRequired,
  user: shape({
    id: number,
  }).isRequired,
};

export default FinalScreen;
