import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './style.scss';

const ApplicationError = () => (
  <Grid container styleName="application-error">
    <Grid xs={12} item>
      <Typography variant="display3" align="center" gutterBottom styleName="title">
        Ups, tuvimos un error.
      </Typography>
      <Typography variant="subheading" color="textSecondary" align="center" gutterBottom styleName="text">
        Intenta refrescar esta página.
        {' '}
        Si el problema continua por favor contáctanos, puedes escribirnos a través del chat,
        {' '}
        por correo a
        {' '}
        <a href="mailto:hola@digitt.com">
          hola@digitt.com
        </a>
        {' '}
        o llamando al
        {' '}
        <b>
          (33) 4162-2277
        </b>
        .
      </Typography>
    </Grid>
  </Grid>
);

export default ApplicationError;
