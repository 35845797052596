import React, { Component } from 'react';
import { shape, func } from 'prop-types';
import Pad from 'signature_pad'
import reload from '../../../images/reload.png';
import './style.scss';

class SignaturePad extends Component {
  static propTypes = {
    input: shape({
      onChange: func
    })
  }

  signaturePad = null;

  componentDidMount() {
     this.signaturePad = new Pad(this.padElement, {
      minWidth: 1,
      maxWidth: 1,
      onEnd: this.onEnd
    });
  }

  onEnd = () => {
    const { input } = this.props;
    input.onChange(this.signaturePad.toDataURL());
  }

  undo = () => {
    const { input } = this.props;

    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
    }

    if (data.length) {
      input.onChange(this.signaturePad.toDataURL());
    }
    else {
      input.onChange('');
    }
  }

  render() {
    return (
      <div styleName="signature-pad">
        <div styleName="signature-wrapper">
          <canvas id="signature-canvas" height="230" width="308" ref={ref => this.padElement = ref} />
          <div styleName="reload" onClick={this.undo}>
            <img src={reload} />
          </div>
        </div>
      </div>
    );
  }
}

export default SignaturePad;
