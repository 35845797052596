import React, { useState, useEffect } from 'react';
import fileUploader from '@libs/api-fileuploader';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import googleAnalytics from '@lib/googleAnalytics';
import Mixpanel from '@lib/mixpanel';
import { scroller } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import {
  DesktopCameraInput, MobileCameraInput,
  OverlayMessage, ErrorMessage,
} from '@components';
import './style.scss';

/*
  <Field
    name="passport"
    labelText="Pasaporte"
    styleName="passport-dropdown"
    selectedOption={DEFAULT_PASSPORT_OPTION}
    options={PASSPORT_OPTIONS}
    touched
    required
    component={Dropdown}
  />
 */

function IDPicture(props) {
  const isForeigner = false;
  const [isINE, setIsINE] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showBackINE, setShowBackINE] = useState(false);
  const [errorRequest, setErrorRequest] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [cameraElement, setCameraElement] = useState(null);

  const {
    className,
    error,
    token,
    loanRequest,
    onNext,
  } = props;

  const setCameraElementRef = (element) => {
    setCameraElement(element);
  };

  useEffect(() => {
    googleAnalytics.visitPage('set-id-photo-info');
    return () => {
      if (cameraElement && cameraElement.onPauseCamera) {
        cameraElement.onPauseCamera();
      }
    };
  }, []);

  const resetCamera = () => {
    if (cameraElement && cameraElement.resetIDFlow) {
      cameraElement.resetIDFlow();
    }

    scroller.scrollTo('app', {
      duration: 0,
      delay: 0,
    });
  };

  const onSuccess = async (file) => {
    setShowOverlay(false);
    setIsFetching(true);
    try {
      await fileUploader({
        image: file,
        id_type: isINE ? 'VID' : 'PP',
        image_name: showBackINE ? 'IMAGE_BACK' : 'IMAGE_FRONT',
        loan_request: loanRequest,
        token,
      });
      if (isINE && !showBackINE) {
        setShowBackINE(true);
        setIsINE(true);
        resetCamera();
      } else {
        Mixpanel.track('app_guaranteed_id_step');
        onNext();
      }
    } catch (err) {
      setErrorRequest(file);
      setShowOverlay(true);
    }
    setIsFetching(false);
  };

  const tryAgain = () => {
    onSuccess(errorRequest);
  };

  return (
    <section styleName="step-form front-id-picture" className={className}>
      <h1>
        {!showBackINE
          ? 'Necesitamos verificar tu identidad:'
          : 'Toma una fotografía de la parte trasera de tu INE o IFE'}
      </h1>
      <ErrorMessage error={error} />
      <div styleName="camera-input-container">
        {!showBackINE && (
          <div>
            <div styleName="id-selector">
              <div
                styleName={classnames({ option: true, active: isINE })}
                onClick={() => setIsINE(true)}
                role="button"
              >
                INE/IFE
              </div>
              <div
                styleName={classnames({ option: true, active: !isINE })}
                onClick={() => setIsINE(false)}
                role="button"
              >
                Pasaporte
              </div>
            </div>
            <h2>
              {IDPicture.getMessage(isINE, isForeigner)}
            </h2>
          </div>
        )}
        {!isINE && isForeigner && (
          <div>
            <p>pasaporte</p>
          </div>
        )}
        {!isMobile && (
          <div>
            <DesktopCameraInput
              frameType={isINE ? 'INE' : 'PASSPORT'}
              onSucces={onSuccess}
              notInitCamera
              ref={setCameraElementRef}
              isBack={showBackINE}
              disableButton={isFetching}
            />
          </div>
        )}
        {isMobile && (
          <div>
            <MobileCameraInput
              frameType={isINE ? 'INE' : 'PASSPORT'}
              onSucces={onSuccess}
              isBack={showBackINE}
              disableButton={isFetching}
            />
          </div>
        )}
      </div>
      {showOverlay && <div><OverlayMessage onButtonClick={tryAgain} /></div>}
    </section>
  );
}

IDPicture.getMessage = (isINE, isForeigner) => {
  if (isINE) {
    return 'Toma una fotografía de la parte frontal de tu INE o IFE.';
  }
  if (!isForeigner) {
    return 'Toma una fotografía de tu pasaporte mexicano vigente.';
  }
  return 'Selecciona el pais al cual pertenece tu pasaporte vigente y tómale una fotografía.';
};

IDPicture.propTypes = {
  className: PropTypes.string,
  error: PropTypes.arrayOf(PropTypes.string),
  loanRequest: PropTypes.number,
  token: PropTypes.string,
  onNext: PropTypes.func,
};

export default IDPicture;
