export default function base64toBlob(base64Data, contentType = '') {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  let blob = null;

  try {
    blob = new Blob(byteArrays, { type: contentType });
  } catch (e) {
    // TypeError old chrome and FF
    window.BlobBuilder = window.BlobBuilder
    || window.WebKitBlobBuilder
    || window.MozBlobBuilder
    || window.MSBlobBuilder;

    if (e.name === 'TypeError' && window.BlobBuilder) {
      const bb = new window.BlobBuilder();
      bb.append(byteArrays);
      blob = bb.getBlob(contentType);
    } else if (e.name === 'InvalidStateError') {
      // InvalidStateError (tested on FF13 WinXP)
      blob = new Blob(byteArrays, { type: contentType });
    } else {
      // We're screwed, blob constructor unsupported entirely
      /* eslint-disable-next-line no-console */
      console.error('blob constructor unsupported entirely');
    }
  }

  return blob;
}
