import React from 'react';
import PropTypes from 'prop-types';

import * as icons from './icons';

function Icon(props) {
  const { id, type, width, height, ...others } = props;
  return <img {...others} id={id} src={icons[type]} alt={type} width={width} height={height} />;
}

Icon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(icons)),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
  height: 22,
  width: 22,
};

export default Icon;
