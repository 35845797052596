import validate from '@/lib/validate';

const constraints = {
  email: {
    presence: true,
    email: true,
  },
};

export default function validateForm(values) {
  return validate(values, constraints);
}
