import React, { PureComponent } from 'react';
import { string, number, shape } from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import happy from '../../../../images/icon-happy_face-blue.svg';
import './style.scss';

class FinalScreen extends PureComponent {
  static propTypes = {
    className: string,
    userInfo: shape({
      id: number,
    }).isRequired,
    bank: string,
    clabe: string,
  }

  componentDidMount() {
    googleAnalytics.visitPage('final-screen-new-clabe');
  }

  render() {
    const {
      className, bank, clabe, userInfo,
    } = this.props;

    return (
      <section id="final-screen-page" styleName="step-form step-10" className={className}>
        <img src={happy} alt="feliz" width="100" height="100" />
        <h1>¡Eso es todo!</h1>
        <p>
          Te hemos enviado tu documento de Autorización de Domiciliación firmado a
          {' '}
          <b>{userInfo.email}</b>
        </p>
        <p>
          La cuenta a la que se realizarán los cobros de tu crédito Digitt ha sido cambiada
          {' '}
          a la cuenta
          {` ${bank} `}
          con CLABE interbancaria terminación
          {` -${clabe.substr(clabe.length - 4)}.`}
        </p>
        <p>
          No dudes en contactarnos si tienes alguna duda o comentario al respecto, puedes hacerlo por chat,
          {' '}
          llamando al
          {' '}
          <b>(33) 4162-2277</b>
          {' '}
          o escribiendo a
          {' '}
          <a href="mailto:hola@digitt.com" styleName="mailto">
            hola@digitt.com.
          </a>
        </p>
      </section>
    );
  }
}

export default FinalScreen;
