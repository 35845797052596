import moment from 'moment';
import formats from '@utils/formats';

moment.locale('es');

export default {
  desktopColumns: {
    number: {
      displayText: 'No.',
    },
    due_date: {
      displayText: 'Fecha de vencimiento',
    },
    capital_amortization: {
      displayText: 'Amortización de Capital (MXN)',
    },
    interest: {
      displayText: 'Intereses (MXN)',
    },
    iva: {
      displayText: 'IVA (MXN)',
    },
    payment: {
      displayText: 'Pago Total del Período (MXN)',
    },
    outstanding_balance: {
      displayText: 'Saldo Insoluto (MXN)',
    },
  },
  mobileColumns: {
    number: {
      displayText: 'No.',
    },
    payment: {
      displayText: 'Mensualidad (MXN)',
      isExpandible: true,
    },
    outstanding_balance: {
      displayText: 'Saldo Insoluto (MXN)',
    },
  },
  format: {
    capital_amortization: cell => formats.moneyFormat(cell.capital_amortization),
    payment: cell => formats.moneyFormat(cell.payment),
    due_date: cell => moment(cell.due_date, 'YYYY-MM-DDThh:mm:ss').format('DD/MMM/YYYY').replace('.', ''),
    interest: cell => formats.moneyFormat(cell.interest),
    iva: cell => formats.moneyFormat(cell.iva),
    totalPayment: cell => formats.moneyFormat(cell.totalPayment),
    outstanding_balance: cell => formats.moneyFormat(cell.outstanding_balance),
  },
};
