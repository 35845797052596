import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

class ModalAnimation extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const {
      children,
    } = this.props;

    return (<CSSTransition
      styleName="modal"
      timeout={{ enter: 250, exit: 250 }}
    >
      {children}
    </CSSTransition>);
  }
}

export default ModalAnimation;
