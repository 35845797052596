import Mixpanel from '@lib/mixpanel';
import base64toBlob from './base64toBlob';

export function findIndex(arr = [], value, key = 'id') {
  let index = -1;

  for (let i = 0, len = arr.length; i < len; i += 1) {
    if (arr[i][key] === value) {
      index = i;
      break;
    }
  }

  return index;
}

export function animate(callback, from, to, duration) {
  const start = new Date().getTime();

  let stopAnimation = () => {};
  const timer = setInterval(() => {
    const timePassed = new Date().getTime() - start;
    const percentage = timePassed / duration;
    const diff = to - from;
    const value = from + diff * percentage;

    if (timePassed >= duration) {
      callback(to, 1);
      stopAnimation();
      return;
    }

    callback(value, percentage);
  }, 20);

  stopAnimation = () => {
    clearInterval(timer);
  };

  return stopAnimation;
}

export function formatMoney(value, fixed = 0) {
  const re = new RegExp(/\d(?=(\d{3})+$)/, 'g');
  const hasDot = /\./g.test(value);
  const [num, decimals] = String(value).split('.');
  const money = num.replace(re, '$&,');

  if (hasDot && fixed > 0) {
    return `${money}.${decimals.substr(0, fixed)}`;
  }

  return money;
}

export function normalizeMoney(value) {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d.]/g, '');
  const hasDot = /\./g.test(onlyNums);
  const [m, decimals] = onlyNums.split('.');
  const money = formatMoney(parseFloat(m));

  if (onlyNums.length === 0) {
    return '';
  }

  if (hasDot) {
    return `${money}.${decimals.substr(0, 2)}`;
  }

  return money;
}

export function normalizePhone(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const m = numbers.match(/^(\d{1,2})(\d{1,4})?(\d{1,4})?/);
  const { length } = numbers;

  if (length > 6) {
    return `(${m[1]}) ${m[2]}-${m[3]}`.trim();
  }
  if (length > 2) {
    return `(${m[1]}) ${m[2]}`.trim();
  }

  return numbers;
}

export function normalizeCP(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const { length } = numbers;

  if (length > 5) {
    return numbers.substr(0, 5);
  }

  return numbers;
}

export function normalizeMXDate(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  const m = numbers.match(/^(\d{1,2})(\d{1,2})?(\d{1,4})?/);
  const { length } = numbers;

  if (length > 4) {
    return `${m[1]}/${m[2]}/${m[3]}`.trim();
  }
  if (length > 2) {
    return `${m[1]}/${m[2]}`.trim();
  }

  return numbers;
}

export function normalizeCard(value, previousValue) {
  const newValue = value.replace(/\s/g, '');

  if (!newValue) {
    return '';
  }

  const isInteger = /^[0-9]*$/.test(newValue);

  if (!isInteger || newValue.length > 16) {
    return previousValue;
  }

  return newValue.replace(/(.{4})/g, '$1 ').trim();
}

export function normalizeNumbers(value) {
  const numbers = value.replace(/[^\d.]/g, '');
  return numbers;
}

export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function debounce(func, wait, immediate) {
  let timeout;
  return (...args) => {
    const context = this;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }

  return false;
}

export function hasClass(el, className) {
  if (el && el.classList) {
    return el.classList.contains(className);
  }
  const match = el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`));
  return match !== null;
}

export function addClass(el, className) {
  const element = Object.assign({}, el);
  if (el.classList) {
    element.classList.add(className);
  } else if (!hasClass(el, className)) {
    element.className += ` ${className}`;
  }
  return element;
}

export function removeClass(el, className) {
  const element = Object.assign({}, el);
  if (element.classList) {
    element.classList.remove(className);
  } else if (hasClass(el, className)) {
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`);
    element.className = el.className.replace(reg, ' ');
  }
  return element;
}

export function stripAccents(str) {
  const reAccents = /[àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]/g;
  const replacements = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY';
  return str.replace(reAccents, match => replacements[reAccents.source.indexOf(match)]);
}

export function base64toBlobFile(base64, mimeType) {
  return base64toBlob(base64, mimeType);
}

export function trackCameraEvents({ frameType, isBack, enableCamera = true }) {
  const action = enableCamera ? 'enable_camera' : 'take_picture';
  switch (frameType) {
    case 'INE':
      Mixpanel.track(`app_id_ine_${action}_${isBack ? 'back' : 'front'}`);
      break;
    case 'PASSPORT':
      Mixpanel.track(`app_id_passport_${action}_${isBack ? 'back' : 'front'}`);
      break;
    case 'HEAD':
    default:
      Mixpanel.track(`app_selfie_${action}`);
      break;
  }
}
