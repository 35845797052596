import { types } from '../actions/digitalSignature';
import initialState from './iniitalState';

const {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_IS_LOADING,
  SET_IS_FETCHING,
  SET_IS_INITIALIZING,
  AMORTIZATION_INFO_SUCCESS,
  AMORTIZATION_INFO_FAIL,
  SET_TERMS_OPTIONS,
  SET_DIGITAL_SIGNATURE_PROPS,
  SET_ERROR,
  BACK_STEP,
  NEXT_STEP,
  LOGOUT,
  OPEN_MODAL,
  CLOSE_MODAL,
  MODAL_IS_LOADING,
  SET_MODAL_ERROR,
  SET_ACTIVE_LOANS,
  ON_UPLOAD_FILE_SUCCESS,
  SET_STEP,
  ID_VALIDATION_INFO_FETCHING,
  SET_ID_VALIDATION_INFO,
  ID_VALIDATION_INFO_FETCHING_ERROR,
} = types;

const defaultModal = {
  showModal: false,
  loading: false,
  error: null,
};

export default (state = initialState.digitalSignature, action = {}) => {
  const newState = { ...state };

  switch (action.type) {
    case '@@INIT':
    case '@@redux/INIT':
      Object.assign(newState, {
        error: null,
        loading: false,
      });
      break;
    case SET_PASSWORD:
    case SET_IS_LOADING:
      Object.assign(newState, {
        loading: true,
      });
      break;
    case SET_IS_FETCHING:
      Object.assign(newState, {
        isFetching: true,
      });
      break;
    case SET_IS_INITIALIZING:
      Object.assign(newState, {
        isInitializing: true,
      });
      break;
    case BACK_STEP:
      Object.assign(newState, {
        step: action.step,
        error: null,
      });
      break;
    case NEXT_STEP:
      Object.assign(newState, {
        step: newState.step + 1,
        error: null,
      });
      break;
    case SET_PASSWORD_SUCCESS:
      Object.assign(newState, {
        loading: false,
        error: null,
        step: 2,
      });
      break;
    case SET_ERROR:
      Object.assign(newState, {
        error: action.digitalSignature.error,
        loading: false,
        isFetching: false,
        isInitializing: false,
      });
      break;
    case AMORTIZATION_INFO_FAIL:
      Object.assign(newState, {
        error: action.digitalSignature.error,
        isFetching: false,
      });
      break;
    case AMORTIZATION_INFO_SUCCESS:
      Object.assign(newState, {
        amortizationOptions: action.digitalSignature.amortizationOptions,
        isFetching: false,
        error: null,
      });
      break;
    case SET_TERMS_OPTIONS:
      Object.assign(newState, {
        termsOptions: action.digitalSignature.termsOptions,
        isFetching: false,
        error: null,
      });
      break;
    case SET_DIGITAL_SIGNATURE_PROPS:
      Object.assign(newState, {
        loading: false,
        error: null,
        ...action.digitalSignature,
      });
      break;
    case LOGOUT:
      Object.assign(newState, {
        step: 1,
      });
      break;
    case MODAL_IS_LOADING:
      Object.assign(newState, {
        [action.modalName]: {
          ...defaultModal,
          ...newState[action.modalName],
          loading: true,
        },
      });
      break;
    case OPEN_MODAL:
      Object.assign(newState, {
        [action.modalName]: {
          ...defaultModal,
          ...newState[action.modalName],
          showModal: true,
        },
      });
      break;
    case CLOSE_MODAL:
      Object.assign(newState, {
        [action.modalName]: {
          ...defaultModal,
        },
      });
      break;
    case SET_MODAL_ERROR:
      Object.assign(newState, {
        [action.modalData.name]: Object.assign({},
          newState[action.modalName],
          {
            loading: false,
            error: action.modalData.error,
            showModal: true,
          }),
      });
      break;
    case SET_ACTIVE_LOANS:
      Object.assign(newState, {
        activeLoans: action.data.activeLoans,
        isInitializing: false,
      });
      break;
    case ON_UPLOAD_FILE_SUCCESS:
      Object.assign(newState, {
        isFetching: false,
      });
      break;
    case SET_STEP:
      Object.assign(newState, {
        step: action.step,
        error: null,
        errorIdValidation: false,
      });
      break;
    case ID_VALIDATION_INFO_FETCHING:
      return {
        ...newState,
        isFetchingIdValidationInfo: true,
      };
    case SET_ID_VALIDATION_INFO:
      return {
        ...newState,
        idValidationInfo: action.data,
        isFetchingIdValidationInfo: false,
      };
    case ID_VALIDATION_INFO_FETCHING_ERROR:
      return {
        ...newState,
        isFetchingIdValidationInfo: false,
        idValidationInfo: null,
        error: [''],
      };
    default:
      return state;
  }

  return newState;
};
