import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

class ModalFooter extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  }

  render() {
    const {
      className,
      children,
    } = this.props;

    const classes = classnames({
      'modal-footer': true,
      [className]: !!className,
    });

    return (<div styleName={classes}>
      {children}
    </div>);
  }
}

export default ModalFooter;
