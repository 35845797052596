export default {
  digitalSignature: {
    token: null,
    loading: false,
    isFetching: false,
    isInitializing: false,
    isFetchingIdValidationInfo: false,
    error: null,
    step: 1,
    amortizationInfo: {
      amortization: {
        payments: [],
      },
      user: {},
    },
    termsOptions: [],
    term: 0,
    paymentTerm: 0,
    firstPaymentDate: '',
    clabe: '',
    bank: '',
    loanRequest: 0,
    user: '',
    loan: '',
    userInfo: {},
    passwordModal: {
      showModal: null,
      loading: false,
      error: null,
    },
    activeLoans: [],
    idValidated: false,
    errorIdValidation: false,
    idValidationInfo: null,
  },
};
