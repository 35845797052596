import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const TableBody = (props) => {
	const {
		className,
		children,
	} = props;

	const classes = classnames({
		'table-body': true,
		[className]: className !== null,
	});

	return (
		<tbody styleName={classes}>
			{children}
		</tbody>
	);
};

TableBody.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

TableBody.defaultProps = {
	className: null,
};

export default TableBody;
