import apiUtils from '@utils/apiUtils';
/* globals API_URL */

export default async function uploadFile(request) {
  const form = new FormData();
  form.append('file', request.image);
  form.append('id_type', request.id_type);
  form.append('image_name', request.image_name);
  form.append('loan_request', request.loan_request);

  try {
    const resp = await apiUtils.callApi({
      endpoint: `${API_URL}/upload-file`,
      method: 'POST',
      body: form,
      contentType: 'multipart/form-data',
      token: request.token,
    });
    return resp;
  } catch (error) {
    throw error;
  }
}
