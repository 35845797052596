function Create(engine) {
  function getAmortizationInfo(idLoan) {
    if (engine === undefined || engine.getAmortizationInfo === undefined) {
      throw new Error('getAmortizationInfo not implemented on selectedEngine');
    }
    return engine.getAmortizationInfo(idLoan);
  }

  function setTerm(idLoan, term) {
    if (engine === undefined || engine.setTerm === undefined) {
      throw new Error('setTerm not implemented on selectedEngine');
    }
    return engine.setTerm(idLoan, term);
  }

  function setPaymentDay(idLoan, firstPaymentDate) {
    if (engine === undefined || engine.setPaymentDay === undefined) {
      throw new Error('setPaymentDay not implemented on selectedEngine');
    }
    return engine.setPaymentDay(idLoan, firstPaymentDate);
  }

  function setBank(idLoan, clabe, bank) {
    if (engine === undefined || engine.setBank === undefined) {
      throw new Error('setBank not implemented on selectedEngine');
    }
    return engine.setBank(idLoan, clabe, bank);
  }

  function setMati() {
    if (engine === undefined || engine.setMati === undefined) {
      throw new Error('setMati not implemented on selectedEngine');
    }
    return engine.setMati();
  }

  function setConfirmPasswordData(request) {
    if (engine === undefined || engine.setConfirmPasswordData === undefined) {
      throw new Error('setConfirmPasswordData not implemented on selectedEngine');
    }
    return engine.setConfirmPasswordData(request);
  }

  function uploadFile(request) {
    if (engine === undefined || engine.uploadFile === undefined) {
      throw new Error('uploadFile not implemented on selectedEngine');
    }
    return engine.uploadFile(request);
  }

  function startIdentityValidation(loanRequestId, passport) {
    if (engine === undefined || engine.startIdentityValidation === undefined) {
      throw new Error('startIdentityValidation not implemented on selectedEngine');
    }
    return engine.startIdentityValidation(loanRequestId, passport);
  }

  function changeClabe(request) {
    if (engine === undefined || engine.changeClabe === undefined) {
      throw new Error('changeClabe not implemented on selectedEngine');
    }
    return engine.changeClabe(request);
  }

  function getLoanDetails(request) {
    if (engine === undefined || engine.getLoanDetails === undefined) {
      throw new Error('getLoanDetails not implemented on selectedEngine');
    }
    return engine.getLoanDetails(request);
  }

  function getIdValidationInfo(request) {
    if (engine === undefined || engine.getIdValidationInfo === undefined) {
      throw new Error('getIdValidationInfo not implemented on selectedEngine');
    }
    return engine.getIdValidationInfo(request);
  }

  function setIdentityValidationSuccess(request) {
    if (engine === undefined || engine.setIdentityValidationSuccess === undefined) {
      throw new Error('setIdentityValidationSuccess not implemented on selectedEngine');
    }
    return engine.setIdentityValidationSuccess(request);
  }

  return {
    getAmortizationInfo,
    setTerm,
    setPaymentDay,
    setBank,
    setMati,
    setConfirmPasswordData,
    uploadFile,
    startIdentityValidation,
    changeClabe,
    getLoanDetails,
    getIdValidationInfo,
    setIdentityValidationSuccess,
  };
}

export default {
  Create,
};
