import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import logUtils from '@utils/logUtils';
import rootReducer, { onCreated } from './reducers';
import apiMiddleware from './middleware/apiMiddleware';
import promiseMiddleware from './middleware/promiseMiddleware';
import browserHistory from '../browserHistory';
/* eslint-disable no-underscore-dangle */

let composeEnhancers;

if (process.env.NODE_ENV !== 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
} else {
  composeEnhancers = compose;
}

const middlewares = [
  apiMiddleware.jwtApiCall,
  promiseMiddleware.promise,
  thunk,
  routerMiddleware(browserHistory),
];

if (process.env.NODE_ENV === 'production') {
  middlewares.push(logUtils.sentryMiddleware);
}

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(reduxImmutableStateInvariant());
}

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

onCreated(store);

export default store;
