import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { indexOf, findIndex } from 'lodash-es';
import ScrollView from '../ScrollView';
import iconArrow from '../../../images/icon-arrow-down.svg';
import { addClass, removeClass, debounce, stripAccents } from '../../lib/util';
import './style.scss';

const optionShape = {
  value: PropTypes.string,
  label: PropTypes.string,
};

@onClickOutside
class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    alwaysOpen: PropTypes.bool,
    className: PropTypes.string,
    labelText: PropTypes.node,
    defaultOptionText: PropTypes.node,
    optionsOnTop: PropTypes.bool,
    disabled: PropTypes.bool,
    maxHeight: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape(optionShape)).isRequired,
    selectedOption: PropTypes.shape(optionShape),
    showArrows: PropTypes.bool,
    boldLabel: PropTypes.bool,
    touched: PropTypes.bool,
    input: PropTypes.shape({
      // ReduxForm Prop
      name: PropTypes.string,
      onChange: PropTypes.func,
    }),
    template: PropTypes.func,
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
    errorText: PropTypes.node,
  }

  static defaultProps = {
    className: '',
    defaultOptionText: 'Opciones',
    selectedOption: {},
    showArrows: true,
    template: option => option.label,
    optionsOnTop: false,
    disabled: false,
    touched: false,
    maxHeight: 420,
    errorText: 'Este campo es obligatorio.',
  }

  constructor(props) {
    super(props);

    this.state = {
      showOptions: props.alwaysOpen || false,
      touched: props.touched,
    };

    this.debounce = debounce(this.searchOption, 300);
    this.textToSearch = '';
    this.listRef = null;
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  areNaveKeys = (e) => e.which === 38 || e.which === 40 || e.which === 9;

  isTabOrEnterKey = (e) => e.which === 13 || e.which === 32;

  isInAlphabet = (e) => {
    const keyChar = String.fromCharCode(e.which);
    var regex = /^[a-zA-Z]+$/
    return regex.test(keyChar);
  }

  onKeyDown = (e) => {
    const { alwaysOpen } = this.props;
    const { showOptions } = this.state;
    const { areNaveKeys, isTabOrEnterKey, isInAlphabet, navigateList, clickActive } = this;

    if (e && (areNaveKeys(e) || isTabOrEnterKey(e) || isInAlphabet(e)) && (alwaysOpen || showOptions)) {

      e.preventDefault();

      if (areNaveKeys(e)) {
        navigateList(e);
      }

      if (isTabOrEnterKey(e)) {
        clickActive(e);
      }

      if (isInAlphabet(e)) {
        this.textToSearch += String.fromCharCode(e.which);
        this.debounce();
      }
    }
  }

  clickActive = (e) => {
    const activeItem = this.listRef.querySelector('.active');
    if (activeItem) {
      activeItem.querySelector('a').click();
    }
  }

  navigateList = (e) => {
    const listElements = this.listRef.children;
    const selectedItem = this.listRef.querySelector('.active');
    const selectedIndexOf = indexOf(listElements, selectedItem);

    if (selectedItem) {
      removeClass(selectedItem, 'active');
    }

    if (e.which === 40 || (e.which === 9 && !e.shiftKey)) {
      if (selectedItem && selectedIndexOf < (listElements.length - 1)) {
        this.setActiveElementsProps(listElements[selectedIndexOf + 1], selectedIndexOf + 1);
      } else {
        this.setActiveElementsProps(listElements[0], 0);
      }
    } else {
      if (selectedItem && selectedIndexOf !== 0) {
        this.setActiveElementsProps(listElements[selectedIndexOf - 1], selectedIndexOf - 1);
      } else {
        this.setActiveElementsProps(listElements[listElements.length - 1], listElements.length - 1);
      }
    }
  }

  setActiveElementsProps = (currentElement, currentIndexOf) => {
    addClass(currentElement, 'active');
    currentElement.querySelector('a').focus();
    this.scrollToElement(currentElement, currentIndexOf);
  }

  scrollToElement = (currentElement, currentIndexOf) => {
    const elementHeight = currentElement.clientHeight;
    const scrollTop = this.listRef.parentElement.scrollTop;
    const viewport = scrollTop + this.listRef.parentElement.clientHeight;
    const elementOffset = elementHeight * currentIndexOf;

    if (elementOffset < scrollTop || (elementOffset + elementHeight) > viewport) {
      this.listRef.parentElement.scrollTop = elementOffset;
    }
  }

  searchOption = () => {
    const regex = `^${this.textToSearch.toLowerCase()}`;
    const { options } = this.props;
    const listElements = this.listRef.children;
    const selectedItem = this.listRef.querySelector('.active');

    const matchIndex = findIndex(options, option => {
      return stripAccents(option.label).toLowerCase().match(regex) && !option.disabled;
    });

    if (matchIndex >= 0) {

      if (selectedItem) {
        removeClass(selectedItem, 'active')
      }

      this.setActiveElementsProps(listElements[matchIndex], matchIndex);
    }

    this.textToSearch = '';
  }

  onFocus = (e) => {
    // this.toggleMenu(e);
  }

  handleClickOutside = () => {
    const { alwaysOpen, onToggle } = this.props;
    const { showOptions } = this.state;

    if (showOptions && onToggle) {
      onToggle();
    }

    this.setState({
      showOptions: alwaysOpen || false,
    });
  }

  toggleMenu = (e) => {
    const { alwaysOpen, onToggle, options, disabled } = this.props;
    const { showOptions } = this.state;

    e.stopPropagation();

    if (options && options.length === 0) {
      // no open a empy dropdown
      return;
    }

    if (onToggle) {
      onToggle();
    }

    if (e.type === 'focus') {
      this.setState({
        showOptions: true,
        touched: true,
      });
    } else {
      this.setState({
        showOptions: (alwaysOpen || !showOptions) && !disabled,
        touched: true,
      });
    }
  }

  renderLabel() {
    const { input, options, selectedOption, defaultOptionText, template } = this.props;
    const { showOptions } = this.state;
    let option = {
      ...selectedOption,
    };

    if (input) {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].value === input.value.value) {
          option = options[i];
          break;
        }
      }
    }

    let label = option.label ? template(option, true) : defaultOptionText;

    if (showOptions) {
      label = defaultOptionText;
    }

    return label;
  }

  renderOption = (option) => {
    const { input, onChange, selectedOption, template } = this.props;
    const { disabled, action } = option;

    let value = selectedOption.value;

    if (input) {
      value = input.value;
    }

    const active = value.value === option.value;
    const listClassNames = classnames({
      active,
      disabled,
    });

    let onClick = (e) => {
      this.toggleMenu(e);

      const selectedItem = this.listRef.querySelector('.active');

      if (selectedItem) {
        removeClass(selectedItem, 'active')
      }

      if (input && input.onChange) {
        input.onChange(option);
      } else if (onChange) {
        onChange(option);
      }

    };

    if (typeof action === 'function') {
      onClick = action;
    }

    return (
      <li key={option.value} className={listClassNames}>
        <a disabled={disabled} onClick={disabled ? undefined : onClick}>
          {template(option, active)}
        </a>
      </li>
    );
  }

  renderArrow() {
    const { showArrows } = this.props;

    if (!showArrows) {
      return null;
    }

    return <img styleName="arrow" src={iconArrow} width="16" height="16" alt="icon" />;
  }

  setListRef = (e) => {
    this.listRef = e;
  }

  render() {

    const { id, className, labelText, options, input, optionsOnTop, disabled,
      maxHeight, boldLabel, selectedOption, errorText } = this.props;

    const { showOptions, touched } = this.state;
    const containerStyleNames = classnames({
      'dropdown-container': true,
      disabled,
      touched,
    });
    const styleNames = classnames({
      dropdown: true,
      open: showOptions,
      'options-on-top': optionsOnTop,
    });
    const labelStylenames = classnames({
      bold: boldLabel,
    });
    let name = this.props.name;

    if (input) {
      name = input.name;
    }

    const hasError = JSON.stringify(selectedOption) === JSON.stringify({});

    return (
      <div id={id} className={className} styleName={containerStyleNames} onFocus={this.onFocus}>
        {labelText && <label styleName={labelStylenames} htmlFor={name}>{labelText}</label>}

        <div styleName={styleNames}>
          <button type="button" onClick={this.toggleMenu} id={name} name={name}>
            <span styleName="dropdown-label">
              {touched && this.renderLabel()}
              {this.renderArrow()}
            </span>
          </button>

          <div styleName="dropdown-options">
            <ScrollView autoHeightMax={maxHeight}>
              <ul ref={this.setListRef}>
                {options && options.map(option => this.renderOption(option))}
              </ul>
            </ScrollView>
          </div>
        </div>

        {hasError && <span id={`${id}-error`} styleName="input-error-text">{errorText}</span>}
      </div>
    );
  }
}

export default Dropdown;
