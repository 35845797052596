import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import blacklist from 'blacklist';
import './style.scss';

function handleNumericKeyDown(e) {
  const { key } = e;

  if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'ArrowLeft' || key === 'ArrowRight') {
    e.preventDefault();
  }
}

function handleNumericKeyUp(e) {
  const { target } = e;

  if (target.selectionStart) {
    target.selectionStart = target.value.length;
    target.selectionEnd = target.value.length;
  }
}

class TextField extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errorText: PropTypes.node,
    labelText: PropTypes.node,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    multiLine: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    fileName: PropTypes.string,
    isActive: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
  }

  static defaultProps = {
    multiLine: false,
    type: 'text',
    autoFocus: false,
    isActive: false,
  }

  buildInput(field, error) {
    const { multiLine, leftIcon, rightIcon, type, fileName, placeholder } = this.props;
    const inputProps = blacklist(field,
      'input',
      'errorText',
      'labelText',
      'leftIcon',
      'rightIcon',
      'multiLine',
      'meta',
      'fileName',
      'isActive',
      'showErrorOnBeActive',
    );

    const classes = classnames({
      input: true,
      error,
      // [type]: true,
      'left-icon': leftIcon,
      'right-icon': rightIcon,
    });
    const focus = field.meta.touched || field.meta.active;
    let inputElement;

    if (type === 'numbers' || type === 'tel') {
      inputProps.onKeyDown = inputProps.onKeyDown ? inputProps.onKeyDown : handleNumericKeyDown;
      inputProps.onKeyUp = inputProps.onKeyUp ? inputProps.onKeyUp : handleNumericKeyUp;
    }

    if (multiLine) {
      inputElement = (<textarea {...field.input} styleName={classes} />);
    } else if (type === 'file') {
      inputElement = (<button type="button" styleName={classes} onClick={() => this.inputFile.click()}>
        <input
          {...inputProps}
          onChange={field.input.onChange}
          ref={(ele) => { this.inputFile = ele; }}
        />
        <span title={fileName}>{fileName}</span>
      </button>);
    } else {
      inputElement = (<input
        {...field.input}
        {...inputProps}
        styleName={classes}
        placeholder={focus || inputProps.disabled ? (placeholder || field.placeholder) : ''}
      />);
    }

    return (<div styleName="input-group">
      {leftIcon && <span styleName="input-addon">
        {leftIcon}
      </span>}

      {inputElement}

      {rightIcon && <span styleName="input-addon">
        {rightIcon}
      </span>}
    </div>);
  }

  renderField = (field) => {
    const {
      className,
      errorText,
      labelText,
      disabled,
      name,
      autoFocus,
      isActive,
      showErrorOnBeActive,
    } = this.props;
    const hasError = (showErrorOnBeActive) ||
      (field.meta.error &&
        field.meta.touched &&
        !field.meta.active);

    const classes = classnames({
      'input-container': true,
      error: hasError,
      focus: (isActive || autoFocus || field.meta.touched || field.meta.active),
      disabled,
    });

    const inputElement = this.buildInput(field, hasError);

    return (<div className={className} styleName={classes}>
      {labelText && <label htmlFor={name}>{labelText}</label>}

      {inputElement}

      {hasError && <span styleName="input-error-text">{errorText}</span>}
    </div>);
  }

  render() {
    return (<Field {...this.props} component={this.renderField} />);
  }
}

export default TextField;
