import { types, setToken } from '../actions/auth';

const {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  SET_TOKEN,
} = types;

// I think nI can remove this line without any problems
const token = localStorage.getItem('token') || null;

const initialState = {
  token,
  isAuthenticating: false,
  error: null,
};

export const onCreated = (store) => {
  store.dispatch(setToken(store.getState().auth.token));
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticating: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticating: false,
        error: action.payload.error,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    default:
      return state;
  }
};
