import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import googleAnalytics from '@lib/googleAnalytics';
import {
  TextField, Button, Icon,
} from '@/components';
// import history from '@/browserHistory';
import './style.scss';

function SetPassword(props) {
  const { className, valid, loading } = props;

  const [textPassword, showPassword] = useState(false);
  const [textConfirmPassword, showConfirmPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  useEffect(() => {
    googleAnalytics.visitPage('set-password');
  }, []);

  const errorClasses = classnames({
    'error-text': true,
    invalid: invalidPassword,
  });

  return (
    <section styleName="step-form step-01" className={className}>
      <h1>¡Tu crédito fue aprobado!</h1>
      <h2>Establece una nueva contraseña para continuar con el proceso de obtención de tu crédito Digitt.</h2>
      {/* <ErrorMessage error={error} /> */}
      <div className="row" styleName="inputs-container">
        <TextField
          className="col-4"
          labelText="Correo electrónico"
          name="email"
          id="email"
          type="email"
          disabled
          autoFocus
        />
        <div className="col-4">
          <TextField
            labelText="Contraseña"
            name="password"
            id="password"
            type={textPassword ? 'text' : 'password'}
            onChange={({ target }) => {
              const value = target.value.trim();
              if (value.length >= 8) {
                const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/g.test(value);
                setInvalidPassword(!isValid);
              }
            }}
            rightIcon={
              <Icon type={textPassword ? 'viewBlue' : 'view'} height="17" onClick={() => showPassword(!textPassword)} />
            }
          />
          <div styleName={errorClasses}>
            Tu contraseña debe contener:
            <ul>
              <li>8 o más caracteres</li>
              <li>Letras mayúsculas y minúsculas</li>
              <li>Al menos un número</li>
            </ul>
          </div>
        </div>
        <TextField
          className="col-4"
          labelText="Confirmar contraseña"
          name="confirmPassword"
          id="confirmPassword"
          type={textConfirmPassword ? 'text' : 'password'}
          errorText="La contraseña no coincide"
          rightIcon={(
            <Icon
              type={textConfirmPassword ? 'viewBlue' : 'view'}
              height="17"
              onClick={() => showConfirmPassword(!textConfirmPassword)}
            />
          )}
        />
        <div className="col-12" styleName="container-btn-next">
          <Button
            id="btn-next"
            styleName="btn-next"
            type="submit"
            color="primary"
            spinner={loading}
            disabled={loading || !valid}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </section>
  );
}

SetPassword.propTypes = {
  className: PropTypes.string,
  valid: PropTypes.bool,
  loading: PropTypes.bool,
};

export default SetPassword;
