import apiUtils from '@utils/apiUtils';
import { createTypes } from 'redux-action-types';
import { push } from 'react-router-redux';
import { setToken } from './auth';

export const types = createTypes('app/loans/', 'SET_LOANS', 'SET_USER');

// eslint-disable-next-line arrow-body-style
export const getLoans = (filter = '') => (dispatch) => {
  return new Promise((resolve) => {
    apiUtils
      .getLoans(filter)
      .then((response) => {
        dispatch({
          type: types.SET_LOANS,
          loans: response.data.loans,
        });
      })
      .catch((err) => {
        if (err.request.status === 401) {
          dispatch(setToken(null));
          dispatch(push('/login'));
        }
        dispatch({
          type: types.SET_LOANS,
          loans: [],
        });
      });
    apiUtils.getPersonalInfo()
      .then((response) => {
        dispatch({
          type: types.SET_USER,
          response: response.data,
        });
        resolve();
      });
  });
};
