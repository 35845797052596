import React, { PureComponent } from 'react';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import { recoverPassword } from '@/store/actions/digitalSignature';
import { TextField, Button, ErrorMessage } from '@/components';
import Mixpanel from '@lib/mixpanel';
import mapStateToProps from './mapStateToProps';
import validateForm from './validate';
import './style.scss';

@reduxForm({
  form: 'forgot-password',
  validate: validateForm,
  destroyOnUnmount: false,
  enableReinitialize: true,
})
@connect(mapStateToProps, { actionRecoverPassword: recoverPassword })
class ForgotPassword extends PureComponent {
  static propTypes = {
    actionRecoverPassword: PropTypes.func,
    digitalSignature: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
    }),
  }

  state = {
    success: false,
  }

  componentDidMount() {
    googleAnalytics.visitPage('forgot-password');
    Mixpanel.track('app_reset_password');
  }

  onSubmit = (values) => {
    const { actionRecoverPassword } = this.props;
    actionRecoverPassword(values.email)
      .then(() => {
        this.setState({ success: true });
      });
  };

  render() {
    const { handleSubmit, valid, digitalSignature: { loading, error } } = this.props;
    const { success } = this.state;

    return (
      <section styleName="forgot-password">
        {!success && <h1>¿Olvidaste tu contraseña?</h1>}
        {!success && <h2>Para restablecerla, ingresa tu correo electrónico.</h2>}
        {success && (
          <h2 styleName="success-message">
            Te hemos enviado un correo con las instrucciones para cambiar tu contraseña.
          </h2>
        )}
        <ErrorMessage error={error} />
        <Form method="post" className="clearfix" onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          {!success && (
            <div className="row" styleName="inputs-container">
              <TextField
                styleName="input-field"
                className="col-4"
                labelText="Email"
                name="email"
                id="email"
                type="email"
                errorText="Ingresa un formato de correo correcto."
              />
              <div styleName="container-btn-next">
                <Button
                  id="btn-next"
                  styleName="btn-next"
                  type="submit"
                  color="primary"
                  spinner={loading}
                  disabled={loading || !valid}
                >
                  Recuperar contraseña
                </Button>
              </div>
            </div>
          )}
        </Form>
      </section>
    );
  }
}

export default ForgotPassword;
