import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../Alert';
import './style.scss';

function Div(params) {
  const { className, children } = params;
  return (<div className={className}>{children}</div>);
}

function ErrorMessage(props) {
  const { className, type, error, color } = props;
  let Wrapper = Div;

  if (!error) {
    return null;
  }

  if (type === 'alert') {
    Wrapper = Alert;
  }

  const errorMessage = [].concat(error).map((message, index) => {
    return (<li key={index}>
      {message}
    </li>);
  });

  return (<Wrapper styleName="error-message" className={className} color={color}>
    <ul>
      {errorMessage}
    </ul>
  </Wrapper>);
}

ErrorMessage.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['div', 'alert']),
  color: PropTypes.string,
  error: PropTypes.arrayOf(
    PropTypes.string,
  ),
};

ErrorMessage.defaultProps = {
  type: 'div',
  color: 'error',
};


export default ErrorMessage;
