import digitalSignatureRepository from '../repository/digitalSignatureRepository';
import engine from '../engines/digitalSignature/promise';

const getAmortizationInfo = (idLoan) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.getAmortizationInfo(idLoan);
};

const setTerm = (idLoan, term) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setTerm(idLoan, term);
};

const setPaymentDay = (idLoan, firstPaymentDate) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setPaymentDay(idLoan, firstPaymentDate);
};

const setBank = (idLoan, clabe, bank) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setBank(idLoan, clabe, bank);
};

const setMati = () => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setMati();
};

const setConfirmPasswordData = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setConfirmPasswordData(request);
};

const uploadFile = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.uploadFile(request);
};

const startIdentityValidation = (loanRequestId, passport) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.startIdentityValidation(loanRequestId, passport);
};

const changeClabe = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.changeClabe(request);
};

const getLoanDetails = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.getLoanDetails(request);
};

const getIdValidationInfo = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.getIdValidationInfo(request);
};

const setIdentityValidationSuccess = (request) => {
  const repository = digitalSignatureRepository.Create(engine);
  return repository.setIdentityValidationSuccess(request);
};

export default {
  getAmortizationInfo,
  setTerm,
  setPaymentDay,
  setBank,
  setMati,
  setConfirmPasswordData,
  uploadFile,
  startIdentityValidation,
  changeClabe,
  getLoanDetails,
  getIdValidationInfo,
  setIdentityValidationSuccess,
};
