import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useAxios from 'axios-hooks';
import Mixpanel from '@lib/mixpanel';
import {
  Button,
} from '@components';
import './style.scss';
/* globals API_URL */

const PasswordError = (props) => {
  const {
    className,
    error,
  } = props;
  if (error) {
    return (
      <h3 className={className} style={{ textAlign: 'center', color: 'red' }}>
        Hubo en error firmando tu credito, favor de intentarlo de nuevo
      </h3>
    );
  }
  return '';
};

function PasswordConfirmation(props) {
  const {
    className,
    formValues,
    loanId,
    token,
    onNext,
  } = props;

  const [password, setPassword] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [{ data: postData, loading, error }, postSignature] = useAxios(
    {
      url: `${API_URL}/sign`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }, { manual: true },
  );

  const onSubmit = async () => {
    try {
      const documentsAccepted = [
        `${loanId}/PromissoryNote.pdf`,
        `${loanId}/AmortizationTable.pdf`,
        `${loanId}/Domiciliacion.pdf`,
        `${loanId}/LoanAgreement.pdf`,
      ];
      await postSignature({
        data: {
          id: loanId,
          password,
          signature: formValues.signature,
          documents_accepted: documentsAccepted,
        },
      });
      Mixpanel.track('app_guaranteed_confirm_password_step');
      onNext();
    } catch (err) {
      // ignore error handled in the render
    }
  };

  return (
    <section styleName="step-form step-sign" className={className}>
      <h2>
        Por tu seguridad, necesitamos que ingreses tu contraseña para finalizar el proceso.
      </h2>
      <PasswordError error={error} />
      <div className="row" styleName="inputs-container input-container">
        <div className="col-12 col-4-ns">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="passwordconfirmation">
            Contraseña
          </label>
          <input
            id="passwordconfirmation"
            name="password"
            type="password"
            styleName="input-field bank"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
        </div>
      </div>
      <div styleName="btn-container">
        <Button
          id="btn-next"
          styleName="btn-next"
          type="submit"
          color="primary"
          spinner={loading}
          disabled={loading || password.length < 6}
          onClick={onSubmit}
        >
          Aceptar
        </Button>
      </div>
    </section>
  );
}

PasswordConfirmation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.object,
  loanId: PropTypes.number,
  token: PropTypes.string,
  onNext: PropTypes.func,
};

export default PasswordConfirmation;
