import React from 'react';
import iconFacebook from '@images/icon-facebook-alt.svg';
import iconTwitter from '@images/icon-twitter-alt.svg';
import '../style.scss';

function Footer() {
  return (<footer className="clearfix">
      <div className="row clearfix" styleName="copyright">
        <div>Copyright ® Digitt,</div>
        <div>PAGOSAFE SAPI de CV.</div>
        <div>Todos los derechos reservados.</div>
      </div>
  </footer>);
}

export default Footer;
