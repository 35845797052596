export const getPaymentDayOptions = (options) => {
  const uniqueDates = options ? [...new Set(options.map(option => option.firstPaymentAt))] : [];
  return uniqueDates.sort((date1, date2) => new Date(date2).getDate() - new Date(date1).getDate());
};

export const getSelectedOption = (options, formValues) => {
  const selectedOption = options && options.find(option => (
    option.firstPaymentAt === formValues.firstPaymentDate
    && option.installments === formValues.term
  ));

  return selectedOption;
};

export const getPaymentsMetadata = (option) => {
  const secondToLastPayment = option.scheduledPayments[option.scheduledPayments.length - 2];
  const lastPayment = option.scheduledPayments[option.scheduledPayments.length - 1];
  const hasDifferentAmounts = secondToLastPayment.amount !== lastPayment.amount;
  const maxPaymentAmount = Math.max(...option.scheduledPayments.map(sp => sp.amount));

  return {
    maxPaymentAmount,
    hasDifferentAmounts,
    paymentsCount: option.scheduledPayments.length,
    monthlyPaymentAmount: secondToLastPayment.amount,
    lastPaymentAmount: lastPayment.amount,
    totalAmount: option.scheduledPayments.reduce((acc, p) => acc + p.amount, 0),
  };
};

export default {
  getSelectedOption,
  getPaymentsMetadata,
};
