/* eslint-disable quote-props */
export default {
  '002': 'BANAMEX',
  '006': 'BANCOMEXT',
  '009': 'BANOBRAS',
  '012': 'BBVA BANCOMER',
  '014': 'SANTANDER',
  '019': 'BANJERCITO',
  '021': 'HSBC',
  '022': 'GE MONEY',
  '030': 'BAJÍO',
  '032': 'IXE',
  '036': 'INBURSA',
  '037': 'INTERACCIONES',
  '042': 'MIFEL',
  '044': 'SCOTIABANK',
  '058': 'BANREGIO',
  '059': 'INVEX',
  '060': 'BANSI',
  '062': 'AFIRME',
  '072': 'BANORTE',
  '102': 'THE ROYAL BANK',
  '103': 'AMERICAN EXPRESS',
  '106': 'BAMSA',
  '108': 'TOKYO',
  '110': 'JP MORGAN',
  '112': 'BMONEX',
  '113': 'VE POR MAS',
  '116': 'ING',
  '124': 'DEUTSCHE',
  '126': 'CREDIT SUISSE',
  '127': 'AZTECA',
  '128': 'AUTOFIN',
  '129': 'BARCLAYS',
  '130': 'COMPARTAMOS',
  '131': 'BANCO FAMSA',
  '132': 'BMULTIVA',
  '133': 'ACTINVER',
  '134': 'WAL-MART',
  '135': 'NAFIN',
  '136': 'INTERBANCO',
  '137': 'BANCOPPEL',
  '138': 'ABC CAPITAL',
  '139': 'UBS BANK',
  '140': 'CONSUBANCO',
  '141': 'VOLKSWAGEN',
  '143': 'CIBANCO',
  '145': 'BBASE',
  '166': 'BANSEFI',
  '168': 'HIPOTECARIA FEDERAL',
  '600': 'MONEXCB',
  '601': 'GBM',
  '602': 'MASARI',
  '605': 'VALUE',
  '606': 'ESTRUCTURADORES',
  '607': 'TIBER',
  '608': 'VECTOR',
  '610': 'B&B',
  '614': 'ACCIVAL',
  '615': 'MERRILL LYNCH',
  '616': 'FINAMEX',
  '617': 'VALMEX',
  '618': 'UNICA',
  '619': 'MAPFRE',
  '620': 'PROFUTURO',
  '621': 'CB ACTINVER',
  '622': 'OACTIN',
  '623': 'SKANDIA',
  '624': 'CONSULTORÍA',
  '626': 'CBDEUTSCHE',
  '627': 'ZURICH',
  '628': 'ZURICHVI',
  '629': 'SU CASITA',
  '630': 'CB INTERCAM',
  '631': 'CI BOLSA',
  '632': 'BULLTICK CB',
  '633': 'STERLING',
  '634': 'FINCOMUN',
  '636': 'HDI SEGUROS',
  '637': 'ORDER',
  '638': 'AKALA',
  '640': 'CB JPMORGAN',
  '642': 'REFORMA',
  '646': 'STP',
  '647': 'TELECOMM',
  '648': 'EVERCORE',
  '649': 'SKANDIA',
  '651': 'SEGMTY',
  '652': 'ASEA',
  '653': 'KUSPIT',
  '655': 'SOFIEXPRESS',
  '656': 'UNAGRA',
  '659': 'OPCIONES EMPRESARIALES DEL NOROESTE',
  '901': 'CLS',
  '902': 'INDEVAL',
  '670': 'LIBERTAD',
};
