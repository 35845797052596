import querystring from 'querystring';

function mapStateToProps(state, props) {
  const { digitalSignature, form } = state;
  const { location: { search }, match: { params } } = props;

  const searchFormatted = search[0] === '?' ? search.substr(1) : search;
  const urlValues = querystring.parse(searchFormatted);
  const urlToken = urlValues.token ? urlValues.token : null;
  const formValues = form.newClabeForm ? (form.newClabeForm.values || {}) : {};

  return {
    urlToken,
    formValues,
    digitalSignature,
    user: digitalSignature.user,
    loanRequest: digitalSignature.loanRequest,
    loan: parseInt(params.id || 0, 10),
  };
}

export default mapStateToProps;
