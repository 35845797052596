import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Spinner = (props) => {
  const { table } = props; 
  const classes = classnames({
    'spinner-container': true,
    table: table
  });
  return (
    <div styleName={classes}>
      <div styleName="spinner" />
      <div styleName="loader" />
    </div>
  );
};

Spinner.propTypes = {
  table: bool,
};

Spinner.defaultProps = {
  table: false,
};

export default Spinner;
