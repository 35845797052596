import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const TableCell = (props) => {
	const {
		className,
		children,
		variation,
		rowSpan,
		colSpan,
		onClick,
		isExpandible,
	} = props;

	const classes = classnames({
		'table-cell': true,
		'isExpandible': isExpandible,
		[className]: className !== null,
	});

	const headCell = (
		<th styleName={classes} rowSpan={rowSpan} colSpan={colSpan} onClick={onClick}>
			{children}
		</th>
	);

	const bodyCell = (
		<td styleName={classes} rowSpan={rowSpan} colSpan={colSpan} onClick={onClick}>
			{children}
		</td>
	);

	return variation === 'head' ? headCell : bodyCell;
};

TableCell.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	variation: PropTypes.oneOf(['head', 'body']),
	rowSpan: PropTypes.number,
	colSpan: PropTypes.number,
	onClick: PropTypes.func,
	isExpandible: PropTypes.bool,
};

TableCell.defaultProps = {
	className: null,
	variation: 'body',
	rowSpan: 1,
	colSpan: 1,
	onClick: null,
	isExpandible: false,
};

export default TableCell;
