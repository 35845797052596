import React, { useState } from 'react';
import useSWR from 'swr';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import formatUtils from '@utils/formats';
import classnames from 'classnames';
import loanFetcher from '@libs/api-loans';
import Mixpanel from '@lib/mixpanel';
import {
  Button, ErrorMessage,
} from '@components';
import './style.scss';
/* globals API_URL */

function TermsSelection(props) {
  const {
    className,
    token,
    loanId,
    onValueChange,
    onNext,
  } = props;

  const [term, setTerm] = useState(0);

  const { data: termData, error } = useSWR([`${API_URL}/get-loan-payment-terms/${loanId}`, token], loanFetcher(token));
  const [
    { loading, postError },
    postTerm,
  ] = useAxios(
    {
      url: `${API_URL}/set-loan-term`,
      method: 'POST',
    },
    { manual: true },
  );

  if (error || postError) {
    return <div>Hubo un error cargando los datos</div>;
  }
  if (!termData) {
    return <div>Cargando...</div>;
  }

  const onSelect = (e) => {
    setTerm(parseInt(e.target.dataset.term, 10));
    onValueChange('term', parseInt(e.target.dataset.term, 10));
  };

  const renderOption = (option) => {
    const classes = classnames({
      'block-option': true,
      active: term === option.term,
    });

    return (
      <button key={option.term} type="button" styleName={classes} data-term={option.term} onClick={onSelect}>
        {option.term}
        {' '}
        pagos mensuales de
        {' '}
        {formatUtils.moneyFormat(option.payment)}
        {' '}
        MXN
      </button>
    );
  };

  const onSubmit = async () => {
    try {
      await postTerm({
        data: {
          id: loanId,
          term,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Mixpanel.track('app_guaranteed_set_term_step');
      onNext();
    } catch (err) {
      // TODO: check if error is 401 and redirect to login
    }
  };

  const termsOptions = termData.paymentTerms;
  return (
    <section id="terms-selection" styleName="step-form" className={className}>
      <h1>Selecciona el mejor plan para ti.</h1>
      <h2>
        Elige entre estas
        {' '}
        {termsOptions.length}
        {' '}
        opciones:
      </h2>
      <ErrorMessage error={error} />
      <div styleName="inputs-container">
        <div id="term-options" className="row" styleName="block-options-container">
          {termsOptions.map(renderOption)}
        </div>
        <div className="row">
          <Button
            id="submit"
            styleName="btn-next"
            type="submit"
            color="primary"
            spinner={loading}
            onClick={onSubmit}
            disabled={loading || !(term > 0)}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </section>
  );
}

TermsSelection.propTypes = {
  className: PropTypes.string,
  loanId: PropTypes.number,
  token: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default TermsSelection;
