import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import { Spinner } from '@components';
import './style.scss';

class FinalScreen extends PureComponent {
  static propTypes = {
    appToken: string,
    className: string,
  }

  componentDidMount() {
    googleAnalytics.visitPage('final-screen');
  }

  sendToDashboard = () => {
    const { appToken: token } = this.props;

    localStorage.removeItem('token');

    window.location.assign(`${process.env.DASHBOARD_APP_URL}/referencias?token=${token}`);
  }

  render() {
    const { className, appToken: token } = this.props;

    setTimeout(this.sendToDashboard, 3000);

    return (
      <section id="final-screen-page" styleName="step-form step-10" className={className}>
        <Spinner table />
        <div>
          Te estamos redirigiendo a nuestra aplicacion, si no puedes verla da click
          {' '}
          <a href={`${process.env.DASHBOARD_APP_URL}/referencias?token=${token}`}>aquí</a>
        </div>
      </section>
    );
  }
}

export default FinalScreen;
