import Axios from 'axios';

export default token => (
  async function apiFetcher(url) {
    // create a token fetcher
    try {
      const response = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      // not authorized
      const err = new Error('Not authorized!');
      err.status = error.response.status || 403;
      throw err;
    }
  }
);
