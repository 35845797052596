class GoogleAnalytics {
  dataLayerCanPush = () => window.dataLayer !== undefined

  visitPage(page = '') {
    if (this.dataLayerCanPush()) {
      const path = `${window.location.pathname}${page}`;

      window.dataLayer.push({
        event: 'pageView',
        pageUrl: path,
      });

      window.dataLayer.push({
        event: 'facebookVirtualPageView',
        pageUrl: `${window.location.hostname}${path}`,
      });
    }
  }
}

export default new GoogleAnalytics();
