import validate from '@/lib/validate';

const bank = {
  clabe: {
    presence: true,
    length: {
      minimum: 19,
      maximum: 19,
    },
    validClabeSum: true,
  },
  bank: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
};

const signature = {
  signature: {
    presence: true,
  },
};

const constraints = {
  ...bank,
  ...signature,
};

export const validateForm = (values, props) => {
  const {
    digitalSignature: { step },
  } = props;
  let stepConstraint;

  switch (step) {
    case 1:
    default:
      stepConstraint = bank;
      break;
    case 3:
      stepConstraint = signature;
      break;
  }

  const errors = validate(values, stepConstraint);

  return errors;
};

export const validateSingle = field => (value = '') => {
  if (value.length > 0) {
    const errors = validate.single(value, constraints[field]);
    return !!errors;
  }

  return false;
};
