import { createTypes, async } from 'redux-action-types';
import { push } from 'react-router-redux';
import apiUtils from '@utils/apiUtils';
import authUtils from '@utils/authUtils';
import Mixpanel from '@lib/mixpanel';
/* eslint-disable no-console */

export const types = createTypes(
  'app/auth/',
  async('LOGIN'),
  'SET_TOKEN',
);

const setAuthStart = () => {
  return {
    type: types.LOGIN,
  };
};

const setError = () => {
  return {
    type: types.LOGIN_FAIL,
    payload: {
      error: 'The user name or password is incorrect.',
    },
  };
};

const setAuthSuccess = () => {
  return {
    type: types.LOGIN_SUCCESS,
  };
};

/**
 * @param {string} t - token
 */
export const setToken = (t) => {
  const token = authUtils.isValidAccessToken(t) ? t : null;

  if (token) {
    localStorage.setItem('token', token);
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('idValidationInfo');
  }

  apiUtils.setToken(token);

  return {
    type: types.SET_TOKEN,
    payload: {
      token,
    },
  };
};

/**
 * @param {JSON} request
 * @param {string} request.email
 * @param {string} request.password
 */
export const login = request => async (dispatch) => {
  try {
    dispatch(setAuthStart());
    const res = await apiUtils.login(request);
    if (res) {
      Mixpanel.track('app_login');
    }
    dispatch(setAuthSuccess());
    dispatch(setToken(res.data.token));
  } catch (err) {
    console.error(err);
    dispatch(setError('Error logging in'));
  }
};

export const logout = () => (dispatch) => {
  dispatch(setToken(null));
};

export const logoutAndRedirect = () => (dispatch) => {
  dispatch(setToken(null));
  dispatch(push('/login'));
};
