import { createTypes, async } from 'redux-action-types';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { push } from 'react-router-redux';
import moment from 'moment';
import apiUtils from '@utils/apiUtils';
import loanUtils from '@utils/loanUtils';
import currencyUtils from '@utils/currencyUtils';
import digitalSignatureService from '../../service/digitalSignatureService';
import * as authActions from './auth';
/* eslint-disable */

export const types = createTypes('app/digitalSignature/',
  async('SET_PASSWORD'),
  async('AMORTIZATION_INFO'),
  'SET_IS_LOADING',
  'SET_IS_FETCHING',
  'SET_IS_INITIALIZING',
  'SET_TERMS_OPTIONS',
  'SET_DIGITAL_SIGNATURE_PROPS',
  'SET_ACTIVE_LOANS',
  'SET_BANK',
  'SET_ERROR',
  'BACK_STEP',
  'NEXT_STEP',
  'SET_IS_LOGIN',
  'LOGOUT',
  'OPEN_MODAL',
  'CLOSE_MODAL',
  'MODAL_IS_LOADING',
  'SET_MODAL_ERROR',
  'ON_UPLOAD_FILE_SUCCESS',
  'SET_STEP',
  'ID_VALIDATION_INFO_FETCHING',
  'SET_ID_VALIDATION_INFO',
  'ID_VALIDATION_INFO_FETCHING_ERROR');

const setDigitalSignatureProps = (digitalSignature) => {
  return {
    type: types.SET_DIGITAL_SIGNATURE_PROPS,
    digitalSignature,
  };
};

const isLoading = () => {
  return {
    type: types.SET_IS_LOADING,
  };
};

const isFetching = () => {
  return {
    type: types.SET_IS_FETCHING,
  };
};

const isInitializing = () => {
  return {
    type: types.SET_IS_INITIALIZING,
  };
};

export const setError = (error = ['Error inesperado']) => {
  return {
    type: types.SET_ERROR,
    digitalSignature: {
      error,
    },
  };
};

const setPasswordSucceded = () => {
  return {
    type: types.SET_PASSWORD_SUCCESS,
  };
};

const setAmortizationInfo = (amortizationOptions) => {
  return {
    type: types.AMORTIZATION_INFO_SUCCESS,
    digitalSignature: {
      amortizationOptions,
    },
  };
};

const setTermsOptions = (termsOptions) => {
  return {
    type: types.SET_TERMS_OPTIONS,
    digitalSignature: {
      termsOptions,
    },
  };
};

export const backStep = () => (dispatch, store) => {
  const { digitalSignature: { step } } = store();

  // If step is Contract Summary go back to Selfie
  dispatch({
    type: types.BACK_STEP,
    step: step === 8 ? 6 : step - 1,
  });
};

export const nextStep = () => {
  return {
    type: types.NEXT_STEP,
  };
};

const onUploadFileSuccess = () => {
  return {
    type: types.ON_UPLOAD_FILE_SUCCESS,
  };
};

export const setStep = (step) => {
  return {
    type: types.SET_STEP,
    step,
  };
};

export const openModal = modalName => (dispatch) => {
  dispatch({
    type: types.OPEN_MODAL,
    modalName,
  });
};

export const closeModal = modalName => (dispatch) => {
  dispatch({
    type: types.CLOSE_MODAL,
    modalName,
  });
};

export const passwordModalIsLoading = () => {
  return {
    type: types.MODAL_IS_LOADING,
    modalName: 'passwordModal',
  };
};

export const passwordModalError = () => {
  return {
    type: types.SET_MODAL_ERROR,
    modalData: {
      name: 'passwordModal',
      error: 'Tu contraseña no coincide.',
    },
  };
};

export const setActiveLoans = (data) => {
  return {
    type: types.SET_ACTIVE_LOANS,
    data: {
      activeLoans: data.activeLoans
    },
  };
};

export const recoverPassword = email => (dispatch) => {
  dispatch(isLoading());
  return new Promise((resolve) => {
    apiUtils
      .recoverPassword(email)
      .finally(() => {
        dispatch(setDigitalSignatureProps());
        resolve();
      });
  });
};

export const resetPassword = (password, token) => (dispatch) => {
  dispatch(isLoading());
  return new Promise((resolve, reject) => {
    apiUtils
      .resetPassword(password, token)
      .then(() => {
        dispatch(setDigitalSignatureProps());
        resolve();
      })
      .catch(() => {
        dispatch(setError());
        reject();
      });
  });
};

/**
 * @param {string} token - We set manually this token when setting the password
 *                         for the first time.
 */
export const loadUser = (token) => (dispatch) => {
  dispatch(isInitializing());
  apiUtils.loadUser(token)
    .then((response) => {
      const [userResponse, loansResponse] = response;
      const user = userResponse.data;
      const activeLoans = loansResponse.data.filter((loan) => loan.status === 'ACTIVE');
      const pendingLoan = loansResponse.data.find((loan) => loan.status === 'PENDING');

      // We manually set the token when we validate this request can be
      // done without problems
      if (token) {
        dispatch(authActions.setToken(token));
      }

      if (user.allowChangePassword) {
        dispatch(setDigitalSignatureProps(loanUtils.mapLoan({ }, null)));
      } else if (isEmpty(pendingLoan) && !isEmpty(activeLoans)) {
        dispatch(setActiveLoans({ activeLoans }));
        dispatch(push('/'));
      } else {
        pendingLoan.debtAmount = currencyUtils.centsToFloat(pendingLoan.debtAmount);
        pendingLoan.installmentAmount = currencyUtils.centsToFloat(pendingLoan.installmentAmount);
        const loanData = loanUtils.mapLoan(pendingLoan, user);
        dispatch(setDigitalSignatureProps(loanData));
        dispatch(getAmortization(pendingLoan.id));
        if (pendingLoan.guaranteed === true) {
          dispatch(push('/obtener-credito-garantizado'));
        } else {
          dispatch(push('/obtener-credito'));
        }
      }
    })
    .catch((error) => {
      dispatch(authActions.setToken(null));
      if (error && error.response && error.response.status === 405) {
        dispatch(setDigitalSignatureProps(loanUtils.mapLoan({ }, null)));
      } else {
        dispatch(push('/login'));
      }
    });
};

export const setPassword = (password, token) => (dispatch) => {
  apiUtils
    .setPassword(password, token)
    .then(() => {
      dispatch(loadUser());
      dispatch(setPasswordSucceded());
    })
    .catch((e) => {
      dispatch(setError());
    });
};

export const setTerm = (idLoan, term) => (dispatch) => {
  dispatch(isLoading());
  dispatch(digitalSignatureService.setTerm(idLoan, term))
    .then((response) => {
      dispatch(setDigitalSignatureProps({
        term,
        paymentTerm: response.data.payment_term,
        step: 4,
      }));
    })
    .catch(() => {
      dispatch(setError());
    });
};

export const setPaymentDay = (idLoan, firstPaymentDate) => (dispatch) => {
  dispatch(isLoading());
  dispatch(digitalSignatureService.setPaymentDay(idLoan, firstPaymentDate))
    .then(() => {
      dispatch(setDigitalSignatureProps({
        firstPaymentDate,
        step: 3,
      }));
    })
    .catch(() => {
      dispatch(setError());
    });
};

const getAmortization = (idLoan, onError = null) => (dispatch) => {
  dispatch(isFetching());
  dispatch(digitalSignatureService.getAmortizationInfo(idLoan))
    .then((response) => {
      const options = response.data.options.map((option) => {
        return {
          ...option,
          firstPaymentAt: moment(option.firstPaymentAt, moment.ISO_8601).format('YYYY-MM-DD'),
          installmentAmount: currencyUtils.centsToFloat(option.installmentAmount),
          scheduledPayments: option.scheduledPayments.map((sp) => {
            return {
              ...sp,
              amount: currencyUtils.centsToFloat(sp.amount),
              regularInterest: currencyUtils.centsToFloat(sp.regularInterest),
              regularIva: currencyUtils.centsToFloat(sp.regularIva),
              regularPrincipal: currencyUtils.centsToFloat(sp.regularPrincipal),
            }
          }),
        };
      });
      dispatch(setAmortizationInfo(options));
      dispatch(setTermsOptions(options));
      setDigitalSignatureProps({ isFetching: false });
    })
    .catch(() => {
      if (onError) {
        dispatch(onError());
      }
    });
};

export const getAmortizationInfo = (idLoan, ) => (dispatch) => {
  dispatch(getAmortization(idLoan, setError));
};

export const acceptPaymentSummary = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 5,
  }));
};

export const setBank = (idLoan, clabe, bank, skipToTestUser = false) => (dispatch) => {
  dispatch(isLoading());
  dispatch(digitalSignatureService.setBank(idLoan, clabe, bank))
    .then(() => {
      dispatch(setDigitalSignatureProps({
        clabe,
        bank,
        step: skipToTestUser ? 8 : 6,
      }));
    })
    .catch(() => {
      dispatch(setError(['Por favor ingresa una CLABE válida.']));
    });
};

export const onSelfieSuccess = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 7,
  }));
};

export const acceptContractsSummary = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 9,
  }));
};

export const acceptContract = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 10,
  }));
};

export const acceptPagare = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 11,
  }));
};

export const acceptDomiciliation = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    step: 12,
  }));
};

export const openConfirmPasswordModal = request => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    signature: request.signature,
  }));
  dispatch(openModal('passwordModal'));
};

export const confirmPasswordSubmit = request => (dispatch, store) => {
  const { digitalSignature } = store();
  dispatch(passwordModalIsLoading());
  dispatch(digitalSignatureService.setConfirmPasswordData({
    id: digitalSignature.loan,
    password: request.password,
    signature: digitalSignature.signature,
    documents_accepted: [
      'ContratoDeCrédito',
      'Pagaré',
      'AutorizaciónDeDomiciliación',
      'TablaDeAmortización',
    ],
  }))
    .then(() => {
      dispatch(closeModal('passwordModal'));
      dispatch(setDigitalSignatureProps({
        step: 13,
      }));
    })
    .catch(() => {
      dispatch(passwordModalError());
    });
};

export const uploadFile = (request, onSuccess, onError) => (dispatch) => {
  dispatch(isFetching());
  dispatch(digitalSignatureService.uploadFile(request))
    .then(() => {
      dispatch(onUploadFileSuccess());
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess();
      }
    })
    .catch(() => {
      dispatch(setDigitalSignatureProps({
        isFetching: false,
      }));
      if (onError && typeof onError === 'function') {
        onError();
      }
    });
};

export const onIDSuccess = (loanRequestId, passport) => (dispatch) => {
  dispatch(isFetching());
  dispatch(digitalSignatureService.startIdentityValidation(loanRequestId, passport))
    .then(() => {
      dispatch(setDigitalSignatureProps({
        step: 8,
        isFetching: false,
      }));
    })
    .catch(() => {
      dispatch(setError());
    });
};

export const onIdentityValidationSuccess = ({ idLoan, documentType }) => async (dispatch) => {
  dispatch(isFetching());
  try {
    await dispatch(digitalSignatureService.setIdentityValidationSuccess({ idLoan, documentType }));
    dispatch(setDigitalSignatureProps({
      step: 8,
      isFetching: false,
    }));
  } catch(e) {
    dispatch(setError(e));
  }
};

export const setErrorIdValidation = () => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    errorIdValidation: true,
  }));
};

export const setNewClabeInfo = request => (dispatch) => {
  const { loanId } = request;
  dispatch(isInitializing());
  dispatch(digitalSignatureService.getLoanDetails({ loan: loanId }))
    .then((response) => {
      const { loan, user } = response.data;
      if (!loan) {
        dispatch(push('/'));
      } else {
        dispatch(setDigitalSignatureProps(loanUtils.mapNewClabeLoan(loan, user)));
      }
    })
    .catch(() => {
      dispatch(setDigitalSignatureProps(loanUtils.mapNewClabeLoan({ }, null)));
      dispatch(push('/'));
    });
};

export const setNewClabeBank = (clabe, bank) => (dispatch) => {
  dispatch(setDigitalSignatureProps({
    clabe,
    bank,
    step: 2,
  }));
};

export const confirmNewClabePasswordSubmit = request => (dispatch, store) => {
  dispatch(passwordModalIsLoading());
  dispatch(digitalSignatureService.changeClabe({
    loan: request.loan,
    signature: request.signature,
    clabe: request.clabe,
    bank: request.bank,
    password: request.password,
  }))
    .then(() => {
      dispatch(closeModal('passwordModal'));
      dispatch(setDigitalSignatureProps({
        step: 4,
      }));
      dispatch(push(`/${request.loan}/nueva-clabe`));
    })
    .catch(error => {
      console.log(error);
      dispatch(passwordModalError());
    });
};

export const getIdValidationInfo = request => async (dispatch) => {
  dispatch({ type: types.ID_VALIDATION_INFO_FETCHING });

  try {
    const storedIdInfo = localStorage.getItem('idValidationInfo');
    const idValidationInfo = storedIdInfo ? JSON.parse(storedIdInfo) : null;

    if (loanUtils.isValidIdValidationInfo(idValidationInfo)) {
      dispatch({ type: types.SET_ID_VALIDATION_INFO, data: idValidationInfo });
    } else {
      const response = await dispatch(digitalSignatureService.getIdValidationInfo(request));
      dispatch({ type: types.SET_ID_VALIDATION_INFO, data: response.data });
      localStorage.setItem('idValidationInfo', JSON.stringify(response.data));
    }
  } catch (e) {
    dispatch({ type: types.ID_VALIDATION_INFO_FETCHING_ERROR });
  }
};

export const uploadIdentityFile = (request, onSuccess, onError) => async (dispatch) => {
  dispatch(isFetching());
  try {
    await axios.put(request.url, request.file, {
      headers: {
        'content-type': request.file.type,
      },
    });
    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (e) {
    if (onError && typeof onError === 'function') {
      onError();
    }
  } finally {
    dispatch(setDigitalSignatureProps({
      isFetching: false,
    }));
  }
};
