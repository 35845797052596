import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { map, some, forOwn } from 'lodash-es';
import shortid from 'shortid';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';
import './style.scss';

class Table extends PureComponent {
  state = {
    expandedRows: [],
  }

  onCellClick = (column, index) => {
    const { expandComponent } = this.props;
    const { expandedRows } = this.state;

    if (!column.isExpandible || !expandComponent || typeof expandComponent !== 'function') {
      return false;
    }

    let newExpandedRows = [];
    if (expandedRows.indexOf(index) >= 0) {
      newExpandedRows = expandedRows.filter(row => row !== index);
    } else {
      newExpandedRows = expandedRows.concat([index]);
    }

    this.setState({
      expandedRows: newExpandedRows,
    });
  }

  renderRow = (row, i) => {
    const { expandComponent, columns, onRowClick, rowClassName } = this.props;
    const { expandedRows } = this.state;
    const hasExpandible = some(columns, column => column.isExpandible);

    const rows = [
      <TableRow key={shortid.generate()} isOdd={i % 2 === 0} className={rowClassName} onClick={onRowClick}>
        {this.renderCells(row, i)}
      </TableRow>,
    ];

    if (hasExpandible && expandComponent && expandedRows.indexOf(i) >= 0) {
      const columnsLength = Object.keys(columns).length;
      rows.push(
        <TableRow key={shortid.generate()} isOdd={i % 2 === 0} isExpanded>
          <TableCell colSpan={columnsLength}>
            {expandComponent(row)}
          </TableCell>
        </TableRow>,
      );
    }

    return rows;
  }

  renderData = (row, key) => {
    const { formats } = this.props;
    if (formats[key]) {
      return formats[key](row);
    }
    return row[key] || '';
  }

  renderCells = (row, index) => {
    const { columns, cellClassName } = this.props;
    const { expandedRows } = this.state;
    const cells = [];

    forOwn(columns, (column, key) => {
      const onClick = () => {
        this.onCellClick(column, index);
      };
      const { isExpandible } = column;
      const isSelected = isExpandible && expandedRows.indexOf(index) >= 0;
      const chevronClasses = classnames({
        chevron: isExpandible,
        up: isSelected,
      });
      const cellClass = classnames({
        [cellClassName]: cellClassName !== null,
        isSelected,
        expanded: isSelected,
      });
      const cell = (
        <TableCell
          key={`${shortid.generate() + column}`}
          isExpandible={isExpandible}
          className={cellClass}
          onClick={onClick}
        >
          {this.renderData(row, key)}
          {isExpandible && (
            <React.Fragment>
              <i styleName={chevronClasses} />
            </React.Fragment>
          )}
        </TableCell>
      );
      cells.push(cell);
    });

    return cells;
  }

  render() {
    const { id, className, columns, rows, cellSpacing } = this.props;

    const classes = classnames({
      'table-info': true,
      [className]: className !== null,
    });

    return (
      <table id={id} styleName={classes} cellSpacing={cellSpacing}>
        <TableHeader>
          <TableRow>
            {map(columns, column => (
              <TableCell variation="head" key={`tableTitle-${column.displayText}`}>
                {column.displayText}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows.map(this.renderRow)}
        </TableBody>
      </table>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  rowClassName: PropTypes.string,
  cellClassName: PropTypes.string,
  columns: PropTypes.objectOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  formats: PropTypes.objectOf(PropTypes.func),
  expandComponent: PropTypes.func,
  onRowClick: PropTypes.func,
  cellSpacing: PropTypes.number,
};

Table.defaultProps = {
  className: null,
  rowClassName: null,
  cellClassName: null,
  rows: [],
  formats: {},
  columns: {},
  expandComponent: null,
  onRowClick: null,
  cellSpacing: 0,
};

export default Table;
