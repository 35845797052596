import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '@images/logo-header.svg';
import logoBlue from '@images/logo-header-alt.svg';
import { Button } from '@components';
import { backStep, nextStep } from '@/store/actions/digitalSignature';
import loanUtils from '@utils/loanUtils';
import './style.scss';

@connect(null, { actionBackStep: backStep, actionNextStep: nextStep })
class StaticHeader extends Component {
  render() {
    const {
      altLogo,
      totalSteps,
      actionBackStep,
      actionNextStep,
      digitalSignature: { step },
      hideStepsTracking,
    } = this.props;

    return (
      <header id="static-header" styleName="static-header">
        <div className="flex center-items space-between-items" styleName="header-container">
          <Button id="static-btn-back" onClick={() => actionBackStep()} color="unstyled" styleName="back">
            {'<'}
            {' '}
            <span styleName="back-text">Regresar</span>
          </Button>
          <div id="static-logo" className="logo">
            {altLogo ? <img src={logoBlue} alt="Digitt" /> : <img src={logo} alt="Digitt" />}
          </div>
          {!hideStepsTracking && (
            <span id="static-step-tracking" styleName="step-tracking">
              {loanUtils.getStepNumber(Math.floor(step))}
              {'/'}
              {totalSteps}
            </span>)}
          <Button id="static-btn-action" color="primary" styleName="btn-action" onClick={() => actionNextStep()}>
            Estoy de acuerdo
          </Button>
        </div>
      </header>
    );
  }
}

export default StaticHeader;
