import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const TableHeader = (props) => {
   const {
      className,
      children,
   } = props;

   const classes = classnames({
      'table-header': true,
      [className]: className !== null,
   });

   return (
      <thead styleName={classes}>
         {children}
      </thead>
   );
};

TableHeader.propTypes = {
   className: PropTypes.string,
   children: PropTypes.node,
};

TableHeader.defaultProps = {
   className: null,
};

export default TableHeader;
