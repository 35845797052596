import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import apiUtils from '@utils/apiUtils';
import SetPassword from './SetPassword';

const LocalSetPasswordForm = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const {
    history,
    token,
    valid,
    loanId,
  } = props;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.password.value === e.target.confirmPassword.value) {
      setIsFetching(true);
      try {
        const response = await apiUtils.setPassword(e.target.password.value, token);
        // set token
        window.localStorage.setItem('token', response.data.token);
        setIsFetching(false);
        if (loanId) {
          return window.location.replace(`/obtener-credito-garantizado/${loanId}?token=${response.data.token}`);
        }
        return window.location.replace(`/?token=${response.data.token}`);
      } catch (error) {
        if (error.response.status === 401 || error.response.status === 405) {
          // user might not be able to change the password
          // set token in local storage
          window.localStorage.setItem('token', token);
          setIsFetching(false);
          return history.push(`/?token=${token}`);
        }
      }
      setIsFetching(false);
      return history.push('/login');
    }
    return false;
  };
  const qdata = queryString.parse(window.location.search);
  const { email } = qdata;
  return (
    <form onSubmit={handleSubmit} method="POST">
      <SetPassword loading={false} valid={valid} isFetching={isFetching} email={email} />
    </form>
  );
};

const SetPasswordForm = reduxForm({
  // a unique name for the form
  form: 'set-password',
})(LocalSetPasswordForm);


LocalSetPasswordForm.propTypes = {
  token: PropTypes.string,
  loanId: PropTypes.number,
};

export default SetPasswordForm;
