import React, { PureComponent } from 'react';
import {
  arrayOf, string, number, shape,
} from 'prop-types';
import moment from 'moment';
import googleAnalytics from '@lib/googleAnalytics';
import loanUtils from '@utils/loanUtils';
import formats from '@utils/formats';
import { ErrorMessage } from '@components';
import './style.scss';

moment.locale('es');

class AcceptDirectDebit extends PureComponent {
  componentDidMount() {
    googleAnalytics.visitPage('accept-direct-debit');
  }

  render() {
    const {
      className,
      error,
      firstPaymentDate,
      bank,
      clabe,
      term,
      userInfo,
      paymentTerm,
      amortizationInfo,
    } = this.props;

    if (!amortizationInfo) {
      return null;
    }

    const monthlyPayment = amortizationInfo.paymentsMetadata
      ? amortizationInfo.paymentsMetadata.maxPaymentAmount
      : paymentTerm;
    const date = moment(firstPaymentDate, 'YYYY-MM-DD');
    const today = moment();

    return (
      <section id="accept-direct-debit-page" styleName="step-form step-direct-debit" className={className}>
        <ErrorMessage error={error} />
        <h3>
          BANCO DEL BAJÍO, S.A. INSTITUCIÓN DE BANCA MÚLTIPLE
        </h3>
        <h5>
          Formato para Solicitar la Domiciliación
        </h5>
        <p styleName="date">
          {`${today.format('DD')} de ${today.format('MMMM')} de ${today.format('YYYY')}`}
        </p>
        <p>
          <b>Banco del Bajío, S.A. Institución de Banca Múltiple</b>
        </p>
        <p>
          Solicito y autorizo que con base en la información que se indica en esta comunicación
          {' '}
          se realicen cargos periódicos en mi cuenta conforme a lo siguiente:
        </p>
        <ol>
          <li>
            Nombre del proveedor del bien, servicio o crédito, según corresponda, que pretende
            {' '}
            pagarse:
            {' '}
            <b>PAGOSAFE SAPI DE CV.</b>
          </li>
          <li>
            Bien, servicio o crédito, a pagar:
            {' '}
            <b>CRÉDITO DIGITT.</b>
          </li>
          <li>
            Periodicidad del pago:
            {' '}
            <b>MENSUAL</b>
            , el día
            {' '}
            <b>
              {date.format('DD')}
              {' '}
              DE CADA MES.
            </b>
          </li>
          <li>
            Nombre del banco que lleva la cuenta de depósito a la vista o de ahorro en la
            {' '}
            que se realizará el cargo:
            {' '}
            <b>
              {bank}
              .
            </b>
          </li>
          <li>
            Clave Bancaria Estandarizada (“CLABE”) de la Cuenta (18 dígitos):
            {' '}
            <b>
              {clabe}
              .
            </b>
          </li>
          <li>
            Monto máximo fijo del cargo autorizado por periodo de facturación:
            {' '}
            <b>
              {formats.moneyFormat(monthlyPayment)}
              {' '}
              MXN.
            </b>
          </li>
          <li>
            Esta autorización vence el:
            {' '}
            <b>
              {date.add(term - 1, 'months').format('DD [de] MMMM [de] YYYY')}
            </b>
          </li>
        </ol>
        <p>
          Estoy enterado de que en cualquier momento podré solicitar la cancelación de
          {' '}
          la presente domiciliación sin costo a mi cargo.
        </p>
        <p>
          En caso de que la Cuenta objeto de la presente autorización no cuente con fondos suficientes
          {' '}
          para cubrir la parcialidad más los intereses ordinarios, el suscrito autoriza
          {' '}
          a PAGOSAFE SAPI DE CV para que en cualquier momento, realice los intentos de cargo
          {' '}
          que estime necesarios para cubrir el adeudo, incluyendo capital, intereses ordinarios
          {' '}
          e intereses moratorios, según sea el caso.
        </p>
        <p>
          Esta autorización estará vigente por todo el plazo del Contrato de Crédito y continuará
          {' '}
          en vigor hasta que todas las obligaciones a mi cargo han sido íntegramente cumplidas.
        </p>
        <p>
          <b>
            Nombre del cliente:
          </b>
          {' '}
          {loanUtils.getLoanName(userInfo)}
        </p>
      </section>
    );
  }
}

AcceptDirectDebit.propTypes = {
  className: string,
  error: arrayOf(string),
  firstPaymentDate: string,
  bank: string,
  clabe: string,
  term: number,
  paymentTerm: number,
  userInfo: shape({}),
  amortizationInfo: shape({
    amortization: shape({}),
  }),
};

export default AcceptDirectDebit;
