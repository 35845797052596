import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import loanUtils from '@utils/loanUtils';
import Button from '../../Button';
import logo from '../../../../images/logo-header.svg';
import logoBlue from '../../../../images/logo-header-alt.svg';
import '../style.scss';

@onClickOutside
class Header extends Component {
  static propTypes = {
    extraBtn: PropTypes.node,
    altLogo: PropTypes.bool,
    totalSteps: PropTypes.number,
    auth: PropTypes.shape({
      token: PropTypes.string,
    }),
    showMenu: PropTypes.bool,
    onLogout: PropTypes.func,
    hideStepsTracking: PropTypes.bool,
  }

  static defaultProps = {
    altLogo: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      openMenu: false,
    };
  }

  handleClickOutside = () => {
    this.setState({
      openMenu: false,
    });
  }

  toggleMenu = () => {
    const { openMenu } = this.state;

    this.setState({
      openMenu: !openMenu,
    });
  }

  getLogo = () => {
    const { digitalSignature, altLogo } = this.props;
    const logoImg = altLogo ? <img src={logoBlue} alt="Digitt" /> : <img src={logo} alt="Digitt" />;
    if (digitalSignature.step < 11) {
      return logoImg;
    }

    return (
      <a href="https://digitt.com/">
        {logoImg}
      </a>
    );
  }

  render() {
    const {
      extraBtn,
      showMenu,
      totalSteps,
      hideStepTracking,
      digitalSignature: { step },
      user: { user = {} },
      auth: { token },
      onLogout,
    } = this.props;

    const path = document.location.pathname;
    const showReferral = () => ((path === '/' || path.includes('credito/')) && token);

    const { openMenu } = this.state;

    const url = () => (`http://solicitud.digitt.com/api/v1/referidos?email=${encodeURIComponent(user.email)}`);

    return (
      <header className="clearfix">
        <div className="container flex center-items header-container">
          <div className="logo">
            {this.getLogo()}
          </div>
          {showMenu && token && <nav styleName="menu">
            <ul>
            { showReferral() && <li>
                <a href={url()} target="_blank" rel="noreferrer noopener" styleName={user.email ? '': 'disabled'}>
                  <Button id="count-to-referral" color="primary" styleName="referrer-button">¡Refiere y gana!</Button>
                </a>
              </li>}
              <li>
                <a href="http://ayuda.digitt.com" target="_blank" rel="noreferrer noopener">
                  <Button id="help-button" color="primary-border" styleName="help-button">Ayuda</Button>
                </a>
              </li>
              <li>
                <span id="logout-button" styleName="logout" onClick={onLogout}>
                  Cerrar Sesión
                </span>
              </li>
            </ul>
          </nav>}
          {showMenu && token && <div styleName="open-menu" onClick={this.toggleMenu} role="button">
            <div />
            <div styleName="line-2" />
            <div styleName="line-3" />
          </div>}
          {showMenu && token && <nav styleName={openMenu ? 'menu-mobile open' : 'menu-mobile'} >
            <div styleName="close-menu" onClick={this.toggleMenu} role="button">x</div>
            <ul>
             {showReferral() && <li>
                <a href={url()} target="_blank" rel="noreferrer noopener">
                  <Button id="count-to-referral" color="primary" styleName="referrer-button">¡Refiere y gana!</Button>
                </a>
              </li>}
             <li>
                <a href="http://ayuda.digitt.com" target="_blank" rel="noreferrer noopener">
                  <Button id="mobile-help-button" color="primary-border" styleName="help-button">Ayuda</Button>
                </a>
              </li>
              <li>
                <span id="mobile-logout-button" styleName="logout" onClick={onLogout}>
                  Cerrar Sesión
                </span>
              </li>
            </ul>
          </nav>}
          {extraBtn}
          {!hideStepTracking && <span styleName="step-tracking">
            {loanUtils.getStepNumber(step)}
            {'/'}
            {totalSteps}
          </span>}
        </div>
      </header>
    );
  }
}

export default Header;
