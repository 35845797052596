import React, { Component } from 'react';
import PropTypes, {
  arrayOf, string, func, bool, shape,
} from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { scroller } from 'react-scroll';
import { find, get } from 'lodash-es';
import googleAnalytics from '@lib/googleAnalytics';
import Passports from '@digitt/supported-passports';
import {
  ErrorMessage, DesktopCameraInput, MobileCameraInput,
  OverlayMessage, Dropdown,
} from '@components';
import './style.scss';

const PASSPORT_OPTIONS = Passports.map(({ country, code }) => {
  return {
    value: code,
    label: country,
  };
});

const DEFAULT_PASSPORT_OPTION = find(PASSPORT_OPTIONS, { value: 'MEX' });

class IDPicture extends Component {
  state = {
    isINE: true,
    showBackINE: false,
    showOverlay: false,
  };

  cameraElement = null;

  componentDidMount() {
    const { idValidationInfo, backStep } = this.props;
    googleAnalytics.visitPage('set-id-photo-info');
    if (idValidationInfo) {
      this.initializeFields();
    } else {
      backStep();
    }
  }

  componentWillUnmount() {
    this.pauseCamera();
  }

  onSuccess = (file) => {
    const {
      uploadIdentityFile,
      idValidationInfo: { back, front, passport },
    } = this.props;
    const { isINE, showBackINE } = this.state;
    const ineURL = showBackINE ? back : front;

    this.setState({
      showOverlay: false,
    });

    uploadIdentityFile({
      file,
      url: isINE ? ineURL : passport,
    },
    () => {
      if (isINE && !showBackINE) {
        this.setBackINE();
      } else {
        localStorage.removeItem('idValidationInfo');
        this.goNextStep();
      }
    }, () => {
      this.setState({
        showOverlay: true,
      });
    });
  }

  static getMessage(isINE, isForeigner) {
    if (isINE) {
      return 'Toma una fotografía de la parte frontal de tu INE o IFE.';
    }
    if (!isForeigner) {
      return 'Toma una fotografía de tu pasaporte mexicano vigente.';
    }
    return 'Selecciona el pais al cual pertenece tu pasaporte vigente y tómale una fotografía.';
  }

  goNextStep = () => {
    const form = document.querySelector('form');
    const button = document.createElement('button');
    button.type = 'submit';
    form.appendChild(button);
    button.click();
    form.removeChild(button);
  }

  setID = (enableINE) => {
    const { change } = this.props;
    const { isINE } = this.state;
    if (isINE !== enableINE) {
      this.setState({
        isINE: enableINE,
        showBackINE: false,
      });
      this.resetCamera();
      change('documentType', enableINE ? 'INE' : 'PASSPORT');
    }
  }

  setBackINE = () => {
    this.setState({
      isINE: true,
      showBackINE: true,
    });

    this.resetCamera();
  }

  setCameraElementRef = (element) => {
    this.cameraElement = element;
  }

  resetCamera = () => {
    if (this.cameraElement && this.cameraElement.resetIDFlow) {
      this.cameraElement.resetIDFlow();
    }

    scroller.scrollTo('app', {
      duration: 0,
      delay: 0,
    });
  }

  pauseCamera = () => {
    if (this.cameraElement && this.cameraElement.onPauseCamera) {
      this.cameraElement.onPauseCamera();
    }
  }

  tryAgain = () => {
    window.location.reload();
  }

  initializeFields() {
    const { change } = this.props;
    change('passport', DEFAULT_PASSPORT_OPTION);
    change('documentType', 'INE');
  }

  isForeigner() {
    const { userInfo } = this.props;
    return get(userInfo, 'personal_info.nationality') !== 'MEXICO';
  }

  render() {
    const { className, error, isFetching } = this.props;
    const { isINE, showBackINE, showOverlay } = this.state;
    const enableINE = this.setID.bind(this, true);
    const enablePassport = this.setID.bind(this, false);
    const isForeigner = this.isForeigner();

    return (
      <section styleName="step-form front-id-picture" className={className}>
        <h1>
          {!showBackINE
            ? 'Necesitamos verificar tu identidad:'
            : 'Toma una fotografía de la parte trasera de tu INE o IFE'}
        </h1>
        <ErrorMessage error={error} />
        <div styleName="camera-input-container">
          {!showBackINE && (
            <div>
              <div styleName="id-selector">
                <div
                  styleName={classnames({ option: true, active: isINE })}
                  onClick={enableINE}
                  role="button"
                >
                  INE/IFE
                </div>
                <div
                  styleName={classnames({ option: true, active: !isINE })}
                  onClick={enablePassport}
                  role="button"
                >
                  Pasaporte
                </div>
              </div>
              <h2>
                {IDPicture.getMessage(isINE, isForeigner)}
              </h2>
            </div>
          )}
          {!isINE && isForeigner && (
            <div>
              <Field
                name="passport"
                labelText="Pasaporte"
                styleName="passport-dropdown"
                selectedOption={DEFAULT_PASSPORT_OPTION}
                options={PASSPORT_OPTIONS}
                touched
                required
                component={Dropdown}
              />
            </div>
          )}
          {!isMobile && (
            <div>
              <DesktopCameraInput
                frameType={isINE ? 'INE' : 'PASSPORT'}
                onSucces={this.onSuccess}
                notInitCamera
                ref={this.setCameraElementRef}
                isBack={showBackINE}
                disableButton={isFetching}
              />
            </div>
          )}
          {isMobile && (
            <div>
              <MobileCameraInput
                frameType={isINE ? 'INE' : 'PASSPORT'}
                onSucces={this.onSuccess}
                isBack={showBackINE}
                disableButton={isFetching}
              />
            </div>
          )}
        </div>
        {showOverlay && <div><OverlayMessage onButtonClick={this.tryAgain} /></div>}
      </section>
    );
  }
}

IDPicture.propTypes = {
  className: string,
  error: arrayOf(string),
  uploadIdentityFile: func,
  isFetching: bool,
  userInfo: PropTypes.shape({
    personal_info: PropTypes.shape({
      nationality: PropTypes.string,
    }),
  }),
  idValidationInfo: shape({}),
  backStep: func,
};

export default IDPicture;
