import { types } from '../actions/loans';

const defaultState = {
  loans: [],
  user: {},
};

export default (state = defaultState, action = {}) => {
  const newState = { ...state };

  switch (action.type) {
    case types.SET_LOANS:
      return {
        ...newState,
        loans: action.loans.filter(l => l.status !== 'EXPIRED'),
      };
    case types.SET_USER:
      return {
        ...newState,
        user: action.response,
      };
    default:
      return state;
  }
};
