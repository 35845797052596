import { isEmpty, map, intersectionWith } from 'lodash-es';

const isValidAccessToken = (accesToken) => {
  if (isEmpty(accesToken)) {
    return false;
  }
  // TODO: Uncomment the following code when token contains the exp (expiration)
  // const tokenDecoded = jwt.decode(accesToken);
  // console.log('tokenDecoded', tokenDecoded);
  // const expiredDate = new Date(tokenDecoded.exp * 1000);
  // const validationDate = new Date();
  //
  // validationDate.setMinutes(validationDate.getMinutes() + 5);
  //
  // console.log('validationDate.getTime()', validationDate.getTime());
  // console.log('expiredDate.getTime()', expiredDate.getTime());
  // console.log('validationDate.getTime() < expiredDate.getTime()', validationDate.getTime() < expiredDate.getTime());

  // return validationDate.getTime() < expiredDate.getTime();

  return true;
};

const isAuthorized = (auth, roles) => {
  if (auth && roles && roles.length > 0) {
    const grantAccess = intersectionWith(roles, map(auth.roles, r => r.authority));
    return grantAccess && grantAccess.length > 0;
  }
  return true;
};

export default {
  isValidAccessToken,
  isAuthorized,
};
