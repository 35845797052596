import React, { PureComponent } from 'react';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import googleAnalytics from '@lib/googleAnalytics';
import { resetPassword } from '@/store/actions/digitalSignature';
import {
  TextField, Button, ErrorMessage, Icon,
} from '@/components';
import mapStateToProps from './mapStateToProps';
import validateForm from './validate';
import './style.scss';

@connect(mapStateToProps, { actionResetPassword: resetPassword })
@reduxForm({
  form: 'reset-password',
  validate: validateForm,
  destroyOnUnmount: false,
  enableReinitialize: true,
})
class ResetPassword extends PureComponent {
  static propTypes = {
    actionResetPassword: PropTypes.func,
    digitalSignature: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.string,
    }),
    token: PropTypes.string,
  }

  state = {
    textPassword: false,
    invalidPassword: false,
    textConfirmPassword: false,
  }

  componentDidMount() {
    googleAnalytics.visitPage('reset-password');
  }

  showPassword = () => {
    const { textPassword } = this.state;
    this.setState({ textPassword: !textPassword });
  };

  showConfirmPassword = () => {
    this.setState((s) => { return { textConfirmPassword: !s.textConfirmPassword }; });
  }

  onSubmit = (values) => {
    const { actionResetPassword, token, history } = this.props;
    actionResetPassword(values.password, token)
      .then(() => {
        history.push({
          pathname: '/login',
          state: { successResetPassword: true },
        });
      });
  };

  onChangePassword = ({ target }) => {
    const value = target.value.trim();
    if (value.length >= 8) {
      const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/g.test(value);
      if (!isValid) {
        this.setState({ invalidPassword: true });
      } else {
        this.setState({ invalidPassword: false });
      }
    }
  }

  render() {
    const { handleSubmit, valid, digitalSignature: { loading, error } } = this.props;
    const { textPassword, textConfirmPassword, invalidPassword } = this.state;

    const errorClasses = classnames({
      'error-text': true,
      invalid: invalidPassword,
    });

    return (
      <section styleName="reset-password">
        <h1>Establece una nueva contraseña</h1>
        <ErrorMessage error={error} />
        <Form method="post" className="clearfix" onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <div className="row" styleName="inputs-container">
            <TextField
              className="col-4"
              labelText="Correo electrónico"
              name="email"
              id="email"
              type="email"
              disabled
              autoFocus
            />
            <div className="col-4">
              <TextField
                labelText="Contraseña"
                name="password"
                id="password"
                type={textPassword ? 'text' : 'password'}
                onChange={this.onChangePassword}
                rightIcon={<Icon type={textPassword ? 'viewBlue' : 'view'} height="17" onClick={this.showPassword} />}
              />
              <div styleName={errorClasses}>
                Tu contraseña debe contener:
                <ul>
                  <li>8 o más caracteres</li>
                  <li>Letras mayúsculas y minúsculas</li>
                  <li>Al menos un número</li>
                </ul>
              </div>
            </div>
            <TextField
              className="col-4"
              labelText="Confirmar contraseña"
              name="confirmPassword"
              id="confirmPassword"
              type={textConfirmPassword ? 'text' : 'password'}
              rightIcon={(
                <Icon
                  type={textConfirmPassword ? 'viewBlue' : 'view'}
                  height="17"
                  onClick={this.showConfirmPassword}
                />
              )}
            />
            <div className="col-12" styleName="container-btn-next">
              <Button
                id="btn-next"
                styleName="btn-next"
                type="submit"
                color="primary"
                spinner={loading}
                disabled={loading || !valid}
              >
                Reestablecer
              </Button>
            </div>
          </div>
        </Form>
      </section>
    );
  }
}

export default ResetPassword;
