import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import loanUtils from '@utils/loanUtils';
import * as digitalSignatureActions from '@/store/actions/digitalSignature';
import {
  Button, TinyCircle, Spinner, PasswordModal,
} from '@components';
import Mixpanel from '@lib/mixpanel';
import { getPaymentDayOptions, getSelectedOption, getPaymentsMetadata } from '@utils/amortizationOptionsUtils';
import {
  SetPassword, TermsSelection, PaymentDaySelection,
  AmortizationTable, Bank, Selfie, OnePage, Signature,
  FinalScreen, AcceptContract, Pagare, AcceptDirectDebit,
  IDPicture,
  AcceptContractPersonalLoan, OnePagePersonalLoan,
  BankPersonalLoan,
} from './components';
import mapStateToProps from './mapStateToProps';
import { validateForm } from './validations/DigitalSignatureForm';
import loadUser from '../../hoc/loadUser';
import './style.scss';

@connect(
  mapStateToProps,
  {
    ...digitalSignatureActions,
  },
)
@loadUser
@reduxForm({
  form: 'signatureForm',
  validate: validateForm,
  destroyOnUnmount: false,
  enableReinitialize: true,
})
class DigitalSignature extends Component {
  state = {};

  stepsWithoutTinyCircle = [8, 9, 10, 11, 13];

  stepsWithoutBackButton = [1, 2, 8, 9, 10, 11, 13];

  static propTypes = {
    digitalSignature: PropTypes.shape({
      step: PropTypes.number,
      loan: PropTypes.number,
      error: PropTypes.string,
      loading: PropTypes.bool,
      isFetching: PropTypes.bool,
      passwordModal: PropTypes.shape({
        showModal: PropTypes.bool,
      }),
      idValidated: PropTypes.bool,
      errorIdValidation: PropTypes.bool,
      loan_type: PropTypes.string,
      idValidationInfo: PropTypes.shape({}),
      amortizationOptions: PropTypes.arrayOf(PropTypes.shape({})),
      isFetchingIdValidationInfo: PropTypes.bool,
      amortizationInfo: PropTypes.shape({}),
    }),
    formValues: PropTypes.shape({}),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    setPassword: PropTypes.func,
    setTerm: PropTypes.func,
    setPaymentDay: PropTypes.func,
    setBank: PropTypes.func,
    acceptPaymentSummary: PropTypes.func,
    getAmortizationInfo: PropTypes.func,
    setError: PropTypes.func,
    setMati: PropTypes.func,
    acceptContractsSummary: PropTypes.func,
    acceptContract: PropTypes.func,
    acceptDomiciliation: PropTypes.func,
    openConfirmPasswordModal: PropTypes.func,
    confirmPasswordSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    logout: PropTypes.func,
    backStep: PropTypes.func,
    change: PropTypes.func,
    valid: PropTypes.bool,
    touch: PropTypes.func,
    untouch: PropTypes.func,
    user: PropTypes.number,
    loanRequest: PropTypes.number,
    loan: PropTypes.number,
    uploadFile: PropTypes.func,
    uploadIdentityFile: PropTypes.func,
    getIdValidationInfo: PropTypes.func,
    onSelfieSuccess: PropTypes.func,
    onIdentityValidationSuccess: PropTypes.func,
    token: PropTypes.string,
    urlToken: PropTypes.string,
    acceptPagare: PropTypes.func,
    skipToTestUser: PropTypes.bool,
    setErrorIdValidation: PropTypes.func,
    setStep: PropTypes.func,
  };

  componentWillMount() {
    const {
      history,
      token,
      urlToken,
    } = this.props;

    if (!token && !urlToken) {
      history.push('/login');
    }
  }

  componentWillUpdate(nextProps) {
    const { digitalSignature: { step } } = this.props;
    if (step !== nextProps.digitalSignature.step) {
      scroller.scrollTo('app', {
        duration: 0,
        delay: 0,
      });
    }
  }

  onForgotPasswordClick = () => {
    const { closeModal, logout, history } = this.props;
    closeModal('passwordModal');
    Mixpanel.track('app_reset_password_last_step');
    history.push('/recuperar-password');
    logout();
  }

  onPassWordModalClose = () => {
    const { closeModal } = this.props;
    closeModal('passwordModal');
  }

  onSubmit = (values) => {
    const {
      digitalSignature: { step, loan },
      setPassword,
      setTerm,
      setPaymentDay,
      setBank,
      acceptPaymentSummary,
      acceptContractsSummary,
      acceptContract,
      acceptPagare,
      acceptDomiciliation,
      openConfirmPasswordModal,
      skipToTestUser,
      onSelfieSuccess,
      onIdentityValidationSuccess,
      urlToken,
    } = this.props;

    switch (step) {
      case 2:
        setPaymentDay(loan, values.firstPaymentDate);
        Mixpanel.track('app_set_payment_day_step');
        break;
      case 3:
        setTerm(loan, values.term);
        Mixpanel.track('app_set_term_step');
        break;
      case 4:
        acceptPaymentSummary();
        Mixpanel.track('app_accept_payment_summary_step');
        break;
      case 5:
        setBank(loan, values.clabe.replace(' ', ''), values.bank, skipToTestUser);
        Mixpanel.track('app_set_bank_step');
        break;
      case 6:
        onSelfieSuccess();
        Mixpanel.track('app_selfie_step');
        break;
      case 7:
        onIdentityValidationSuccess({ idLoan: loan, documentType: values.documentType });
        Mixpanel.track('app_id_step');
        break;
      case 8:
        acceptContractsSummary();
        Mixpanel.track('app_accept_domiciliation_step');
        break;
      case 9:
        acceptContract();
        Mixpanel.track('app_accept_contract_step');
        break;
      case 10:
        acceptPagare();
        Mixpanel.track('app_accept_pagare_step');
        break;
      case 11:
        acceptDomiciliation();
        Mixpanel.track('app_accept_domiciliation_step');
        break;
      case 12:
        openConfirmPasswordModal(values);
        Mixpanel.track('app_set_signature_step');
        break;
      case 1:
        setPassword(values.password, urlToken);
        Mixpanel.track('app_set_password_step');
        break;
      default:
    }
  };

  getAmortizationInfo = () => {
    const { getAmortizationInfo, digitalSignature: { loan } } = this.props;
    getAmortizationInfo(loan);
  }

  getStepComponent = () => {
    const {
      digitalSignature: { step, loan_type: loanType },
    } = this.props;

    switch (step) {
      case 1:
        return SetPassword;
      case 2:
        return PaymentDaySelection;
      case 3:
        return TermsSelection;
      case 4:
        return AmortizationTable;
      case 5:
        if (loanType === 'PERSONAL_LOAN') {
          return BankPersonalLoan;
        }
        return Bank;
      case 6:
        return Selfie;
      case 7:
        return IDPicture;
      case 8:
        if (loanType === 'PERSONAL_LOAN') {
          return OnePagePersonalLoan;
        }
        return OnePage;
      case 9:
        if (loanType === 'PERSONAL_LOAN') {
          return AcceptContractPersonalLoan;
        }
        return AcceptContract;
      case 10:
        return Pagare;
      case 11:
        return AcceptDirectDebit;
      case 12:
        return Signature;
      case 13:
        return FinalScreen;
      default:
    }

    return SetPassword;
  };

  backStep = () => {
    const { backStep } = this.props;

    backStep();
  }

  updateInput = (input, value) => {
    const { change } = this.props;
    change(input, value);
  }

  onConfirmPasswordSubmit = (values) => {
    const { confirmPasswordSubmit } = this.props;
    confirmPasswordSubmit(values);
    Mixpanel.track('app_confirm_password_modal_accept');
  }

  render() {
    const {
      handleSubmit,
      valid,
      digitalSignature,
      formValues,
      change,
      touch,
      untouch,
      setError,
      user,
      loanRequest,
      loan,
      uploadFile,
      uploadIdentityFile,
      getIdValidationInfo,
      setErrorIdValidation,
      setStep,
      skipToTestUser,
      token,
    } = this.props;

    const {
      step,
      error,
      loading,
      isFetching,
      passwordModal,
      idValidated,
      errorIdValidation,
      idValidationInfo,
      isFetchingIdValidationInfo,
      amortizationOptions,
      amortizationInfo: oldInfo,
      ...restSignatureInfo
    } = digitalSignature;

    const totalSteps = 10;

    const StepComponent = this.getStepComponent();

    const hideTinyCircle = this.stepsWithoutTinyCircle.includes(step);
    const hideBackButtton = this.stepsWithoutBackButton.includes(step);

    const paymentDayOptions = getPaymentDayOptions(amortizationOptions);
    let amortizationInfo = getSelectedOption(amortizationOptions, formValues);

    if (amortizationInfo) {
      amortizationInfo = {
        ...JSON.parse(JSON.stringify(amortizationInfo)),
        paymentsMetadata: getPaymentsMetadata(amortizationInfo),
      };
    }

    return (
      <section id="digital-signature" className="container" styleName="digital-signature page-body">
        {isFetching && <div><Spinner /></div>}
        <div className="clearfix">
          {!hideBackButtton && (
            <div>
              <Button id="btn-back" onClick={this.backStep} color="unstyled" className="btn-back">
                {'<'}
                {' '}
                <span styleName="back-text">Regresar</span>
              </Button>
            </div>
          )}

          {!hideTinyCircle && (
            <div>
              <TinyCircle
                id="tiny-circle"
                role="responsive-hidden"
                styleName="steps-chart"
                currentStep={loanUtils.getStepNumber(Math.floor(step))}
                totalSteps={totalSteps}
              />
            </div>
          )
          }
        </div>

        <Form method="post" className="clearfix" onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          <StepComponent
            className="clearfix"
            loading={loading}
            valid={valid}
            error={error}
            formValues={formValues}
            getAmortizationInfo={this.getAmortizationInfo}
            paymentDayOptions={paymentDayOptions}
            amortizationInfo={amortizationInfo}
            setErrorIdValidation={setErrorIdValidation}
            errorIdValidation={errorIdValidation}
            setStep={setStep}
            change={change}
            touch={touch}
            untouch={untouch}
            setError={setError}
            isFetching={isFetching}
            updateInput={this.updateInput}
            user={user}
            loanRequest={loanRequest}
            loan={loan}
            uploadFile={uploadFile}
            uploadIdentityFile={uploadIdentityFile}
            idValidated={idValidated}
            skipToTestUser={skipToTestUser}
            appToken={token}
            idValidationInfo={idValidationInfo}
            getIdValidationInfo={getIdValidationInfo}
            isFetchingIdValidationInfo={isFetchingIdValidationInfo}
            backStep={this.backStep}
            {...restSignatureInfo}
          />
        </Form>
        {passwordModal && passwordModal.showModal
          && (
            <div>
              <PasswordModal
                passwordModal={passwordModal}
                onSubmit={this.onConfirmPasswordSubmit}
                onClose={this.onPassWordModalClose}
                onForgotPasswordClick={this.onForgotPasswordClick}
              />
            </div>
          )}
      </section>
    );
  }
}

export default DigitalSignature;
