import React, { Component } from 'react';
import {
  object, arrayOf, string, bool, func, number, shape,
} from 'prop-types';
import classnames from 'classnames';
import formatUtils from '@utils/formats';
import googleAnalytics from '@lib/googleAnalytics';
import { Button, ErrorMessage } from '@/components';
import './style.scss';

class TermsSelection extends Component {
  static propTypes = {
    className: string,
    loading: bool,
    error: arrayOf(string),
    // eslint-disable-next-line react/forbid-prop-types
    termsOptions: arrayOf(object),
    formValues: shape({
      term: number,
    }).isRequired,
    change: func,
    isFetching: bool,
  };

  static defaultProps = {
    termsOptions: [],
  };

  componentDidMount() {
    googleAnalytics.visitPage('set-loan-term');
  }

  onSelect = (e) => {
    const { change } = this.props;
    change('term', parseInt(e.target.dataset.term, 10));
  };

  renderOption = (option) => {
    const { formValues } = this.props;

    const classes = classnames({
      'block-option': true,
      active: formValues.term === option.term,
    });

    return (
      <button key={option.term} type="button" styleName={classes} data-term={option.term} onClick={this.onSelect}>
        {option.term}
        {' '}
        pagos mensuales de
        {' '}
        {formatUtils.moneyFormat(option.payment)}
        {' '}
        MXN
      </button>
    );
  };

  render() {
    const {
      className, loading, termsOptions, formValues, error, isFetching,
    } = this.props;

    const optionsForPaymentDate = termsOptions.filter(option => option.firstPaymentAt === formValues.firstPaymentDate);
    const options = optionsForPaymentDate.map((option) => {
      return {
        term: option.installments,
        payment: option.installmentAmount,
      };
    });

    return (
      <section id="terms-selection" styleName="step-form" className={className}>
        <h1>Selecciona el mejor plan para ti.</h1>
        {!isFetching
          && (
            <h2>
              Elige entre estas
              {' '}
              {options.length}
              {' '}
              opciones:
            </h2>
          )}
        <ErrorMessage error={error} />
        <div styleName="inputs-container">
          <div id="term-options" className="row" styleName="block-options-container">
            {options.map(this.renderOption)}
          </div>
          <div className="row">
            <Button
              id="submit"
              styleName="btn-next"
              type="submit"
              color="primary"
              spinner={loading}
              disabled={loading || !formValues.term}
            >
              Siguiente
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default TermsSelection;
