import { last } from 'lodash-es';
import moment from 'moment';
import Decimal from 'decimal.js';
import queryString from 'query-string';
import ENUMS from '@enums';
import { descriptiveDate } from './dateUtils';
import testUsers from '../../config/testUsers';

function getStep(steps, user) {
  const step = last(steps);
  const skipToTestUser = testUsers.noMati.indexOf(user.email) >= 0;
  switch (step) {
    case 'set-password':
    default:
      return 2;
    case 'set-first-payment-date':
      return 3;
    case 'set-loan-term':
      return 4;
    case 'accept-payment-summary':
      return 5;
    case 'set-bank-account':
      if (skipToTestUser) {
        return 8;
      }
      return 6;
    case 'identity-validation':
      return 8;
  }
}

function getStepNumber(step) {
  switch (step) {
    case 9:
    case 10:
    case 11:
      return 9;
    case 12:
      return 10;
    default:
      return step;
  }
}

function getCatRate(rate) {
  const catRates = {
    15: 16.1,
    16: 17.2,
    17: 18.4,
    18: 19.6,
    19: 20.7,
    20: 21.9,
    21: 23.1,
    22: 24.4,
    23: 25.6,
    24: 26.8,
    25: 28.1,
    26: 29.3,
    27: 30.6,
    28: 31.9,
    29: 33.2,
    30: 34.5,
    31: 35.8,
    32: 37.1,
    33: 38.5,
    34: 39.8,
    35: 41.2,
  };

  return catRates[rate] || '';
}

function getLoanName(userInfo) {
  if (userInfo.personal_info) {
    const personalInfo = userInfo.personal_info;
    return `${personalInfo.name} ${personalInfo.first_surname} ${personalInfo.second_surname}`;
  }

  return `${userInfo.name} ${userInfo.firstSurname} ${userInfo.secondSurname}`;
}

function mapLoan(loan, user) {
  return {
    isInitializing: false,
    step: loan && loan.signatureSteps ? getStep(loan.signatureSteps, user) : 1,
    term: loan.installments || 0,
    firstPaymentDate: moment(loan.firstPaymentAt, moment.ISO_8601).format('YYYY-MM-DD') || null,
    clabe: loan.clabe ? loan.clabe.number : null,
    bank: loan.clabe ? loan.clabe.bank : null,
    // loanRequest is from loan table
    loanRequest: loan.loanRequestId || null,
    user: loan.userId || null,
    loan: loan.id || null,
    loan_type: loan.type || null,
    publicId: loan.pubId,
    loanRate: loan.rate,
    debtAmount: loan.debtAmount || 0,
    rate: loan.rate || 0,
    paymentTerm: loan.installmentAmount || 0,
    userInfo: user || {},
  };
}

function getProgress(scheduledPayments) {
  return scheduledPayments.reduce((total, sch) => {
    if (sch.status === ENUMS.scheduledPaymentStatus.PAID) {
      return total + 1;
    }
    return total;
  }, 0);
}

/**
 *
 * @param {Object} scheduledPayments
 * @param {Object} snapshot
 * @returns {Object} { amount: {String}, date: {String} }
 */
function getNextScheduledPayment(scheduledPayments, snapshot) {
  if (snapshot.payment_status === ENUMS.snapshotPaymentStatus.LATE) {
    return {
      amount: Decimal(snapshot.past_due_principal).add(snapshot.past_due_interest).add(snapshot.past_due_iva),
      date: ENUMS.INMEDIATO,
    };
  }

  const scheduledPayment = scheduledPayments.find(sch => sch.status === ENUMS.scheduledPaymentStatus.PENDING);

  return {
    amount: scheduledPayment.amount,
    date: descriptiveDate(moment(scheduledPayment.due_date, 'YYYY-MM-DD')),
  };
}

/**
 * Get pending scheduled payments
 * @param {Array<Object>} scheduledPayments
 * @param {Object} nextScheduledPayment
 * @param {Object} snapshot
 * @returns {Array<Object>}
 */
function getScheduledPayments(scheduledPayments, nextScheduledPayment, snapshot) {
  const scheduled = scheduledPayments.filter(sch => sch.status === ENUMS.scheduledPaymentStatus.PENDING);

  if (scheduled.length > 0 && nextScheduledPayment.amount !== scheduledPayments[0].amount) {
    scheduled[0].due_date = ENUMS.INMEDIATO;
    scheduled[0].amount = nextScheduledPayment.amount;
    scheduled[0].regular_principal = snapshot.past_due_principal;
    scheduled[0].regular_interests = snapshot.past_due_interest;
    scheduled[0].regular_iva = snapshot.past_due_iva;
  }

  return scheduled;
}

function mapNewClabeLoan(loan, user) {
  return {
    isInitializing: false,
    step: 1,
    loan: loan.id || null,
    loan_type: loan.type || null,
    loanRequest: loan.loan_request || null,
    term: loan.term || 0,
    firstPaymentDate: loan.first_payment_date || null,
    user: loan.user,
    publicId: loan.public_id,
    loanRate: loan.rate,
    debtAmount: loan.debt_ammount || 0,
    rate: loan.rate || 0,
    paymentTerm: loan.payment_term || 0,
    userInfo: user,
  };
}

function getStatus({ status, snapshot }) {
  switch (status) {
    case 'PENDING':
      return 'Por firmar';
    case 'EXPIRED':
      return 'Expirado';
    case 'SIGNED':
      return 'Firmado';
    case 'ACTIVE':
      switch (snapshot.payment_status) {
        case 'ON_TIME':
          return 'A tiempo';
        case 'LATE':
          return 'Pendiente de pago';
        default:
          return 'Error';
      }
    case 'PAID_OFF':
      return 'Pagado';
    default:
      return 'Error';
  }
}


function getCurrentPayment(scheduledPayments) {
  return scheduledPayments.find(sp => sp.status === 'PENDING');
}

const isLate = loanStatus => loanStatus === 'Pendiente de pago';

/**
 * Order loans by days priority
 * @param {Array<Object>} loans
 * @param {String} status
 * @param {String} value
 * @paran {Bool} needSnapshot
 * @return {Array<Object>}
 */
function orderByDays(loans, status, value = '', needSnapshot = false) {
  return loans.sort((a, b) => {
    if (a.currentStatus === status && b.currentStatus === status) {
      if (!needSnapshot && value && moment(a[value]).isBefore(b[value])) {
        return -1;
      }
      if (needSnapshot && value && moment(a.snapshot[value]).isBefore(b.snapshot[value])) {
        return -1;
      }
      return 1;
    }
    return 0;
  });
}

/**
 * Order loans by status priority
 * @param {Array<Object>} loans
 * @param {Array<String>} listed priority represented by getStatus
 * @return {Array<Object>}
 */
function orderLoans(loans, priority = []) {
  const sortedByPriority = loans.map((loan) => { return { currentStatus: getStatus(loan), ...loan }; })
    .sort((a, b) => {
      if (priority.indexOf(a.currentStatus) >= 0
        && priority.indexOf(a.currentStatus) < priority.indexOf(b.currentStatus)) {
        return -1;
      }
      return 1;
    });

  const sortedByLate = orderByDays(sortedByPriority, 'Pendiente de pago', 'not_paid_date');
  const sortedByOnTime = orderByDays(sortedByLate, 'A tiempo', 'next_payment_date', true);

  return sortedByOnTime;
}

function isValidIdValidationInfo(idValidationInfo) {
  if (!idValidationInfo) {
    return false;
  }

  const { selfie } = idValidationInfo;
  const queryObject = queryString.parse(selfie.split('?')[1]);
  const expireDate = queryObject['X-Amz-Date'];
  const expiresIn = queryObject['X-Amz-Expires'];
  const expirationDate = moment(expireDate).add(expiresIn, 'seconds').subtract(5, 'minutes');

  if (moment().isAfter(expirationDate)) {
    return false;
  }

  return true;
}

export default {
  getStep,
  getCatRate,
  getLoanName,
  getStepNumber,
  mapLoan,
  getProgress,
  mapNewClabeLoan,
  getNextScheduledPayment,
  getScheduledPayments,
  getStatus,
  getCurrentPayment,
  isLate,
  orderLoans,
  isValidIdValidationInfo,
};
