import React, { Component } from 'react';
import {
  arrayOf, string, bool, shape, number, func,
} from 'prop-types';
import { Field } from 'redux-form';
import googleAnalytics from '@lib/googleAnalytics';
import loanUtils from '@utils/loanUtils';
import {
  Button,
  ErrorMessage,
  SignaturePad,
} from '@components';
import './style.scss';

class Signature extends Component {
  componentDidMount() {
    googleAnalytics.visitPage('set-signature');
  }

  componentWillUnmount() {
    const { updateInput } = this.props;
    updateInput('signature', '');
    clearTimeout(this.timeOut);
  }

  render() {
    const {
      className, error, loading, formValues, userInfo, isNewClabe,
    } = this.props;

    return (
      <section id="signature-page" styleName="step-form step-sign" className={className}>
        <h2>
          Yo,
          {' '}
          {loanUtils.getLoanName(userInfo)}
          {!isNewClabe && ', acepto los documentos de contratación del crédito Digitt.'}
          {isNewClabe && ', acepto el documento de domiciliación anterior.'}
        </h2>
        <p styleName="sing-inside-square">
          Por favor, firma dentro del recuadro
        </p>
        <ErrorMessage error={error} />
        <Field name="signature" component={SignaturePad} />
        <div styleName="btn-container">
          <Button
            id="btn-next"
            styleName="btn-next"
            type="submit"
            color="primary"
            spinner={loading}
            disabled={loading || !formValues.signature}
          >
            Siguiente
          </Button>
        </div>
      </section>
    );
  }
}

Signature.propTypes = {
  className: string,
  error: arrayOf(string),
  formValues: shape({
    signature: string,
  }),
  loading: bool,
  userInfo: shape({
    id: number,
  }),
  updateInput: func,
  isNewClabe: bool,
};

export default Signature;
