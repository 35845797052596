import { reduce, max, map } from 'lodash-es';

export const getPayments = ({ amortization: { payments } }) => {
  if (!payments || payments.length < 2) {
    return {};
  }
  const totalPayments = payments.length;
  const lastPayment = payments[totalPayments - 1].payment;
  const monthlyPayment = payments[totalPayments - 2].payment;

  return {
    totalPayments,
    monthlyPayment,
    lastPayment,
    hasDifferentAmounts: lastPayment !== monthlyPayment,
  };
};

export const getPaymentsTotal = ({ amortization: { payments } }) => {
  if (!payments || payments.length < 2) {
    return 0;
  }

  return reduce(payments, (total, monthlyPayment) => total + monthlyPayment.payment, 0);
};

export const getPaymentsMaxAmount = ({ amortization: { payments } }) => {
  if (!payments || payments.length < 2) {
    return 0;
  }

  return max(map(payments, p => p.payment));
};

export default {
  getPayments,
  getPaymentsTotal,
  getPaymentsMaxAmount,
};
