import React, { Component } from 'react';
import {
  shape, func, string, number,
} from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Form } from 'redux-form';
import * as digitalSignatureActions from '@/store/actions/digitalSignature';
import * as auth from '@/store/actions/auth';
import {
  Button, Spinner, PasswordModal,
} from '@components';
import {
  Bank, AcceptDirectDebit, Signature,
} from '../DigitalSignature/components';
import { FinalScreen } from './components';
import mapStateToProps from './mapStateToProps';
import { validateForm } from './validations';
/* eslint-disable no-console */

import './style.scss';

@connect(
  mapStateToProps,
  {
    ...digitalSignatureActions,
    setToken: auth.setToken,
  },
)
@reduxForm({
  form: 'newClabeForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate: validateForm,
})
class NewClabe extends Component {
  static propTypes = {
    history: shape({
      push: func,
    }).isRequired,
    loan: shape({}),
    digitalSignature: shape({}),
    formValues: shape({}),
    touch: func,
    untouch: func,
    user: shape({}),
    loanRequest: shape({}),
    handleSubmit: func,
    setToken: func,
    urlToken: string,
    setNewClabeInfo: func,
    loanRequestId: number,
    setNewClabeBank: func,
    backStep: func,
    openConfirmPasswordModal: func,
    confirmNewClabePasswordSubmit: func,
    closeModal: func,
    logout: func,
  };

  componentWillMount = () => {
    const {
      history,
      urlToken,
      setNewClabeInfo,
      loan,
      setToken,
    } = this.props;

    if (!urlToken) {
      history.push('/');
    }

    if (urlToken) {
      setToken(urlToken);
      setNewClabeInfo({
        loanId: loan,
      });
    }
  }

  getComponent = () => {
    const { digitalSignature: { step } } = this.props;
    switch (step) {
      case 1:
        return Bank;
      case 2:
        return AcceptDirectDebit;
      case 3:
        return Signature;
      case 4:
        return FinalScreen;
      default:
        return null;
    }
  }

  onSubmit = (values) => {
    const {
      digitalSignature: { step },
      setNewClabeBank,
      openConfirmPasswordModal,
    } = this.props;

    switch (step) {
      case 1:
        setNewClabeBank(values.clabe.replace(' ', ''), values.bank);
        break;
      case 3:
        openConfirmPasswordModal(values);
        break;
      default:
    }
  }

  updateInput = (input, value) => {
    const { change } = this.props;
    change(input, value);
  }

  backStep = () => {
    const { backStep } = this.props;
    backStep();
  }

  onPassWordModalClose = () => {
    const { closeModal } = this.props;
    closeModal('passwordModal');
  }

  onForgotPasswordClick = () => {
    const { closeModal, logout, history } = this.props;
    closeModal('passwordModal');
    history.push('/recuperar-password');
    logout();
  }

  changeClabe = (values) => {
    const { confirmNewClabePasswordSubmit, digitalSignature } = this.props;

    const request = {
      loan: digitalSignature.loan,
      signature: digitalSignature.signature,
      clabe: digitalSignature.clabe,
      bank: digitalSignature.bank,
      password: values.password,
    };

    confirmNewClabePasswordSubmit(request);
  }

  render() {
    const {
      handleSubmit,
      valid,
      digitalSignature,
      formValues,
      change,
      touch,
      untouch,
      user,
      loanRequest,
      loan,
    } = this.props;

    const {
      step,
      error,
      loading,
      isFetching,
      isInitializing,
      passwordModal,
      ...restSignatureInfo
    } = digitalSignature;

    const hideBackButtton = [1, 2, 4].includes(step);

    const FormComponent = this.getComponent();

    return (
      <section className="container" styleName="new-clabe page-body">
        {(isInitializing || isFetching) && <div><Spinner table={isInitializing} /></div>}
        <div className="clearfix">
          {!hideBackButtton && (
            <div>
              <Button id="btn-back" onClick={this.backStep} color="unstyled" className="btn-back">
                {'<'}
                {' '}
                <span styleName="back-text">Regresar</span>
              </Button>
            </div>
          )}
        </div>

        <Form method="post" className="clearfix" onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          {!isInitializing && (
            <div>
              <FormComponent
                className="clearfix"
                loading={loading}
                valid={valid}
                error={error}
                formValues={formValues}
                change={change}
                touch={touch}
                untouch={untouch}
                isFetching={isFetching}
                updateInput={this.updateInput}
                user={user}
                loanRequest={loanRequest}
                loan={loan}
                idValidated
                skipToTestUser={false}
                isNewClabe
                {...restSignatureInfo}
              />
            </div>
          )}
        </Form>
        {(passwordModal && passwordModal.showModal)
          && (
            <div>
              <PasswordModal
                passwordModal={passwordModal}
                onSubmit={this.changeClabe}
                onClose={this.onPassWordModalClose}
                onForgotPasswordClick={this.onForgotPasswordClick}
              />
            </div>
          )}
      </section>
    );
  }
}

export default NewClabe;
