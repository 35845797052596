import React from 'react';
import { string, number, shape } from 'prop-types';
import moment from 'moment';
import loanUtils from '@utils/loanUtils';
import numeral from 'numeral';
import { getPayments, getPaymentsTotal } from '@utils/paymentsUtils';
import Terms from './Terms';
import './style.scss';

moment.locale('es');

function ContractTable(props) {
  const {
    publicId,
    loanRate,
    debtAmount,
    firstPaymentDate,
    userInfo,
    amortizationInfo,
  } = props;

  const today = moment();
  const term = getPayments(amortizationInfo).totalPayments;
  const totalDebtAmount = getPaymentsTotal(amortizationInfo);

  return (
    <table styleName="contract-table">
      <tbody>
        <tr>
          <td styleName="title" colSpan="5">
            CARÁTULA DEL CONTRATO DE CRÉDITO
          </td>
        </tr>
        <tr>
          <td styleName="credit-info" colSpan="5">
            <div>
              <span styleName="label">
                Nombre Comercial del Producto:
              </span>
              {' '}
              Crédito DIGITT
            </div>
            <div styleName="odd">
              <span styleName="label">
                Nombre del Cliente:
              </span>
              {' '}
              {loanUtils.getLoanName(userInfo)}
            </div>
            <div>
              <span styleName="label">
                Tipo de Crédito:
              </span>
              {' '}
              Simple
            </div>
            <div styleName="odd">
              <span styleName="label">
                Número de Crédito:
              </span>
              {' '}
              {publicId}
            </div>
          </td>
        </tr>
        <tr styleName="credit-summary">
          <td>
            <span styleName="label">CAT</span>
            <span styleName="label">Costo Anual Total</span>
          </td>
          <td>
            <span styleName="label">TASA DE INTERÉS ANUAL ORDINARIA</span>
          </td>
          <td>
            <span styleName="label">MONTO DEL CRÉDITO</span>
          </td>
          <td>
            <span styleName="label">MONTO TOTAL A PAGAR</span>
          </td>
          <td>
            <span styleName="label">COMISIONES</span>
            <span styleName="label">Montos y Cláusulas</span>
          </td>
        </tr>
        <tr styleName="credit-summary">
          <td rowSpan="3">
            Para fines informativos y de comparación
            {' '}
            {numeral(loanUtils.getCatRate(loanRate) / 100).format('0,0[.]00%')}
            {' '}
            Fijo sin IVA
          </td>
          <td>
            {numeral(loanRate / 100).format('0,0[.]00%')}
            {' '}
            sin IVA Fijo
          </td>
          <td rowSpan="3">
            {numeral(debtAmount).format('$0,0.00')}
            {' '}
            Pesos
            <span>(Ver Cláusula PRIMERA)</span>
          </td>
          <td>
            {numeral(totalDebtAmount).format('$0,0.00')}
            {' '}
            Pesos
          </td>
          <td rowSpan="3">
            <span>Por apertura: $0.00</span>
            <span>Por Prepago: $0.00</span>
            <span>Por cobranza: $0.00</span>
            <span>(Ver Cláusula QUINTA)</span>
          </td>
        </tr>
        <tr styleName="credit-summary">
          <td>
            <span styleName="label">TASA DE INTERÉS ANUAL MORATORIA</span>
          </td>
          <td>
            <span styleName="label">LUGAR Y FORMA DE PAGO</span>
          </td>
        </tr>
        <tr styleName="credit-summary">
          <td>
            {numeral((loanRate * 2) / 100).format('0,0[.]00%')}
            {' '}
            sin IVA por mora
          </td>
          <td>
            (Ver Cláusula TERCERA)
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            <span styleName="label">CAT</span>
            <span styleName="label">Costo Anual Total</span>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            Para fines informativos y de comparación
            {' '}
            {numeral(loanUtils.getCatRate(loanRate) / 100).format('0,0[.]00%')}
            {' '}
            Fijo sin IVA
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td styleName="no-padding" colSpan="5">
            <table>
              <tbody>
                <tr>
                  <td styleName="no-borders">
                    <span styleName="label">
                      TASA DE INTERÉS ANUAL ORDINARIA
                    </span>
                  </td>
                  <td styleName="no-borders left">
                    <span styleName="label">
                      TASA DE INTERÉS ANUAL MORATORIA
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td styleName="no-padding" colSpan="5">
            <table>
              <tbody>
                <tr>
                  <td styleName="no-borders">
                    {numeral(loanRate / 100).format('0,0[.]00%')}
                    {' '}
                    sin IVA Fijo
                  </td>
                  <td styleName="no-borders left">
                    {numeral((loanRate * 2) / 100).format('0,0[.]00%')}
                    {' '}
                    sin IVA por mora
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            <span styleName="label">MONTO DEL CRÉDITO</span>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            {numeral(debtAmount).format('$0,0.00')}
            {' '}
            Pesos
            <span>(Ver Cláusula PRIMERA)</span>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td styleName="no-padding" colSpan="5">
            <table>
              <tbody>
                <tr>
                  <td styleName="no-borders">
                    <span styleName="label">
                      MONTO TOTAL A PAGAR
                    </span>
                  </td>
                  <td styleName="no-borders left">
                    <span styleName="label">
                      LUGAR Y FORMA DE PAGO
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td styleName="no-padding" colSpan="5">
            <table>
              <tbody>
                <tr>
                  <td styleName="no-borders">
                    {numeral(totalDebtAmount).format('$0,0.00')}
                    {' '}
                    Pesos
                  </td>
                  <td styleName="no-borders left">
                    (Ver Cláusula TERCERA)
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            <span styleName="label">COMISIONES</span>
            <span styleName="label">Montos y Cláusulas</span>
          </td>
        </tr>
        <tr styleName="credit-summary--mobile">
          <td colSpan="5">
            <span>Por apertura: $0.00</span>
            <span>Por Prepago: $0.00</span>
            <span>Por cobranza: $0.00</span>
            <span>(Ver Cláusula QUINTA)</span>
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            <span styleName="label">Metodología para el cálculo de intereses ordinarios:</span>
            {' '}
            Los intereses ordinarios se
            {' '}
            calcularán dividiendo la tasa de interés ordinaria pactada entre 360 (trescientos sesenta)
            {' '}
            días, multiplicándola por el número de días del periodo y multiplicando
            {' '}
            la cantidad resultante por el saldo insoluto.
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            <span styleName="label">Metodología para el cálculo intereses moratorios:</span>
            {' '}
            Los intereses moratorios se calcularán dividiendo la tasa de interés moratoria
            {' '}
            pactada entre 360 (trescientos sesenta) días, multiplicándola por el número
            {' '}
            de días transcurridos desde el momento en que debió realizarse el pago y hasta
            {' '}
            la fecha de pago efectiva y multiplicando la cantidad resultante por el capital
            {' '}
            adeudado, vencido y no pagado.
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            <span styleName="label">Plazo del Crédito:</span>
            <br />
            {term}
            {' '}
            meses.
            <br />
            <span styleName="label">Sus Pagos Serán Como Siguen:</span>
          </td>
        </tr>
        <tr styleName="terms-info">
          <td>
            <span styleName="label">Número</span>
          </td>
          <td>
            <span styleName="label">Monto</span>
          </td>
          <td colSpan="3">
            <span styleName="label">Cuando se realizan los pagos</span>
          </td>
        </tr>
        <Terms amortizationInfo={amortizationInfo} firstPaymentDate={firstPaymentDate} />
        <tr styleName="text">
          <td colSpan="5">
            “Cuide su capacidad de pago, generalmente no debe de exceder del 35%
            {' '}
            de sus ingresos periódicos, los costos por mora son muy elevados”.
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            <span styleName="label">DUDAS, ACLARACIONES Y RECLAMACIONES</span>
            <br />
            Para cualquier duda, aclaración o reclamación, favor de dirigirse a:
            <br />
            Domicilio: José Guadalupe Zuno 1745, Colonia Americana, C.P. 44160 Guadalajara,
            {' '}
            Jalisco Teléfono: (33) 4162 2277 correo electrónico:
            {' '}
            <a href="mailto:hola@digitt.com">hola@digitt.com</a>
            {' '}
            o en su caso a:  PROFECO: teléfono 01-800-468-8722,
            {' '}
            Página de Internet:
            {' '}
            <a href="https://www.gob.mx/profeco" target="_blank" rel="noopener noreferrer">
              www.profeco.gob.mx
            </a>
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            La presente Carátula del Contrato de Crédito forma parte del Contrato de Crédito Simple
            {' '}
            y lo firma el CLIENTE, en señal de conformidad y demás efectos legales que correspondan
            {' '}
            en la Ciudad de Guadalajara a los
            {' '}
            {`${today.format('DD')} días de ${today.format('MMMM')} de ${today.format('YYYY')}.`}
          </td>
        </tr>
        <tr styleName="text">
          <td colSpan="5">
            <span styleName="label">Datos de inscripción en el Registro Público de Contratos de Adhesión:</span>
            {' '}
            10694-2019.
          </td>
        </tr>
      </tbody>
    </table>
  );
}

ContractTable.propTypes = {
  publicId: string,
  loanRate: number,
  debtAmount: number,
  firstPaymentDate: string,
  userInfo: shape({}),
  amortizationInfo: shape({
    amortization: shape({}),
  }),
};

export default ContractTable;
