import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import digitalSignature from './digitalSignature';
import loans from './loans';
import auth, { onCreated as onAuthCreated } from './auth';

const rootReducer = combineReducers({
  form,
  digitalSignature,
  loans,
  auth,
});

export const onCreated = (store) => {
  onAuthCreated(store);
};

export default rootReducer;
