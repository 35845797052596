import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header, Footer, StaticHeader } from './components';
import * as authActions from '@/store/actions/auth';
import './style.scss';

function Page(props) {
  const {
    component: ChildrenComponent,
    altLogo,
    extraBtn,
    totalSteps,
    digitalSignature,
    auth,
    hideStepTracking,
    showMenu,
    logoutAndRedirect,
    isNewClabe,
    user,
    ...rest
  } = props;

  const { step } = digitalSignature;
  const stepsWithStaticHeader = isNewClabe ? [2] : [8, 9, 10, 11];
  const staticHeaderWithoutSteps = isNewClabe ? [2] : [];
  const staticHeader = stepsWithStaticHeader.includes(step);
  const hideStaticHeaderSteps = staticHeaderWithoutSteps.includes(step);

  const onLogout = () => {
    logoutAndRedirect();
  };

  return (
    <Route
      {...rest}
      render={matchProps => (
        <div styleName="page-container">
          {staticHeader ?
            <StaticHeader
              altLogo={altLogo}
              totalSteps={totalSteps}
              digitalSignature={digitalSignature}
              user={user}
              hideStepsTracking={hideStaticHeaderSteps}
            />
            :
            <Header
              altLogo={altLogo}
              extraBtn={extraBtn}
              totalSteps={totalSteps}
              digitalSignature={digitalSignature}
              auth={auth}
              user={user}
              hideStepTracking
              showMenu={showMenu}
              onLogout={onLogout}
            />}

          <section id="digital-signature" className="container" styleName="digital-signature page-body">
             <ChildrenComponent {...matchProps} />
          </section>

          <Footer />
        </div>
      )}
    />
  );
}

Page.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  extraBtn: PropTypes.node,
  altLogo: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  totalSteps: PropTypes.number,
  digitalSignature: PropTypes.shape({
    loading: PropTypes.bool,
    token: PropTypes.string,
  }),
  showMenu: PropTypes.bool,
  logoutAndRedirect: PropTypes.func,
  isNewClabe: PropTypes.bool,
};

Page.defaultProps = {
  className: undefined,
  extraBtn: null,
  totalSteps: 9,
  altLogo: true,
  showMenu: false,
  isNewClabe: false,
};

const mapStateToProps = (state) => {
  const { digitalSignature, auth, loans: { user } } = state;
  return {
    digitalSignature,
    auth,
    user,
  };
};

export default connect(
  mapStateToProps,
  { ...authActions },
)(Page);
