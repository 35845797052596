import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button, Icon } from '../../components';
import './style.scss';

function WaitingModal(props) {
  const { title, error, errorText, errorSubtitleText, setStep } = props;

  return (<Modal id="waiting-modal">
    <ModalBody>
    <div styleName="waiting-modal">
      {!error && <div>
        <div><div styleName="loader" /></div>
        <div styleName="title">
          {title}
        </div>
      </div>}
      {error && <div className="align-center" styleName="error">
        <Icon type="errorCircle" height="80" width="80"/>
        <div styleName="error-text">{errorText}</div>
        {errorSubtitleText && <div styleName="error-subtitle-text">{errorSubtitleText}</div>}
          <div>
            <Button
              id="btn-try-again"
              onClick={() => setStep(6)}
              color="primary"
            >
              Volver a intentar
            </Button>
          </div>
        </div>}
      </div>
    </ModalBody>
  </Modal>);
}

WaitingModal.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  errorSubtitleText: PropTypes.string,
  setStep: PropTypes.func,
}

export default WaitingModal;
