export default {
  middleware: {
    CALL_API: 'CALL_API',
    PROMISE: 'PROMISE',
  },
  loanStatus: {
    PENDING: 'PENDING',
    EXPIRED: 'EXPIRED',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    SIGNED: 'SIGNED',
    PAID_OFF: 'PAID_OFF',
  },
  loanPaymentStatus: {
    ON_TIME: 'ON-TIME',
    LATE: 'LATE',
  },
  paymentTypes: {
    ANTICIPATED: 'ANTICIPATED',
    REGULAR: 'REGULAR',
  },
  snapshotPaymentStatus: {
    ON_TIME: 'ON_TIME',
    LATE: 'LATE',
  },
  scheduledPaymentStatus: {
    PENDING: 'PENDING',
    PAID: 'PAID',
    NOT_PAID: 'NOT_PAID',
  },
  paymentMethods: {
    DOMICILIACION: 'DOMICILIACION',
    TRANSFERENCIA: 'TRANSFERENCIA',
    DEPOSITO: 'DEPOSITO',
  },
  INMEDIATO: 'INMEDIATO',
};
