import React, { PureComponent } from 'react';
import { string, number, shape } from 'prop-types';
import numeral from 'numeral';
import googleAnalytics from '@lib/googleAnalytics';
import happy from '../../../../../../images/icon-happy_face-blue.svg';
import '../style.scss';

class FinalScreen extends PureComponent {
  static propTypes = {
    className: string,
    debtAmount: number.isRequired,
    userInfo: shape({
      id: number,
    }).isRequired,
  }

  componentDidMount() {
    googleAnalytics.visitPage('final-screen');
  }

  render() {
    const { className, debtAmount, userInfo } = this.props;

    return (
      <section id="final-screen-page" styleName="step-form step-10" className={className}>
        <img src={happy} alt="feliz" width="100" height="100" />
        <h1>¡Eso es todo!</h1>
        <h1>Ya tienes tu crédito Digitt</h1>
        <p>
          Te hemos enviado tus documentos firmados a
          {' '}
          <b>{userInfo.email}</b>
        </p>
        <p>
          Haremos el pago de
          {' '}
          <b>
            {numeral(debtAmount).format('$0,0[.]00')}
            {' '}
            pesos
          </b>
          {' '}
          a tu cuenta en un máximo de 24 horas hábiles,
          {' '}
          y te avisaremos por correo en cuanto lo hayamos hecho.
        </p>
        <p>
          No dudes en contactarnos si tienes alguna duda o comentario al respecto, puedes hacerlo por chat,
          {' '}
          llamando al
          {' '}
          <b>(33) 4162-2277</b>
          {' '}
          o escribiendo a
          {' '}
          <a href="mailto:hola@digitt.com" styleName="mailto">
            hola@digitt.com.
          </a>
        </p>
      </section>
    );
  }
}

export default FinalScreen;
