import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import apiUtils from '@utils/apiUtils';
import openpay from '@images/openpay_color.png';
import Mixpanel from '@lib/mixpanel';
import { Button } from '@components';
import './style.scss';
import { debounce } from '../../../lib/util';
/* globals API_BASE_URL, OpenPay */

const getFullName = (personalInfo) => {
  let fullName = `${personalInfo.name} ${personalInfo.first_surname}`;
  if (personalInfo.second_surname) {
    fullName = `${fullName} ${personalInfo.second_surname}`;
  }
  return fullName;
};

function CreditCardForm(props) {
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [cvv, setCvv] = useState();
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errmsg, setErrmsg] = useState(false);

  const firstRender = useRef(true);
  const validateFields = useCallback(debounce(
    () => {
      const errors = [];

      if (!month || month.length !== 2) errors.push('month');
      if (!year || year.length !== 2) errors.push('year');
      if (!cvv || cvv.length < 3 || cvv.length > 5) errors.push('cvv');

      if (errors.length > 0 && isValid) setIsValid(false);
      else if (errors.length === 0 && !isValid) setIsValid(true);
    },
    300,
  ));

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    validateFields();
  }, [month, year, cvv]);

  OpenPay.setId(process.env.OPENPAY_ID);
  OpenPay.setApiKey(process.env.OPENPAY_PUBLIC_KEY);

  if (process.env.NODE_ENV !== 'production') {
    OpenPay.setSandboxMode(true);
  }

  const {
    token,
    user,
    loan,
    onNext,
    onValueChange,
    history,
  } = props;
  const {
    creditCard: cc,
    personal_info: personalInfo,
    address,
  } = user;
  const fullName = getFullName(personalInfo);
  let deviceId;
  const onSubmit = async () => {
    setLoading(true);
    // get device id
    deviceId = OpenPay.deviceData.setup('processCard');
    OpenPay.token.create({
      card_number: cc.credit_card,
      holder_name: fullName,
      expiration_year: year,
      expiration_month: month,
      cvv2: cvv,
      address: {
        city: address.city,
        line3: address.city,
        postal_code: address.zip_code,
        line1: address.street,
        line2: address.colony,
        state: address.state,
        country_code: 'MX',
      },
    }, async (resp) => {
      // success
      // create credit card
      const cardToken = resp.data.id;
      try {
        await apiUtils.callApi({
          endpoint: `${API_BASE_URL}/guaranteed-card`,
          method: 'POST',
          body: {
            deviceSessionId: deviceId,
            token: cardToken,
            loanId: loan.id,
          },
          token,
        });
        await apiUtils.callApi({
          endpoint: `${API_BASE_URL}/loan/freeze`,
          method: 'POST',
          body: {
            loanId: loan.id,
          },
          token,
        });
        setLoading(false);
        onValueChange('token', cardToken);
        Mixpanel.track('app_guaranteed_set_cc_step');
        onNext();
      } catch (error) {
        if (error.response.data.error_code === 3001) {
          setErrmsg('La tarjeta fue rechazada.');
          // Can't recover from this go back go homescreen
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
        if (error.response.data.error_code === 3002) {
          setErrmsg('La tarjeta ha expirado.');
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
        if (error.response.data.error_code === 3003) {
          setErrmsg('Tarjeta declinada.');
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
        if (error.response.data.error_code === 3004) {
          setErrmsg('Tarjeta declinada.');
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
        if (error.response.data.error_code === 3005) {
          setErrmsg('La tarjeta ha sido rechazada por el sistema antifraudes.');
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
        if ([3001, 3002, 3003, 3004, 3005].indexOf(error.response.data.error_code) === -1) {
          setErrmsg('Ocurrio un error creando tu tarjeta, favor de intentarlo mas tarde.');
          setLoading(false);
        }
      }
    });
  };

  // if on cvv
  // else if on 3dsecure
  // loading
  return (
    <div>
      <div className="row">
        {errmsg
          ? (
            <div style={{ margin: '15px', color: '#9f041b' }}>
              {errmsg}
            </div>
          )
          : ''}
        <p>
          Por último, necesitamos los datos de la tarjeta de crédito que
          pagaremos para hacerle una retención por el monto del crédito que te otorgaremos.
        </p>
        <p>
          Recuerda que esta retención sólo se convertirá en un cargo en
          caso de que dejaras de pagar la mensualidad de tu crédito Digitt.
        </p>
        <p>Nota: La retención se renovará cada 7 días de forma automática, sin que tengas que hacer nada de tu lado.</p>
        <p>
          Tarjeta&nbsp;
          {(cc.bank)
            ? cc.bank : ''}
          &nbsp;con terminacion&nbsp;
          {(cc.credit_card)
            ? cc.credit_card.slice(cc.credit_card.length - 4) : ''}
        </p>

        <div className="row" styleName="inputs-container">
          <div>
            Fecha de expiracion
            <div>
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9\s]{2}"
                name="month"
                value={month}
                onChange={e => setMonth(e.target.value)}
                placeholder="MM"
                minLength="2"
                maxLength="2"
                required
              />
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9\s]{2}"
                name="year"
                value={year}
                onChange={e => setYear(e.target.value)}
                placeholder="YY"
                minLength="2"
                maxLength="2"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="date">
              CVV
              <br />
              <input
                type="text"
                inputMode="numeric"
                pattern="[0-9\s]{3,4}"
                name="cvv"
                value={cvv}
                onChange={e => setCvv(e.target.value)}
                placeholder="000"
                minLength="3"
                maxLength="4"
                required
              />
            </label>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: '15px' }}>
        <Button
          id="submit"
          styleName="btn-next"
          type="submit"
          color="primary"
          spinner={loading}
          disabled={loading || !isValid}
          // eslint-disable-next-line react/prop-types
          onClick={onSubmit}
        >
          Siguiente
        </Button>
      </div>
      <div style={{ padding: '15px', maxWidth: '250px' }}>
        <img src={openpay} alt="Openpay logo" styleName="" style={{ maxWidth: '100%' }} />
      </div>
    </div>
  );
}

CreditCardForm.propTypes = {
  token: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  loan: PropTypes.object,
  onNext: PropTypes.func,
  onValueChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
};

export default CreditCardForm;
