import validate from '@/lib/validate';

const signUp = {
  password: {
    presence: true,
    strongPassword: {
      minimum: 8,
    },
    length: {
      maximum: 50,
    },
  },
  confirmPassword: {
    presence: true,
    equality: 'password',
  },
};

const login = {
  email: {
    presence: true,
    email: true,
  },
  password: {
    presence: true,
    length: {
      maximum: 50,
    },
  },
};

const terms = {
  term: {
    presence: true,
    numericality: true,
  },
};

const firstPaymentDate = {
  firstPaymentDate: {
    presence: true,
    numericality: true,
  },
};

const bank = {
  clabe: {
    presence: true,
    length: {
      minimum: 19,
      maximum: 19,
    },
    validClabeSum: true,
  },
  bank: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
};

const signature = {
  signature: {
    presence: true,
  },
};

const constraints = {
  ...signUp,
  ...login,
  ...terms,
  ...firstPaymentDate,
  ...bank,
  ...signature,
};

export const validateForm = (values, props) => {
  const {
    digitalSignature: { step },
  } = props;
  let stepConstraint;

  switch (step) {
    case 1:
      stepConstraint = signUp;
      break;
    case 2:
      stepConstraint = terms;
      break;
    case 3:
      stepConstraint = firstPaymentDate;
      break;
    case 5:
      stepConstraint = bank;
      break;
    case 9:
      stepConstraint = signature;
      break;
    default:
      stepConstraint = signUp;
  }

  const errors = validate(values, stepConstraint);
  return errors;
};

export const validateSingle = field => (value = '') => {
  if (value.length > 0) {
    const errors = validate.single(value, constraints[field]);
    return !!errors;
  }

  return false;
};
