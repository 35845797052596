import React from 'react';
import PropTypes from 'prop-types';

function TooltipWrapper(props) {
  const { children, ...others } = props;
  return (<span {...others}>
    {children}
  </span>);
}

TooltipWrapper.propTypes = {
  children: PropTypes.node,
};

export default TooltipWrapper;
