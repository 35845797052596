import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import './style.scss';

function Animation(props) {
  const {
    children,
    name,
    time,
  } = props;

  return (<CSSTransition
    styleName={name}
    timeout={{ enter: time, exit: time }}
  >
    {children}
  </CSSTransition>);
}

Animation.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  time: PropTypes.number,
};

Animation.defaultProps = {
  time: 300,
};

export default Animation;
