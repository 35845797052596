import validate from '@/lib/validate';

const constraints = {
  email: {
    presence: true,
    email: true,
  },
  password: {
    presence: true,
    strongPassword: {
      minimum: 8,
    },
    length: {
      maximum: 50,
    },
  },
  confirmPassword: {
    equality: 'password',
  },
};

export default function validateForm(values) {
  return validate(values, constraints);
}
