import React from 'react';
import cancel from '@images/cancel.svg';
import Button from '../Button';

import './style.scss';

const OverlayMessage  = (props) => {
  const { onButtonClick } = props;

  return (
    <div styleName="overlay-message">
      <div styleName="content">
        <div styleName="message">
          <img src={cancel} alt="Cancel" />
          Ha habido un error al subir tu fotografía.
          <Button
            id="on_error_button"
            type="button"
            color="primary"
            onClick={onButtonClick}
          >
            Volver a intentar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OverlayMessage;
