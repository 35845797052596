import React from 'react';
import { shape, string } from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import './style.scss';

moment.locale('es');

function Terms(props) {
  const { amortizationInfo, firstPaymentDate } = props;
  const { amortization: { payments } } = amortizationInfo;

  const date = moment(firstPaymentDate, 'YYYY-MM-DD');
  const totalPayments = payments.length;
  const lastPayment = payments[totalPayments - 1].payment;
  const penultimatePayment = payments[totalPayments - 2].payment;

  if (lastPayment !== penultimatePayment) {
    return (
      <React.Fragment>
        <tr styleName="terms-info">
          <td>
            {`${totalPayments - 1} meses`}
          </td>
          <td>
            {numeral(penultimatePayment).format('$0,0.00')}
            <br />
            Pesos Incluye Iva
          </td>
          <td colSpan="3" rowSpan="2">
            El día
            {' '}
            <span styleName="label">{date.format('DD')}</span>
            {' '}
            de cada mes durante todo el plazo del crédito.
          </td>
        </tr>
        <tr styleName="terms-info">
          <td>
            1 mes
          </td>
          <td>
            {numeral(lastPayment).format('$0,0.00')}
            <br />
            Pesos Incluye Iva
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <tr styleName="terms-info">
      <td>
        {`${totalPayments} meses`}
      </td>
      <td>
        {numeral(lastPayment).format('$0,0.00')}
        <br />
        Pesos Incluye Iva
      </td>
      <td colSpan="3">
        El día
        {' '}
        <span styleName="label">{date.format('DD')}</span>
        {' '}
        de cada mes durante todo el plazo del crédito.
      </td>
    </tr>
  );
}

Terms.propTypes = {
  firstPaymentDate: string,
  amortizationInfo: shape({
    amortization: shape({}),
  }),
};

export default Terms;
