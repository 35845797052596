import React, { Component } from 'react';
import {
  shape,
  bool,
  func,
  string,
} from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Spinner } from '@components';
import * as digitalSignature from '../store/actions/digitalSignature';

export default function requireAuthentication(ChildrenComponent) {
  class LoadUser extends Component {
    componentDidMount() {
      const { actions, auth: { token }, urlToken } = this.props;
      if (token || urlToken) {
        actions.loadUser(token || urlToken);
      }
    }

    render() {
      const { digitalSignature: { isInitializing } } = this.props;
      const component = isInitializing ? <Spinner table /> : <ChildrenComponent {...this.props} />;
      return component;
    }
  }

  LoadUser.propTypes = {
    digitalSignature: shape({
      isInitializing: bool,
    }).isRequired,
    actions: shape({
      loadUser: func,
    }).isRequired,
    auth: shape({
      token: string,
    }),
    urlToken: string,
  };

  const mapStateToProps = (state) => {
    return {
      digitalSignature: state.digitalSignature,
      auth: state.auth,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(Object.assign({},
        digitalSignature),
      dispatch),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(LoadUser);
}
