import React, { Component } from 'react';
import { oneOfType, node, arrayOf } from 'prop-types';
import ApplicationError from '@pages/ApplicationError';
import logUtils from '@utils/logUtils';
import { Page } from '@components';

class ErrorBoundary extends Component {
  static propTypes = {
    children: oneOfType([
      arrayOf(node),
      node,
    ]),
  }

  state = {
    hasError: false,
  }

  componentDidCatch = (error) => {
    logUtils.captureError(error);
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Page component={ApplicationError} />;
    }
    return children;
  }
}

export default ErrorBoundary;
