import enums from '../../constants/enums';

function promise() {
  return next => (action) => {
    const promiseAction = action[enums.middleware.PROMISE];

    if (typeof promiseAction === 'undefined') {
      return next(action);
    }

    return promiseAction.action;
  };
}

export default { promise };
