const mixpanel = require('mixpanel-browser');

const mixpanelID = process.env.MIXPANEL_ID;

if (mixpanelID !== '') {
  mixpanel.init(`${mixpanelID}`);
  mixpanel.track('page_view');
}

const actions = {
  track: (eventName, props = {}) => {
    if (mixpanelID) mixpanel.track(eventName, { ...props });
  },
  track_links: (query, eventName) => {
    if (mixpanelID) mixpanel.track_links(query, eventName);
  },
  identify: (uniqueId) => {
    if (mixpanelID) mixpanel.identify(uniqueId);
  },
  people: {
    set: (props) => {
      if (mixpanelID) mixpanel.people.set(props);
    },
  },
};

export default actions;
