import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import loanFetcher from '@libs/api-loans';
import {
  arrayOf, string, number, shape, func,
} from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import Mixpanel from '@lib/mixpanel';
import { Button, ErrorMessage } from '@components';
import AcceptContract from './AcceptContract';
import './style.scss';
/* globals API_URL */

const steps = {
  0: AcceptContract,
};

function Contract(props) {
  const {
    className,
    error: err,
    publicId,
    user: userInfo,
    token,
    loanId,
    onNext,
  } = props;
  const { data: amortizationInfo, error } = useSWR(
    [`${API_URL}/get-loan-amortization/${loanId}`, token], loanFetcher(token),
  );
  const [contractsStep, setContractStep] = useState(0);

  useEffect(() => {
    googleAnalytics.visitPage('accept-contract');
  }, []);

  const onSubmit = () => {
    setContractStep(contractsStep + 1);
    Mixpanel.track('app_guaranteed_accept_contract_step');
    onNext();
  };

  if (error) {
    return <div>error</div>;
  }
  if (!amortizationInfo) {
    return <div>loading</div>;
  }

  const [firstPayment] = amortizationInfo.amortization.payments;
  const firstPaymentDate = firstPayment.due_date;
  const {
    rate: loanRate,
    debt_ammount: debtAmount,
    term,
  } = amortizationInfo.amortization;

  const ContractComponent = steps[contractsStep];

  return (
    <section id="accept-contract-page" styleName="step-form step-08" className={className}>
      <ErrorMessage error={err} />
      <div styleName="container-btn-next">
        <Button
          id="submit-contract"
          styleName="btn-next"
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          Estoy de acuerdo
        </Button>
      </div>
      <ContractComponent
        userInfo={userInfo}
        amortizationInfo={amortizationInfo}
        firstPaymentDate={firstPaymentDate}
        loanRate={loanRate}
        debtAmount={debtAmount}
        term={term}
        publicId={publicId}
      />
      <Button
        id="submit"
        styleName="btn-next"
        type="submit"
        color="primary"
        onClick={onSubmit}
      >
        Estoy de acuerdo
      </Button>
    </section>
  );
}

Contract.propTypes = {
  className: string,
  error: arrayOf(string),
  publicId: string,
  loanId: number,
  user: shape({
    id: number,
  }),
  token: string,
  onNext: func,
};

export default Contract;
