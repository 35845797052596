import React, { Component } from 'react';
import {
  arrayOf, string, number, bool, shape,
} from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import formats from '@utils/formats';
import { Button, Table, ErrorMessage } from '@/components';
import CreditSummary from '../CreditSummary';
import paymentSummary from '../../config/paymentSummary';
import './style.scss';

class AmortizationTable extends Component {
  static propTypes = {
    className: string,
    valid: bool,
    loading: bool,
    rate: number,
    debtAmount: number,
    userInfo: shape({}),
    amortizationInfo: shape({}),
    error: arrayOf(string),
  }

  state = {
    mobileTable: false,
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    googleAnalytics.visitPage('accept-payment-summary');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    const mobileTable = window.innerWidth <= 900;
    this.setState({
      mobileTable,
    });
  }

  TableComponent = row => (
    <div styleName="table-component">
      <div className="clearfix">
        <span styleName="description">
          Amortización de capital:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.capital_amortization)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          Intereses:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.interest)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          IVA:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.iva)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          Mensualidad:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.payment)}
        </span>
      </div>
    </div>
  );

  render() {
    const {
      className,
      valid,
      loading,
      rate,
      debtAmount,
      userInfo,
      amortizationInfo,
      error,
    } = this.props;
    const { mobileTable } = this.state;
    const { desktopColumns, mobileColumns, format } = paymentSummary;
    const columns = mobileTable ? mobileColumns : desktopColumns;

    if (!amortizationInfo) {
      return null;
    }

    let remainingPrincipal = debtAmount;
    const tableRows = amortizationInfo.scheduledPayments.map((sp) => {
      remainingPrincipal -= sp.regularPrincipal;
      return {
        number: sp.number,
        due_date: sp.dueDate,
        payment: sp.amount,
        capital_amortization: sp.regularPrincipal,
        interest: sp.regularInterest,
        iva: sp.regularIva,
        // Prevent floating point errors with validation
        outstanding_balance: remainingPrincipal > 0.01 ? remainingPrincipal : 0,
      };
    });

    return (
      <section id="amortization-table-page" styleName="step-form step-04" className={className}>
        <div styleName="info">
          <h1>Te presentamos tu tabla de amortización informativa:</h1>
          <ErrorMessage error={error} />
          <CreditSummary user={userInfo} debtAmount={debtAmount} rate={rate} amortization={amortizationInfo} />
        </div>

        <div className="row" styleName="table-summary-container">
          <Table
            id="amortization-table"
            columns={columns}
            rows={tableRows}
            expandComponent={this.TableComponent}
            formats={format}
          />
        </div>

        <div className="row">
          <div styleName="inputs-container">
            <Button
              id="submit"
              styleName="btn-next"
              type="submit"
              color="primary"
              spinner={loading}
              disabled={loading || !valid}
            >
              Siguiente
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

export default AmortizationTable;
