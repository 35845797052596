const ONE_TO_TWENTYNINE = [
  'uno', 'dos', 'tres', 'cuatro', 'cinco',
  'seis', 'siete', 'ocho', 'nueve', 'diez',
  'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis',
  'diecisiete', 'dieciocho', 'diecinueve',
  'veinte', 'veintiuno', 'veintidós', 'veintitrés',
  'veinticuatro', 'veinticinco', 'veintiséis',
  'veintisiete', 'veintiocho', 'veintinueve',
];

const TENS = [
  'diez', 'veinte', 'treinta', 'cuarenta', 'cincuenta',
  'sesenta', 'setenta', 'ochenta', 'noventa',
];

const CENTS = [
  'cien', 'doscientos', 'trescientos', 'cuatrocientos',
  'quinientos', 'seiscientos', 'setecientos',
  'ochocientos', 'novecientos',
];

const SCALES = ['mil', 'millón', 'billón', 'trillón'];

// helper function for use with Array.filter
function isTruthy(item) {
  return !!item;
}

// convert a number into "chunks" of 0-999
function chunk(number) {
  const thousands = [];
  let amount = number;

  while (amount > 0) {
    thousands.push(amount % 1000);
    amount = Math.floor(amount / 1000);
  }

  return thousands;
}

// translate a number from 1-999 into English
function toWord(number) {
  let tens;
  let ones;
  const words = [];

  if (number < 30) {
    return ONE_TO_TWENTYNINE[number - 1]; // may be undefined
  }

  if (number < 100) {
    ones = number % 10;
    tens = number / 10 | 0; // eslint-disable-line no-bitwise

    words.push(TENS[tens - 1]);
    words.push(toWord(ones));

    return words.filter(isTruthy).join(' y ');
  }

  const hundreds = number / 100 | 0; // eslint-disable-line no-bitwise
  tens = number % 100;

  if (hundreds === 1 && tens !== 0) {
    words.push('ciento');
    words.push(toWord(tens % 100));
  } else {
    words.push(CENTS[hundreds - 1]);
    words.push(toWord(tens % 100));
  }

  return words.filter(isTruthy).join(' ');
}

// append the word for a scale. Made for use with Array.map
function appendScale(word, exp) {
  if (!word) {
    return null;
  }
  const value = word === 'uno' ? 'un' : word;
  const scale = SCALES[exp - 1];
  return [value, scale].filter(isTruthy).join(' ');
}

function numberToWords(num) {
  const numSplitted = num.toString().split('.');
  let cents = '';
  const amount = Math.floor(num);
  let number = null;
  const floatPointLength = (numSplitted[1] || '').length;

  switch (floatPointLength) {
    case 1:
      cents = `${numSplitted[1]}0`;
      break;
    case 2:
      cents = `${numSplitted[1]}`;
      break;
    default:
      cents = '00';
      break;
  }

  if (amount < 30) {
    number = ONE_TO_TWENTYNINE[amount - 1];
  } else {
    number = chunk(amount)
      .map(toWord)
      .map(appendScale)
      .filter(isTruthy)
      .reverse()
      .join(' ');
  }

  return `${number} ${cents}/100 M.N.`;
}

function centsToFloat(cents) {
  const centsStr = String(cents);
  const integerPart = centsStr.substring(0, centsStr.length - 2);
  const centsPart = centsStr.substring(centsStr.length - 2);
  return Number(`${integerPart}.${centsPart}`);
}

export default {
  numberToWords,
  centsToFloat,
};
