/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { shape, string, number } from 'prop-types';
import moment from 'moment';
import formats from '@utils/formats';
import loanUtils from '@utils/loanUtils';
import currencyUtils from '@utils/currencyUtils';
import googleAnalytics from '@lib/googleAnalytics';
import './style.scss';

class Pagare extends PureComponent {
  componentDidMount() {
    googleAnalytics.visitPage('accept-pagare');
  }

  render() {
    const {
      className,
      userInfo,
      loanRate,
      amortizationInfo,
    } = this.props;

    if (!amortizationInfo) {
      return null;
    }

    const totalDebtAmount = amortizationInfo.paymentsMetadata.totalAmount;
    const today = moment([]).format('DD MMMM YYYY').split(' ').join(' de ');
    const textDebt = currencyUtils.numberToWords(totalDebtAmount.toFixed(2)).toUpperCase();
    const dailyLateRateInterest = parseFloat(((loanRate * 2) / 360).toFixed(2));
    const lateRateInterest = loanRate * 2;

    return (
      <section id="pagare-page" styleName="step-form step-pagare" className={className}>
        <div styleName="today">{today}</div>
        <div styleName="title">PAGARÉ</div>

        <div styleName="subtitle">
          Bueno por:
          {' '}
          {formats.moneyFormat(totalDebtAmount)}
          {' '}
          (
          {textDebt}
          ),
        </div>

        <p>
          A través de la suscripción de este PAGARÉ,
          <span>
            {' '}
            {loanUtils.getLoanName(userInfo)}
            {' '}
          </span>
          (en adelante el “ACREDITADO”) se obliga incondicionalmente a pagar, sin protesto o requisito previo alguno, a la orden de
          {' '}
          <span>
            PAGOSAFE, S.A.P.I. DE C.V.
          </span>
          , la cantidad de:
          <span>
            {' '}
            {formats.moneyFormat(totalDebtAmount)}
            {' '}
            (
            {textDebt}
            )
          </span>
          , en su domicilio ubicado en José Guadalupe Zuno No. 1745, Col. Americana, C.P. 44160, Guadalajara, Jalisco. o, a través de otro medio o lugar que
          <span>
            {' '}
            PAGOSAFE, S.A.P.I. DE C.V.
            {' '}
          </span>
          indique.
          Monto que comprende el capital dispuesto y recibido, adicionado por los intereses ordinarios calculados a razón de aplicar un factor de TASA DE INTERÉS.
        </p>

        <p>
          La falta de pago oportuno de cualquier cantidad pagadera conforme a este PAGARÉ,
          generará intereses moratorios a cargo del ACREDITADO a razón del
          {' '}
          {`${dailyLateRateInterest}%`}
          {' '}
          diario sobre saldos insolutos no pagados (
          {`${lateRateInterest}%`}
          {' '}
          anual),
          los cuales se calcularán desde el día en que se verifique el incumplimiento de pago y hasta la total regularización del adeudo.
        </p>

        <p>
          Cualquier pago que realice el ACREDITADO será aplicado primero al pago de intereses moratorios, si los hubiere, intereses ordinarios, accesorios y, finalmente, a capital.
        </p>

        <p styleName="last-paragraph">
          Para todo lo relativo a la interpretación, cumplimiento o requerimiento judicial de las obligaciones contraídas en este PAGARÉ,
          El ACREDITADO se somete expresamente a las Leyes aplicables de los Estados Unidos Mexicanos y,
          a la jurisdicción de los Tribunales Competentes del Estado de Jalisco, renunciando a cualquier otro fuero que pudiere corresponderle en razón de su domicilio presente o futuro.
        </p>

        <div styleName="accept">Acepta:</div>

        <p>
          <span>DATOS DEL ACREDITADO</span>
          <br />
          Nombre:
          {' '}
          {loanUtils.getLoanName(userInfo)}
          <br />
          Calle:
          {' '}
          {userInfo.address.street}
          {' '}
          {userInfo.address.number}
          <br />
          Colonia:
          {' '}
          {userInfo.address.colony}
          <br />
          Municipio:
          {' '}
          {userInfo.address.city}
          <br />
          Estado:
          {' '}
          {userInfo.address.state}
          <br />
          CP:
          {' '}
          {userInfo.address.zip}
        </p>
      </section>
    );
  }
}

Pagare.propTypes = {
  className: string,
  userInfo: shape({
    id: number,
  }),
  loanRate: number.isRequired,
  amortizationInfo: shape({
    amortization: shape({}),
  }),
};

export default Pagare;
