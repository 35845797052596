import React, { useState, useEffect } from 'react';
import googleAnalytics from '@lib/googleAnalytics';
import { calculateClabeCheckDigit } from '@lib/validate';
import useAxios from 'axios-hooks';
import PropTypes from 'prop-types';
import banksClabe from '@/constants/clabe';
import Mixpanel from '@lib/mixpanel';
import {
  Button, ErrorMessage,
} from '@components';
import './style.scss';
/* globals API_URL */

function Bank(props) {
  const {
    className,
    isNewClabe = true,
    loanId,
    onNext,
    onValueChange,
    loan,
    token,
    history,
  } = props;
  const [clabe, setClabe] = useState();
  const [valid, setValid] = useState(false);
  const [bank, setBank] = useState('');
  const [showClabeErrorText, setShowClabeErrorText] = useState(false);
  const { bank: loanBank } = loan;

  useEffect(() => {
    googleAnalytics.visitPage('set-bank-account');
    setBank(loanBank);
    setClabe(loan.clabe);
  }, []);

  const [
    { loading, error },
    postBankAccount,
  ] = useAxios(
    {
      url: `${API_URL}/set-bank-account`,
      method: 'POST',
    },
    { manual: true },
  );
  const onSubmit = async () => {
    try {
      await postBankAccount({
        data: {
          loan: loanId,
          clabe,
          bank,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      Mixpanel.track('app_guaranteed_set_bank_step');
      return onNext();
    } catch (err) {
      if (err.response.status === 405 || err.response.status === 401) {
        // redirect to loan list
        history.push('/');
        return (<div>Error</div>);
      }
      return (<div>Error</div>);
    }
  };

  const onClabeChange = (e) => {
    const { target: { value } } = e;
    const newValue = value.replace(/\s/g, '');
    setClabe(newValue);
    onValueChange('clabe', newValue);
    if (newValue.length >= 3) {
      const prefix = newValue.substr(0, 3);
      const selectedBank = banksClabe[prefix] || '';
      setBank(selectedBank);
    } else {
      setBank(loanBank);
    }
    if (newValue.length === 18) {
      const lastDigit = parseInt(newValue.substr(17, 18), 10);
      const checkDigit = parseInt(calculateClabeCheckDigit(newValue), 10);
      if (lastDigit !== checkDigit) {
        setShowClabeErrorText(true);
        setValid(false);
      } else {
        setValid(true);
        setShowClabeErrorText(false);
      }
    } else {
      setValid(false);
    }
  };

  return (
    <section id="bank-page" styleName="step-form" className={className}>
      <h1>
        Ingresa tu
        {isNewClabe ? ' nueva ' : ' '}
        CLABE interbancaria.
      </h1>
      <p className="title-description">
        Ésta deberá ser la CLABE de tu cuenta de débito a la que
        {' '}
        haremos los cargos mensuales del pago de tu crédito Digitt.
      </p>
      <p className="title-description">
        La cuenta deberá estar a tu nombre y le haremos un abono de un centavo para poder verificarla.
      </p>
      <br />
      <p className="title-description">
        <span className="strong">
          NOTA:
          {' '}
        </span>
        Puedes encontrarla en tus estados de cuenta de tu cuenta de débito.
        O también, puedes consultarla a través de tu banca en línea o el app de tu banco.
        Si de plano no la encuentras, comunícate con tu banco y ellos te la tendrán que proporcionar.
      </p>
      <ErrorMessage error={error} />
      <div className="row" styleName="inputs-container input-container">
        <div className="col-12 col-4-ns">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="clabe">
            CLABE Interbancaria (18 dígitos)
            <br />
          </label>
          <input
            id="clabe"
            name="clabe"
            type="text"
            styleName="input-field bank"
            onChange={onClabeChange}
            value={clabe}
          />
          {showClabeErrorText && (
            <div className="" styleName="error error-text" style={{ textAlign: 'center' }}>
              Por favor ingresa una CLABE válida.
            </div>
          )}
        </div>
        <div className="col-12 col-4-ns">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label htmlFor="bank">
            Banco
            <br />
          </label>
          <input
            disabled
            type="text"
            styleName="input-field bank"
            name="bank"
            id="bank"
            value={bank}
          />
        </div>
        <div styleName="container-btn-next">
          <Button
            id="submit"
            styleName="btn-next"
            type="submit"
            color="primary"
            spinner={loading}
            disabled={loading || !valid || !clabe}
            onClick={onSubmit}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </section>
  );
}

Bank.propTypes = {
  className: PropTypes.string,
  loanId: PropTypes.number,
  isNewClabe: PropTypes.bool,
  onNext: PropTypes.func,
  onValueChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  loan: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object,
  token: PropTypes.string,
};

export default Bank;
