import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalAnimation from './ModalAnimation';

export { Modal };
export { ModalBody }
export {ModalHeader }
export { ModalFooter } 
export { ModalAnimation } 
export { default } from './Modal';
