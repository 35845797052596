import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

/* eslint-disable no-console */
const isProduction = process.env.NODE_ENV === 'production';

function initLog() {
  try {
    if (isProduction) {
      Sentry.init({
        dsn: 'https://268f5267e72d4df9909f030a7b8b5412@sentry.io/1439716',
        debug: isProduction,
        environment: process.env.NODE_ENV,
      });
    }
  } catch (e) {
    console.error(e);
  }
}

function captureError(errorInfo) {
  try {
    if (isProduction) {
      Sentry.captureException(errorInfo);
    }
    console.error(errorInfo);
  } catch (e) {
    console.error(e);
  }
}

initLog();

const sentryMiddleware = createSentryMiddleware(Sentry, {});

export default {
  captureError,
  sentryMiddleware,
};
