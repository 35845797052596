import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import fileUploader from '@libs/api-fileuploader';
import { isMobile } from 'react-device-detect';
import Mixpanel from '@lib/mixpanel';
import {
  DesktopCameraInput, MobileCameraInput,
  OverlayMessage,
} from '@components';
import './style.scss';

function Selfie(props) {
  const isFetching = false;
  const [showOverlay, setOverlay] = useState(false);
  const [errorRequest, setErrorRequest] = useState();
  const [cameraElement, setCameraElement] = useState(null);
  const {
    loanRequest,
    onNext,
    className,
    token,
  } = props;

  const setCameraElementRef = (element) => {
    setCameraElement(element);
  };

  useEffect(() => {
    googleAnalytics.visitPage('set-selfie-photo-info');
    return () => {
      if (cameraElement && cameraElement.onPauseCamera) {
        cameraElement.onPauseCamera();
      }
    };
  }, []);

  const onSuccess = async (file) => {
    setOverlay(false);
    try {
      await fileUploader({
        image: file,
        id_type: '',
        image_name: 'IMAGE_SELFIE',
        loan_request: loanRequest,
        token,
      });
      Mixpanel.track('app_guaranteed_selfie_step');
      onNext();
    } catch (error) {
      setOverlay(true);
      setErrorRequest(file);
    }
  };

  const tryAgain = () => {
    onSuccess(errorRequest);
  };

  return (
    <section styleName="step-form step-06" className={className}>
      <h1>Tómate una selfie:</h1>
      <div styleName="camera-input-container">
        {!isMobile
          && (
            <DesktopCameraInput
              frameType="HEAD"
              onSucces={onSuccess}
              disableButton={isFetching}
              ref={setCameraElementRef}
            />
          )}
        {isMobile
          && (
            <MobileCameraInput
              frameType="HEAD"
              onSucces={() => {}}
              disableButton={isFetching}
            />
          )}
      </div>
      {showOverlay && <div><OverlayMessage onButtonClick={tryAgain} /></div>}
    </section>
  );
}

Selfie.propTypes = {
  onNext: PropTypes.func,
  loanRequest: PropTypes.number,
  token: PropTypes.string,
};

export default Selfie;
