import React, { useState, useEffect } from 'react';
import {
  string, number, func,
} from 'prop-types';
import { map } from 'lodash-es';
import formats from '@utils/formats';
import useAxios from 'axios-hooks';
import Mixpanel from '@lib/mixpanel';
import { Button, Table, ErrorMessage } from '@/components';
import paymentSummary from '../config/paymentSummary';
import './style.scss';
import CreditSummary from '../../DigitalSignature/components/CreditSummary';
/* globals API_URL */

function AmortizationTable(props) {
  const {
    className,
    loanId,
    token,
    onNext,
  } = props;
  const [mobileTable, setMobileTable] = useState(false);
  const { desktopColumns, mobileColumns, format } = paymentSummary;
  const columns = mobileTable ? mobileColumns : desktopColumns;

  const [{ data: amortizationInfo, loading, error }] = useAxios({
    url: `${API_URL}/get-loan-amortization/${loanId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const onResize = () => {
    const isMobile = window.innerWidth <= 900;
    setMobileTable(isMobile);
  };

  const next = () => {
    Mixpanel.track('app_guaranteed_amortization_step');
    onNext();
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  const TableComponent = row => (
    <div styleName="table-component">
      <div className="clearfix">
        <span styleName="description">
          Amortización de capital:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.capital_amortization)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          Intereses:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.interest)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          IVA:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.iva)}
        </span>
      </div>
      <div className="clearfix">
        <span styleName="description">
          Mensualidad:
        </span>
        <span styleName="value">
          {formats.moneyFormat(row.payment)}
        </span>
      </div>
    </div>
  );

  const getAmortizationInfoRows = () => (
    map(amortizationInfo.amortization.payments, (payment, i) => Object.assign({}, payment, {
      number: i + 1,
      ...payment,
    }))
  );

  return (
    <section id="amortization-table-page" styleName="step-form step-04" className={className}>
      <div styleName="info">
        <h1>Te presentamos tu tabla de amortización informativa:</h1>
        <ErrorMessage error={error} />
        <CreditSummary amortizationInfo={amortizationInfo} />
      </div>

      <div className="row" styleName="table-summary-container">
        <Table
          id="amortization-table"
          columns={columns}
          rows={amortizationInfo.amortization ? getAmortizationInfoRows() : []}
          expandComponent={TableComponent}
          formats={format}
        />
      </div>

      <div className="row">
        <div styleName="inputs-container">
          <Button
            id="submit"
            styleName="btn-next"
            type="submit"
            color="primary"
            onClick={next}
            spinner={loading}
            disabled={loading}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </section>
  );
}

AmortizationTable.propTypes = {
  className: string,
  loanId: number,
  token: string,
  onNext: func,
};

export default AmortizationTable;
