import React, { PureComponent } from 'react';
import { string, number, shape } from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import googleAnalytics from '@lib/googleAnalytics';
import formats from '@utils/formats';
import './style.scss';

class OnePage extends PureComponent {
  componentDidMount() {
    googleAnalytics.visitPage('accept-one-page');
  }

  renderTerms = paymentsData => (
    <React.Fragment>
      <span>
        {' '}
        {paymentsData.hasDifferentAmounts ? paymentsData.paymentsCount - 1 : paymentsData.paymentsCount}
        {' '}
        pagos mensuales
        {' '}
      </span>
      de
      <span>
        {' '}
        {formats.moneyFormat(paymentsData.monthlyPaymentAmount)}
        {' pesos '}
      </span>
      {paymentsData.hasDifferentAmounts
        && (
          <React.Fragment>
            y uno de
            <span>
              {' '}
              {formats.moneyFormat(paymentsData.lastPaymentAmount)}
              {' pesos '}
            </span>
          </React.Fragment>
        )}
    </React.Fragment>
  );

  renderTotal = paymentsData => (
    <React.Fragment>
      {paymentsData.hasDifferentAmounts ? paymentsData.paymentsCount - 1 : paymentsData.paymentsCount}
      {' pagos mensuales '}
      de
      {' '}
      {formats.moneyFormat(paymentsData.monthlyPaymentAmount)}
      {' pesos'}
      {paymentsData.hasDifferentAmounts
        && (
          <React.Fragment>
            , más  un último pago de
            {' '}
            {formats.moneyFormat(paymentsData.lastPaymentAmount)}
            {' pesos'}
          </React.Fragment>
        )}
      {' '}
      será de
      <span>
        {' '}
        {formats.moneyFormat(paymentsData.totalAmount)}
        {' pesos '}
      </span>
    </React.Fragment>
  );

  render() {
    const {
      className,
      debtAmount,
      loanRate,
      firstPaymentDate,
      amortizationInfo,
    } = this.props;
    if (!amortizationInfo) {
      return null;
    }

    const nextPaymentDate = moment(amortizationInfo.firstPaymentAt, 'YYYY-MM-DD');
    const moratoryRate = numeral((loanRate * 2) / 100).format('0,0[.]00%');

    return (
      <section id="one-page" styleName="step-form step-07" className={className}>
        <h1>En Digitt creemos en la transparencia, ¡odiamos los contratos confusos y las letras chiquitas!</h1>
        <h2>
          Por eso, aquí resumimos las características más importantes de los documentos
          que firmarás para obtener tu crédito:
        </h2>
        <div styleName="contract-summary">
          <ul>
            <li>
              Digitt te otorgará un crédito por
              <span>
                {' '}
                {formats.moneyFormat(debtAmount)}
                {' pesos '}
              </span>
              a una tasa de interés del
              <span>
                {' '}
                {`${loanRate}%`}
                {' anual '}
              </span>
              para liquidar la deuda de tu(s) tarjeta(s) de crédito. Realizaremos el pago directamente a tu tarjeta.
            </li>

            <li>
              Pagarás tu crédito Digitt en
              {this.renderTerms(amortizationInfo.paymentsMetadata)}
              cada
              <span>
                {' '}
                {moment(firstPaymentDate, 'YYYY-MM-DD').date()}
                {' '}
              </span>
              de mes
              {' '}
              empezando el próximo
              <span>
                {' '}
                {`${nextPaymentDate.date()} de ${nextPaymentDate.format('MMMM')} de ${nextPaymentDate.year()}`}
                {'. '}
              </span>
              Para hacerlo más cómodo para ti, cada mes haremos un cargo automático a tu cuenta bancaria,
              ¡sólo deberás preocuparte por tener fondos suficientes en tu cuenta!
            </li>

            <li>
              El monto total que pagarás, sumando tus
              {' '}
              {this.renderTotal(amortizationInfo.paymentsMetadata)}
            </li>

            <li>
              En Digitt los intereses ordinarios se calculan sobre el saldo insoluto,
              ¡por eso es un crédito tan amigable! En tu tabla de amortización puedes ver que
              <span>
                {' '}
                cada mes pagas menos intereses y más capital.
              </span>
            </li>

            <li>
              En caso de que te llegaras a atrasar en tus pagos, se te cobrarán
              <span>
                {' '}
                intereses moratorios
                {' '}
              </span>
              sobre el saldo vencido que esté pendiente de pagarse a una
              <span>
                {' '}
                tasa del
                {` ${moratoryRate} `}
                anual,
                {' '}
              </span>
              ¡evítalos a toda costa!
            </li>

            <li>
              ¡No cobramos comisiones de ningún tipo! ¡No nos gustan!
            </li>

            <li>
              Siempre que vayas al corriente con tus pagos podrás realizar
              <span>
                {' '}
                abonos a capital en cualquier momento, por cualquier monto y sin ninguna penalización.
                {' '}
              </span>
              Estos pagos anticipados reducirán el plazo en que pagarás tu crédito.
              Ojo, los pagos anticipados no te exentan de hacer tu pago mensual cada fecha de pago.
              Es únicamente un pago extra que te ayuda a disminuir el pago de intereses.
              El mecanismo para realizar pagos anticipados es por medio de una transferencia o depósito bancario
              (puedes revisar los detalles en nuestra sección de ayuda).
            </li>

            <li>
              ¡Te enviaremos tu
              <span>
                {' '}
                estado de cuenta a tu correo electrónico
                {' '}
              </span>
              durante los primeros 5 días hábiles de cada mes!
            </li>

            <li>
              ¡Si cambias de domicilio, teléfono o correo electrónico háznoslo saber!
              De esa manera podremos mantenerte al tanto de toda la información relevante de tu crédito.
            </li>

            <li>
              En el contrato, nos das autorización para compartir tu información
              únicamente con proveedores de servicios necesarios para la operación del crédito.
              <span>
                {' '}
                ¡Jamás compartiremos tu información con fines publicitarios!
                {' '}
              </span>
              ¡En Digitt nos tomamos muy en serio tu privacidad!
            </li>

            <li>
              Como garantía con fines de cobranza, también firmarás un Pagaré por el monto total a pagar del crédito
              (el capital más los intereses ordinarios).
              Al liquidar tu crédito, te enviaremos por correo electrónico tu Carta Finiquito junto con este Pagaré.
            </li>

            <li>
              Como notaste al completar la solicitud, consultamos Buró de Crédito.
              También, mes con mes, les reportamos el comportamiento de todos nuestros usuarios.
              <span>
                {' '}
                ¡Pagar tu crédito Digitt a tiempo te ayudará a mejorar tu historial de crédito!
                {' '}
              </span>
              De lo contrario, el retrasarte en tus pagos, podría afectar tu historial en Buró de Crédito
              y probablemente perderías la oportunidad de obtener algún crédito en el futuro.
            </li>

            <li>
              La Firma Electrónica Avanzada con la que firmarás los documentos para obtener tu crédito Digitt
              tiene la misma validez legal que una firma autógrafa.
            </li>
          </ul>

          <div styleName="acceptance">
            Da clic en el botón
            <span>
              {' '}
              Estoy de acuerdo
              {' '}
            </span>
            para ver y poder firmar tu Contrato, Pagaré y Autorización de Domiciliación.
          </div>
        </div>
      </section>
    );
  }
}

OnePage.propTypes = {
  className: string,
  debtAmount: number.isRequired,
  firstPaymentDate: string.isRequired,
  loanRate: number.isRequired,
  amortizationInfo: shape({}),
};

export default OnePage;
