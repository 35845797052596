import querystring from 'querystring';
import testUsers from '../../../config/testUsers';

function mapStateToProps(state, props) {
  const { digitalSignature, form, auth } = state;
  const {
    location: { search },
  } = props;
  const { userInfo: { email = '' } } = digitalSignature;

  const searchFormatted = search[0] === '?' ? search.substr(1) : search;
  const urlValues = querystring.parse(searchFormatted);
  const formValues = form.signatureForm ? (form.signatureForm.values || {}) : {};
  const urlToken = urlValues.token ? urlValues.token : null;
  const skipToTestUser = testUsers.noMati.indexOf(email) >= 0;

  return {
    token: auth.token,
    urlToken,
    digitalSignature,
    user: digitalSignature.user,
    loanRequest: digitalSignature.loanRequest,
    loan: digitalSignature.loan,
    initialValues: {
      email: urlValues.email,
      term: digitalSignature.term || null,
      firstPaymentDate: digitalSignature.firstPaymentDate || null,
      clabe: digitalSignature.clabe || null,
      bank: digitalSignature.bank || null,
      acceptContract: digitalSignature.acceptContract || null,
      acceptPromissoryNote: digitalSignature.acceptPromissoryNote || null,
      acceptDirectDebit: digitalSignature.acceptDirectDebit || null,
    },
    formValues,
    skipToTestUser,
  };
}

export default mapStateToProps;
