import axios from 'axios';
import store from '../store/configureStore';
import * as actions from '../store/actions/digitalSignature';

axios.interceptors.response.use(response => response,
  (error) => {
    // fix temporal lo del contains
    if (error.response && error.response.status === 401) {
      store.dispatch(actions.logout());
    }
    return Promise.reject(error);
  });

export default axios;
