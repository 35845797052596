import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAxios from 'axios-hooks';
import loanUtils from '@utils/loanUtils';
import googleAnalytics from '@lib/googleAnalytics';
import Mixpanel from '@lib/mixpanel';
import {
  Button,
  SignaturePad,
} from '@components';
import './style.scss';
/* globals API_URL */

function Signature(props) {
  const {
    className,
    user: userInfo,
    token,
    loanRequest,
    onNext,
    onValueChange,
  } = props;
  const [dataSignature, setDataSignature] = useState();
  // check identity calls
  const [{ loading }, checkIdentity] = useAxios(
    {
      url: `${API_URL}/check-identity-validation/${loanRequest}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }, { manual: true },
  );

  const isNewClabe = false;

  useEffect(() => {
    googleAnalytics.visitPage('set-signature');
    return () => {
    };
  }, []);

  const onSignature = (data) => {
    setDataSignature(data);
  };

  const onSubmit = async () => {
    try {
      await checkIdentity();
    } catch (error) {
      // Error submitting identity, ignore
    }

    onValueChange('signature', dataSignature);
    Mixpanel.track('app_guaranteed_set_signature_step');
    onNext();
  };

  return (
    <section id="signature-page" styleName="step-form step-sign" className={className}>
      <h2>
        Yo,
        {' '}
        {loanUtils.getLoanName(userInfo)}
        {!isNewClabe && ', acepto los documentos de contratación del crédito Digitt.'}
        {isNewClabe && ', acepto el documento de domiciliación anterior.'}
      </h2>
      <p styleName="sing-inside-square">
        Por favor, firma dentro del recuadro
      </p>
      <SignaturePad input={{ onChange: onSignature }} />
      <div styleName="btn-container">
        <Button
          id="btn-next"
          styleName="btn-next"
          type="submit"
          color="primary"
          spinner={loading}
          disabled={loading || !dataSignature}
          onClick={onSubmit}
        >
          Siguiente
        </Button>
      </div>
    </section>
  );
}

Signature.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  loanRequest: PropTypes.number,
  token: PropTypes.string,
  onNext: PropTypes.func,
  onValueChange: PropTypes.func,
};

export default Signature;
