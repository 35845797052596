export SetPassword from './FormSteps/SetPassword';
export AmortizationTable from './FormSteps/AmortizationTable';
export TermsSelection from './FormSteps/TermsSelection';
export PaymentDaySelection from './FormSteps/PaymentDaySelection';
export Bank from './FormSteps/Bank';
export Selfie from './FormSteps/Selfie';
export OnePage from './FormSteps/OnePage';
export Pagare from './FormSteps/Pagare';
export Signature from './FormSteps/Signature';
export FinalScreen from './FormSteps/FinalScreen';
export AcceptContract from './FormSteps/AcceptContract';
export AcceptDirectDebit from './FormSteps/AcceptDirectDebit';
export IDPicture from './FormSteps/IDPicture';
export AcceptContractPersonalLoan from './FormSteps/personalLoan/AcceptContract';
export OnePagePersonalLoan from './FormSteps/personalLoan/OnePage';
export BankPersonalLoan from './FormSteps/personalLoan/Bank';
export FinalScreenPersonalLoan from './FormSteps/personalLoan/FinalScreen';
