import React from 'react';
import {
  arrayOf, string, number, shape,
} from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import loanUtils from '@utils/loanUtils';
import { ErrorMessage } from '@components';
import ContractTable from './Contract';
import './style.scss';

function AcceptContract(props) {
  const {
    className,
    error: err,
    publicId,
    userInfo,
    amortizationInfo,
    firstPaymentDate,
    loanRate,
    debtAmount,
    term,
  } = props;

  const today = moment();

  return (
    <section className={className}>
      <ErrorMessage error={err} />
      <ContractTable
        publicId={publicId}
        loanRate={loanRate}
        debtAmount={debtAmount}
        term={term}
        firstPaymentDate={firstPaymentDate}
        userInfo={userInfo}
        amortizationInfo={amortizationInfo}
      />
      <div styleName="contract-details">
        <p>
          <b>
            ÍNDICE DEL CONTRATO
          </b>
        </p>

        <p styleName="index">
          <span>
            I. CONTRATO DE APERTURA DE CRÉDITO SIMPLE
          </span>
          <span>
            II. OPERACIONES A TRAVÉS DE MEDIOS ELECTRÓNICOS
          </span>
          <span>
            III. CLÁUSULAS COMUNES
          </span>
          <span>
            IV. AUTORIZACIONES
          </span>
        </p>

        <p>
          <b>
            CONTRATO DE CRÉDITO SIMPLE (“CONTRATO”) QUE CELEBRAN, POR UNA PARTE, PAGOSAFE,
            S.A.P.I. DE C.V. A QUIEN EN LO SUCESIVO SE LE DENOMINARÁ COMO LA “ENTIDAD COMERCIAL&quot;
            {' '}
            O “DIGITT” INDISTINTAMENTE, Y POR LA OTRA
            {' '}
            {loanUtils.getLoanName(userInfo).toUpperCase()}
            {' '}
            , A QUIEN EN LO SUCESIVO SE LE DENOMINARÁ COMO EL &quot;CLIENTE&quot; O “ACREDITADO”
            {' '}
            INDISTINTAMENTE, Y A QUIENES DE MANERA CONJUNTA SE LES DENOMINARÁ COMO “LAS PARTES”;
            {' '}
            DE CONFORMIDAD CON LAS SIGUIENTES ANTECEDENTES, DECLARACIONES, DEFINICIONES Y CLÁUSULAS:
          </b>
        </p>

        <h4>A N T E C E D E N T E S</h4>

        <p>
          <b>PRIMERO.</b>
          {' '}
          El CLIENTE requirió a la ENTIDAD COMERCIAL el otorgamiento de un crédito
          {' '}
          simple, en moneda nacional (en lo sucesivo el &quot;Crédito&quot;).
        </p>
        <p>
          <b>SEGUNDO.</b>
          {' '}
          La ENTIDAD COMERCIAL previa revisión y análisis de la información y documentación
          {' '}
          que fuera proporcionada y presentada por el CLIENTE por medio del sitio web
          {' '}
          propiedad de la ENTIDAD COMERCIAL, cuyo nombre de dominio es www.digitt.com,
          {' '}
          (en adelante el &quot;Sitio&quot;), autorizó la Solicitud a favor del CLIENTE.
        </p>
        <p>
          <b>TERCERA.</b>
          {' '}
          El cuadro informativo contenido en la Carátula precisa, cierta información
          {' '}
          relativa al CLIENTE (la &quot;Información del Crédito&quot;), aplicable al Contrato.
        </p>

        <h4>D E C L A R A C I O N E S</h4>

        <p>
          <b>I. DECLARA LA ENTIDAD COMERCIAL A TRAVÉS DE SU REPRESENTANTE LEGAL:</b>
        </p>
        <p>
          a) Que es una sociedad mercantil constituida de conformidad con las normas mexicanas
          {' '}
          como se establece en la escritura pública número 6,004 de fecha 18 de febrero de 2016
          {' '}
          pasada ante la fe del Notario Público No. 2 del municipio del Salto, Jalisco,
          {' '}
          la cual se encuentra debidamente inscrita en el Registro Público de Comercio.
        </p>
        <p>
          b) Que su representante legal se encuentra debidamente autorizado como se establece
          {' '}
          en la escritura pública número 6,004 de fecha 18 de febrero de 2016 pasada
          {' '}
          ante la fe del Notario Público No. 2 del municipio del Salto, Jalisco.
        </p>
        <p>
          c) Que su Registro Federal de Contribuyente es: PAG1602197V7
        </p>
        <p>
          d) Que su domicilio se encuentra ubicado en: José Guadalupe Zuno No.
          {' '}
          1745, Col. Americana, C.P. 44160, Guadalajara, Jalisco.
        </p>
        <p>
          e) Que es su intención otorgar el Crédito al CLIENTE,
          {' '}
          conforme a los términos y condiciones del presente Contrato.
        </p>

        <p>
          <b>II. DECLARA EL CLIENTE:</b>
        </p>
        <p>
          a) Que es una persona física con plena capacidad para obligarse en términos del Contrato.
        </p>
        <p>
          b) Previo a la firma del presente contrato, presentó la Solicitud de Apertura de
          {' '}
          Crédito correspondiente que forma parte de este Contrato, en donde constan sus datos
          {' '}
          generales, domicilio, actividad y situación económica, referencias personales,
          {' '}
          bancarias, crédito y que la información proporcionada por él es verdadera y que
          {' '}
          los documentos con los que se acreditó son legítimos y genuinos.
        </p>
        <p>
          c) Que previo a la celebración del Contrato La ENTIDAD COMERCIAL le ha proporcionado
          {' '}
          toda la información referente al Crédito incluyendo el costo anual total de
          {' '}
          financiamiento expresado en términos porcentuales anuales que, para fines informativos y
          {' '}
          de comparación el cual incorpora la totalidad de los costos y gastos
          {' '}
          inherentes del Crédito (en adelante “CAT”).
        </p>
        <p>
          d) Que detenta los recursos económicos para el cumplimiento de las obligaciones
          {' '}
          que, en términos del Contrato, contrae a su cargo,
          {' '}
          mismos que provienen y provendrán de fuentes lícitas.
        </p>
        <p>
          e) Que la información y documentación que proporcionó y presentó a la
          {' '}
          ENTIDAD COMERCIAL, así como la información que asentó en la Carátula,
          {' '}
          es verdadera y correcta.
        </p>

        <p>
          <b>III. DECLARAN LAS PARTES</b>
        </p>
        <p>
          a) Que no existen vicios en el consentimiento para la celebración del presente contrato.
          {' '}
          Por lo cual, con fundamento en las anteriores declaraciones y tras reconocerse mutuamente
          {' '}
          la personalidad con la cual se ostentan, las PARTES convienen en celebrar el presente
          {' '}
          Contrato, al tenor de las siguientes:
        </p>

        <h3>D E F I N I C I O N E S</h3>

        <p>
          <b>a. “CAT”:</b>
          {' '}
          Significa el costo anual total de financiamiento expresado en términos porcentuales anuales
          {' '}
          que, para fines informativos y de comparación, incorpora la totalidad de los costos y
          {' '}
          gastos inherentes al crédito.
        </p>
        <p>
          <b>b. “Carátula”:</b>
          {' '}
          Significa el documento integrante del Contrato, en el que se establecen, la Información
          {' '}
          del Crédito y del CLIENTE, así como la información que determina el Contrato y que le es aplicable.
        </p>
        <p>
          <b>c. “Estado de Cuenta”:</b>
          {' '}
          Documento que se enviará al cliente en el cual se transcribirán las operaciones
          {' '}
          realizadas durante la vigencia del crédito.
        </p>
        <p>
          <b>d. “Día Hábil”:</b>
          {' '}
          Significa cualquier día de la semana, excluyendo los días sábado y domingo, así como los días en
          {' '}
          que, conforme a las disposiciones aplicables, las instituciones de crédito deban cerrar sus puertas,
          {' '}
          suspender operaciones y la prestación de servicios al público.
        </p>
        <p>
          <b>e. “Firma Electrónica Avanzada”:</b>
          {' '}
          Conjunto de datos que se adjuntan a un mensaje electrónico, cuyo propósito es identificar
          {' '}
          al emisor del mensaje como autor legítimo de éste; como si se tratara de una firma autógrafa.
        </p>
        <p>
          <b>f. “Medios Electrónicos”:</b>
          {' '}
          Los dispositivos tecnológicos que serán utilizados para el procesamiento,
          {' '}
          despliegue, conservación y, en su caso, modificación de información.
        </p>
        <p>
          <b>g. “Mensaje de Datos”:</b>
          {' '}
          Toda aquella información generada, enviada, recibida, archivada o comunicada
          {' '}
          a través de Medios Electrónicos.
        </p>
        <p>
          <b>h. “Parcialidad”:</b>
          {' '}
          Importes que el CLIENTE deberá pagar a la ENTIDAD COMERCIAL durante la vigencia de este
          {' '}
          Contrato, para el efecto que sea pagado, en su totalidad, el Monto Total a Pagar.
          {' '}
          El número de pagos, periodicidad de pago e importe de las Parcialidades, se indican en la Carátula.
        </p>

        <h3>C L Á U S U L A S</h3>

        <div styleName="clauses">
          <p>
            I. CONTRATO DE APERTURA DE CRÉDITO SIMPLE
          </p>
          <p>
            <b styleName="underline">PRIMERA. OBJETO.</b>
            {' '}
            Sujeto al perfeccionamiento de la obligación condicional que se indica en la presente Cláusula,
            {' '}
            la ENTIDAD COMERCIAL otorgará al CLIENTE un crédito simple con interés por el importe en capital
            {' '}
            indicado en el apartado de Monto del Crédito de la Carátula, misma que el CLIENTE se obliga a
            {' '}
            pagar en los términos y condiciones que se establecen en este Contrato.
          </p>
          <p>
            <b styleName="underline">
              SEGUNDA. DESTINO DEL CRÉDITO.
            </b>
            {' '}
            Las PARTES establecen que el Crédito será destinado por el CLIENTE para cubrir la deuda que
            {' '}
            tiene con cualquier institución financiera o no financiera por el otorgamiento de un crédito
            {' '}
            el cual fue dispuesto a través de una tarjeta de crédito. El CLIENTE expone que el
            {' '}
            destino del Monto del Crédito es y será lícito.
          </p>
          <p>
            Una vez autorizado el otorgamiento del Crédito por la ENTIDAD COMERCIAL, este último
            realiza directamente el pago de la deuda actual a la tarjeta de crédito del CLIENTE a
            {' '}
            través de transferencia electrónica de fondos por el Sistema de Pagos
            {' '}
            Electrónicos Interbancarios (SPEI).
          </p>
          <p>
            <b styleName="underline">
              TERCERA. OBLIGACIÓN Y FORMAS DEL PAGO.
            </b>
            {' '}
            El CLIENTE se obliga a pagar a la ENTIDAD COMERCIAL el saldo total del Crédito,
            {' '}
            así como los intereses ordinarios y moratorios que se generen.
          </p>
          <p>
            El CLIENTE se obliga a pagar las cantidades estipuladas en la Carátula (Pagos),
            {' '}
            los cuales serán cubiertos en forma mensual según corresponda, de conformidad
            {' '}
            con el número y periodicidad indicada en la Carátula.
          </p>
          <p>
            El pago del Crédito se realizará a través de la domiciliación del pago a su cuenta de
            {' '}
            cheques o tarjeta de débito. En caso de que el pago por domiciliación no se lograra realizar
            {' '}
            por falta de fondos, el CLIENTE deberá realizar el pago a través de
            {' '}
            trasferencia electrónica de fondos a la cuenta bancaria a nombre de PAGOSAFE S.A.P.I. DE C.V.
            {' '}
            especificada dentro del perfil individual del usuario en app.digitt.com.
          </p>
          <p>
            En todo momento, el CLIENTE responderá por el importe íntegro de los Pagos.
          </p>
          <p>
            Los Pagos serán aplicados a: en su caso;(i) intereses moratorios, (ii) intereses
            {' '}
            ordinarios del periodo y; (iii) capital.
          </p>
          <p>
            Todos y cada uno de los Pagos que el CLIENTE deba realizar a favor de la ENTIDAD COMERCIAL,
            {' '}
            se consideran efectuados cuando los mismos no estén sujetos a condición o proceso alguno,
            {' '}
            en caso contrario, la obligación de pago a cargo del CLIENTE subsistirá mientras la cantidad
            {' '}
            pendiente de pago no haya sido liquidada a la ENTIDAD COMERCIAL en su totalidad. Cuando la
            {' '}
            fecha de pago del crédito corresponda a un día inhábil, el pago se podrá realizar el
            {' '}
            día hábil inmediato siguiente sin cargo alguno al CLIENTE.
          </p>
          <p>
            <b styleName="underline">
              CUARTA. INTERESES Y FECHA DE CORTE.
            </b>
            {' '}
            Las Partes convienen que el CLIENTE se obliga a pagar a la ENTIDAD COMERCIAL, sin necesidad de
            {' '}
            requerimiento previo, los intereses ordinarios del Crédito, que serán calculados sobre el saldo
            {' '}
            pendiente de amortización. Los intereses se devengarán a una tasa anual equivalente al
            {' '}
            {numeral(loanRate / 100).format('0,0[.]00%')}
            .
          </p>
          <p>
            Los intereses ordinarios se calcularán sobre el saldo insoluto del Crédito, comenzando
            {' '}
            a generarse a partir de la fecha de celebración del presente Contrato, debiendo ser
            {' '}
            cubiertos, por periodos vencidos, junto con el pago de la Amortización correspondiente
            {' '}
            al periodo que se termina.
          </p>
          <p>
            Los intereses ordinarios se calcularán sobre el saldo insoluto del Crédito, comenzando
            {' '}
            a generarse a partir de la fecha de celebración del presente Contrato, debiendo ser
            {' '}
            cubiertos, por periodos vencidos, junto con el pago de la Amortización correspondiente
            {' '}
            al periodo que se termina.
          </p>
          <p>
            Los intereses ordinarios se calcularán dividiendo la tasa de interés ordinaria pactada
            {' '}
            entre 360 (trescientos sesenta) días, multiplicándola por el número de días del periodo
            {' '}
            y multiplicando la cantidad resultante por el saldo insoluto.
          </p>
          <p>
            En caso de que el CLIENTE no cubriere puntualmente a la ENTIDAD COMERCIAL cualquier
            {' '}
            suma que estuviere obligado a pagar conforme al presente contrato, la cantidad no
            {' '}
            pagada causará intereses moratorios a partir de la fecha en que las cantidades
            {' '}
            adeudadas debieron ser cubiertas y hasta la fecha de su pago total.
          </p>
          <p>
            Los intereses moratorios se calcularán dividiendo la tasa de interés moratoria pactada
            {' '}
            entre 360 (trescientos sesenta) días, multiplicándola por el número de días transcurridos
            {' '}
            desde el momento en que debió realizarse el pago y hasta la fecha de pago efectiva y
            {' '}
            multiplicando la cantidad resultante por el capital adeudado, vencido y no pagado.
          </p>
          <p>
            <b styleName="underline">
              QUINTA. COMISIONES Y GASTOS.
            </b>
            {' '}
            La ENTIDAD COMERCIAL no cobrará las comisiones que se mencionan a continuación:
            <br />
            Comisión por apertura de crédito
            <br />
            Comisión por pagos anticipados.
            <br />
            Gastos por cobranza.
          </p>
          <p>
            <b styleName="underline">
              SEXTA. PAGOS ANTICIPADOS
            </b>
            {' '}
            El CLIENTE podrá realizar pagos anticipados conforme a lo que se establece a continuación:
          </p>
          <p>
            El Usuario podrá realizar pagos anticipados cuando se encuentre al corriente y en cumplimiento
            {' '}
            con las obligaciones de pago que establece el Contrato y se aplicarán de la forma siguiente:
            {' '}
            a) al capital del crédito, b) causará la reducción del plazo del Crédito, en virtud de que se
            {' '}
            recorrerán la o las Fechas de Pago de la Parcialidad o Parcialidades que no sean objeto del
            {' '}
            mismo, para el efecto de que dicha o dichas Parcialidades sean pagadas por el CLIENTE
            {' '}
            en forma consecutiva e ininterrumpida.
          </p>
          <p>
            Para realizar los pagos anticipados EL CLIENTE podrá llevarlos a cabo a través de
            {' '}
            trasferencia electrónica de fondos a la cuenta bancaria que se establece en
            {' '}
            la Cláusula Tercera del presente, para que aplique dicho pago como anticipado.
          </p>
          <p>
            En caso de que el CLIENTE no realice el pago a la cuenta correspondiente que se indica en app.digitt.com
            {' '}
            su pago se entenderá como adelanto de su próxima o próximas parcialidades a pagar y
            {' '}
            dichos pagos no serán aplicables al capital insoluto del crédito.
          </p>
          <p>
            <b styleName="underline">
              SÉPTIMA. ACREDITACIÓN DE PAGOS:
            </b>
            {' '}
            La fecha en que se acreditará el pago realizado por el CLIENTE dependerá del medio utilizado
            {' '}
            para el pago de la misma ENTIDAD COMERCIAL.
            <br />
            Trasferencia electrónica de fondos: Si se hace a través del Sistema de Pagos Electrónicos
            {' '}
            Interbancarios se acredita el mismo día.
            <br />
            Domiciliación del pago: El día siguiente.
          </p>
          <p>
            <b styleName="underline">
              OCTAVA. IMPUESTOS.
            </b>
            El CLIENTE reconoce que la ENTIDAD COMERCIAL podrá estar obligado por las leyes mexicanas a retener
            {' '}
            impuestos a cargo del CLIENTE, por lo que en este acto acepta y autoriza dichas retenciones.
          </p>
          <p>
            <b styleName="underline">
              NOVENA. ESTADO DE CUENTA.
            </b>
            La ENTIDAD COMERCIAL a través del correo electrónico pondrá a disposición del CLIENTE,
            {' '}
            mensualmente y de manera gratuita el Estado de Cuenta en el que se detallan los movimientos
            {' '}
            del Crédito, los pagos efectuados y demás accesorios, que se deriven del Crédito (el Estado de Cuenta).
          </p>
          <p>
            En caso de que, por cualquier motivo, el CLIENTE no reciba el Estado de Cuenta, no lo libera del
            {' '}
            cumplimiento de sus obligaciones de pago adquiridas por virtud del presente Contrato.
          </p>
          <p>
            El CLIENTE podrá consultar sin costo alguno su estado de cuenta enviando un correo electrónico a
            {' '}
            hola@digitt.com y éste le será enviado al correo electrónico registrado del CLIENTE a más
            {' '}
            tardar un (1) Día Hábil posterior a la recepción de la solicitud del estado de cuenta.
            {' '}
            En caso de que el CLIENTE no cuente con correo electrónico o éste no esté actualizado, se verificará
            {' '}
            la identidad del CLIENTE con preguntas personales de seguridad, para así proteger en todo momento
            {' '}
            su información.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA. PLAZO.
            </b>
            El plazo para liquidación del Crédito quedará establecido por las Partes en la Carátula.
          </p>
          <p>
            En caso de que el Crédito sea pagado en los términos del Contrato, se dará por terminado
            {' '}
            el mismo y, por lo tanto, la ENTIDAD COMERCIAL procederá a la entrega a favor del CLIENTE
            {' '}
            del Pagaré a través de medios electrónicos, dentro de los treinta (30) días hábiles siguientes
            {' '}
            a la terminación del crédito. Dicho pagaré que se entrega al CLIENTE al ser pagado el Crédito
            {' '}
            quedará sin validez y dejará sin efectos la obligación de pago que tenía el CLIENTE con la
            {' '}
            ENTIDAD COMERCIAL en virtud del citado pagaré.
          </p>
          <p>
            El acuse de recibo que, por su entrega, emita el CLIENTE, se refutará como la recepción,
            {' '}
            por el CLIENTE y, a su entera satisfacción, del Pagaré. Así mismo, la ENTIDAD COMERCIAL
            {' '}
            emitirá, dentro de los treinta (30) Días Hábiles siguientes al Día Hábil en que se verifique
            {' '}
            el pago del Crédito, un Estado de Cuenta que establezca: (i) la finalización de la relación
            {' '}
            contractual; (ii) la cancelación de los derechos y obligaciones derivados del Contrato;
            {' '}
            y, (iii) la inexistencia de adeudos entre las PARTES. La ENTIDAD COMERCIAL considera treinta
            {' '}
            (30) Días Hábiles para poder realizar las verificaciones pertinentes y no cometer
            {' '}
            ningún error u omisión.
          </p>
          <p>
            <b>
              II. OPERACIONES A TRAVÉS DE MEDIOS ELECTRÓNICOS
            </b>
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA PRIMERA. DE LOS SERVICIOS A TRAVÉS DE MEDIOS ELECTRÓNICOS Y SUS EFECTOS.
            </b>
            Las Partes convienen en que las instrucciones que el CLIENTE gire a la ENTIDAD COMERCIAL para
            {' '}
            celebrar operaciones, ejercer derechos, cumplir obligaciones, así como para concertar operaciones
            {' '}
            con la ENTIDAD COMERCIAL y cualquier otro comunicado de la ENTIDAD COMERCIAL para el CLIENTE, y
            {' '}
            de éste para aquél, salvo que en el presente Contrato se establezca una forma especial, podrán
            {' '}
            hacerse por escrito y/o a través de Medios Electrónicos dependiendo de la disponibilidad de estos.
          </p>
          <p>
            Cuando LA ENTIDAD COMERCIAL se encuentre en posibilidad de incorporar nuevos Medios Electrónicos
            {' '}
            para la prestación de servicios financieros, así lo comunicará al CLIENTE junto con las bases
            {' '}
            para determinar las operaciones y servicios que podrán contratarse a través del equipo o sistema
            {' '}
            de que se trate, los medios de identificación del CLIENTE y las responsabilidades correspondientes
            {' '}
            a su uso, conviniendo expresamente las Partes desde ahora que su utilización por parte del CLIENTE
            {' '}
            implica la aceptación del equipo o sistema y de todos los efectos jurídicos de éste derivados.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA SEGUNDA. OPERACIONES A TRAVÉS DE MEDIOS ELECTRÓNICOS.
            </b>
            Sujeto a lo que establecen las leyes aplicables, mediante el uso de los Medios Electrónicos
            {' '}
            pactados entre las Partes, al CLIENTE podrá, entre otras operaciones, realizar consultas de
            {' '}
            saldos, efectuar disposiciones de crédito, concertar operaciones, ejercer derechos, cumplir
            {' '}
            obligaciones, dar avisos, solicitar aclaraciones, hacer requerimientos y girar cualquier otra
            {' '}
            instrucción que el propio equipo y sistema permita en atención a su naturaleza, bajo los
            {' '}
            conceptos de marca y servicio que la ENTIDAD COMERCIAL llegue a poner a disposición del CLIENTE.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA TERCERA. ACTOS POR MEDIOS ELECTRÓNICOS.
            </b>
            Las Partes reconocen que en términos del artículo 75 fracción XIV del Código de Comercio en
            {' '}
            vigor, los actos relacionados con los Medios Electrónicos aceptados son de naturaleza
            {' '}
            mercantil tanto para el CLIENTE como para LA ENTIDAD COMERCIAL. De acuerdo con lo anterior,
            {' '}
            el CLIENTE y LA ENTIDAD COMERCIAL convienen que:
          </p>
          <p>
            Para efectos de lo previsto en el artículo 89 del Código de Comercio en vigor, se entenderá
            {' '}
            como Mensaje de Datos a toda información generada, enviada, recibida, archivada o
            {' '}
            comunicada a través de Medios Electrónicos.
          </p>
          <p>
            Para efectos de lo dispuesto en la fracción I del artículo 90 del Código de Comercio en vigor,
            {' '}
            se entenderá que un “Mensaje de Datos” ha sido enviado por el propio ACREDITADO, cuando éste
            {' '}
            realice operaciones a través del equipo o sistema de que se trate, utilizando la Contraseña,
            {' '}
            en relación con lo previsto en el artículo 89 del citado ordenamiento legal.
          </p>
          <p>
            Para efectos de lo dispuesto en las fracciones I y II del artículo 91 del Código de Comercio
            {' '}
            en vigor y según sea el caso, se entenderá que LA ENTIDAD COMERCIAL recibe un Mensaje de Datos
            {' '}
            enviado por el CLIENTE, cuando éste haga uso del equipo o ingrese a la aplicación móvil de
            {' '}
            LA ENTIDAD COMERCIAL, y que la información proporcionada a través de ese servicio se recibe por
            {' '}
            el CLIENTE en el momento que obtenga dicha información, en relación con lo previsto en el
            {' '}
            artículo 89 del citado ordenamiento legal.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA CUARTA. MODIFICACIÓN DE LOS SERVICIOS A TRAVÉS DE MEDIOS ELECTRÓNICOS.
            </b>
            La ENTIDAD COMERCIAL podrá ampliar, disminuir o modificar en cualquier tiempo, en todo o
            {' '}
            en parte, temporal o permanentemente, sin necesidad de notificación previa al ACREDITADO,
            {' '}
            las condiciones, características y alcances de los Medios Electrónicos que pone a disposición
            {' '}
            del CLIENTE, así como restringir el uso y acceso a los mismos, limitando inclusive su duración
            {' '}
            o cantidad de uso. De igual forma y a su propio juicio, la ENTIDAD COMERCIAL podrá suspender
            {' '}
            temporal o permanentemente los derechos del CLIENTE para utilizar los Medios Electrónicos
            {' '}
            cuando cuente con elementos que le hagan presumir que estos no están siendo utilizada por el
            {' '}
            propio ACREDITADO, o bien, por considerar que su uso viola los términos de este documento o que
            {' '}
            su uso puede dañar los intereses de otros acreditados o proveedores, a la ENTIDAD COMERCIAL.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA QUINTA. LIMITANTES PARA LA PRESTACIÓN DE SERVICIOS A TRAVÉS DE MEDIOS ELECTRÓNICOS.
            </b>
            Las operaciones y servicios solicitados a través de Medios Electrónicos se sujetarán a lo siguiente:
          </p>
          <p>
            Los servicios utilizados a través de los Medios Electrónicos, generarán un número de referencia o
            {' '}
            folio por la realización de cada operación o servicio, el cual acreditará la existencia, validez
            {' '}
            y efectividad del uso de los servicios comerciales que conforme a las disposiciones vigentes afecten
            {' '}
            o deban afectar los registros contables de la ENTIDAD COMERCIAL, siendo tal número de referencia o
            {' '}
            folio el comprobante material de la operación de que se trate, con todos los efectos que las
            {' '}
            leyes atribuyen a los mismos.
            {' '}
            La prestación de servicios a través de Medios Electrónicos invariablemente estará sujeta a la
            {' '}
            aprobación de un crédito, en ningún caso la ENTIDAD COMERCIAL estará obligado a cumplir las
            {' '}
            instrucciones del CLIENTE si no cumple con los parámetros crediticios de la ENTIDAD COMERCIAL.
            {' '}
            Igualmente, la ENTIDAD COMERCIAL deberá excusarse, sin su responsabilidad a dar cumplimiento a
            {' '}
            las instrucciones del CLIENTE que contravengan lo establecido en las leyes y disposiciones de
            {' '}
            carácter general expedidas por las autoridades competentes.
            {' '}
            Las operaciones instruidas, propaladas o realizadas a través de Medios Electrónicos y los actos
            {' '}
            y transacciones que en cumplimiento de tales operaciones, servicios y/o instrucciones que
            {' '}
            la ENTIDAD COMERCIAL llegue a ejecutar, serán consideradas para todos los efectos legales a
            {' '}
            que haya lugar como realizadas por el CLIENTE, quien las acepta y reconoce desde ahora
            {' '}
            como suyas siempre que existan elementos que indiquen el uso de su Contraseña y la existencia
            {' '}
            del número de referencia o folio que corresponda, y por tanto, serán obligatorias y vinculantes
            {' '}
            para el propio CLIENTE y encuadradas en los términos y condiciones de los modelos de solicitudes
            {' '}
            y/o contratos que la ENTIDAD COMERCIAL habitualmente utiliza para instrumentar tales actos,
            {' '}
            quien las acepta y reconoce como suyas siempre.
            {' '}
            Expresamente reconoce el CLIENTE que los registros de las operaciones a que se refiere el
            {' '}
            presente Contrato que aparezcan en los sistemas de la ENTIDAD COMERCIAL y en los comprobantes
            {' '}
            que de las mismas expidan, tendrán pleno valor probatorio y fuerza legal como constancia de que
            {' '}
            operó a través del equipo o sistema electrónico que hubiere emitido el comprobante de que se trate.
            {' '}
            En los Estados de Cuenta que se hagan llegar al ACREDITADO, se harán constar e identificarán
            {' '}
            las operaciones realizadas mediante Medios Electrónicos. Las observaciones a esos estados de cuenta
            {' '}
            las formulará el CLIENTE en la forma y términos que en dicha Cláusula se señalan.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA SEXTA. EXCLUSIONES PARA LA PRESTACIÓN DE SERVICIOS A TRAVÉS DE MEDIOS ELECTRÓNICOS.
            </b>
            El CLIENTE y la ENTIDAD COMERCIAL convienen que éste último no estará obligado a prestar
            {' '}
            servicios a través de Medios Electrónicos en los siguientes casos:
          </p>
          <p>
            Cuando los equipos de cómputo o el acceso a Internet del CLIENTE no se encuentren actualizados,
            {' '}
            no sean compatibles o presenten cualquier falla inclusive de comunicación), restricción de uso
            {' '}
            o limitaciones de cualquier naturaleza que imposibiliten tener acceso a los equipos y sistemas
            {' '}
            automatizados que LA ENTIDAD COMERCIAL ponga a su disposición.
            {' '}
            Cuando la información transmitida sea insuficiente, inexacta, errónea o incompleta.
            {' '}
            Debido a la necesidad de realizar tareas de reparación y/o mantenimiento de todo o parte
            {' '}
            de los elementos que integran los sistemas de LA ENTIDAD COMERCIAL, que no pudieran evitarse,
            {' '}
            siempre buscando realizarlos en los horarios menos utilizados, cuando así sea posible.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA SÉPTIMA. LIMITACIÓN DE RESPONSABILIDAD PARA LA PRESTACIÓN DE SERVICIOS A TRAVÉS
              {' '}
              DE MEDIOS ELECTRÓNICOS.
            </b>
            Para lograr la conexión a los servicios de Internet, el CLIENTE deberá contar con equipo
            {' '}
            de cómputo y/o un dispositivo móvil con acceso a Internet, mismos que deberá mantener
            {' '}
            actualizados de modo que conserven compatibilidad con los equipos y sistemas de LA ENTIDAD
            {' '}
            COMERCIAL El ACREDITADO, en este acto, acepta que él es el único responsable del uso que le
            {' '}
            da al equipo y/o sistemas electrónicos que usa para celebrar operaciones, ejercer derechos y/o
            {' '}
            cumplir obligaciones con LA ENTIDAD COMERCIAL o cualquier otro acto a los que se refiere el
            {' '}
            presente instrumento, razón por la cual, el ACREDITADO, en este acto, libera a LA ENTIDAD
            {' '}
            COMERCIAL de cualquier responsabilidad que pudiera derivarse, de manera enunciativa más no
            {' '}
            limitativa, por el mal uso que le da o llegue a dar a dicho equipo y/o sistema, así como por
            {' '}
            usar páginas de internet no seguras, por permitir que terceras personas, voluntaria o
            {' '}
            involuntariamente, accedan a su computadora u otro dispositivo donde almacena o llegue a
            {' '}
            almacenar su Contraseña.
          </p>
          <p>
            Asimismo, el CLIENTE se obliga a evitar abrir y/o contestar correos electrónicos de terceros
            {' '}
            que desconozca, así como utilizar programas o sistemas de cómputo legales y a estar enterado
            {' '}
            de las actualizaciones o parches que dichos programas requieren para su uso seguro y acepta que
            {' '}
            la navegación o vista de sitios electrónicos, es bajo su más exclusiva responsabilidad.
          </p>
          <p>
            Será bajo la más exclusiva responsabilidad del ACREDITADO, visitar sitios no seguros que pudieran
            {' '}
            insertar spyware o algún otro sistema para extraer información confidencial del ACREDITADO, así
            {' '}
            como bajar cualquier contenido de tales sitios y/o descargar sistemas o programas de cómputo que
            {' '}
            permitan compartir archivos (peer to peer) que pudieran vulnerar la privacidad de su información y
            {' '}
            que el equipo y/o sistemas electrónicos que utiliza cuenten con la seguridad.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA OCTAVA. FIRMA ELECTRÓNICA AVANZADA.
            </b>
            Para todo lo conveniente con el presente Contrato la Firma Electrónica Avanzada podrá usarse a
            {' '}
            través de la Plataforma, produciendo los mismos efectos que los presentados con firma autógrafa,
            {' '}
            y, en consecuencia, tendrán el mismo valor probatorio que las disposiciones aplicables les otorgan a esta.
          </p>
          <p>
            <b styleName="underline">
              DÉCIMA NOVENA. DOCUMENTOS RELACIONADOS.
            </b>
            El Pagaré, la Solicitud y cualquier otro documento relacionado podrán celebrarse y firmarse a
            {' '}
            través de Medios Electrónicos con el uso de la Firma Electrónica Avanzada, mismos que se agregarán
            {' '}
            y adicionarán al presente Contrato de forma consecutiva, creando así la totalidad de los derechos
            {' '}
            y obligaciones a los que en su caso.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA. RESPONSABILIDAD EL USO DE LA INFORMACIÓN.
            </b>
            {' '}
            Sujeto a lo que establecen las leyes aplicables, mediante el uso de los Medios Electrónicos
            {' '}
            pactados entre las Partes, el CLIENTE podrá, entre otras operaciones, realizar consultas de
            {' '}
            saldos, efectuar disposiciones de crédito, concertar operaciones, ejercer derechos, cumplir
            {' '}
            obligaciones, dar avisos, solicitar aclaraciones, hacer requerimientos y girar cualquier otra
            {' '}
            instrucción que el propio equipo y sistema permita en atención a su naturaleza, bajo los
            {' '}
            conceptos de marca y servicio que LA ENTIDAD COMERCIAL llegue a poner a disposición del CLIENTE.
          </p>
          <p>
            Al firmar este Contrato y utilizar los Medios Electrónicos, el CLIENTE se obliga a hacer uso
            {' '}
            de los servicios en forma personal e intransferible, conforme a los términos, condiciones y
            {' '}
            requisitos establecidos en el presente Contrato, dentro de los horarios que la propia LA
            {' '}
            ENTIDAD COMERCIAL tenga establecidos. El CLIENTE acepta y reconoce expresamente que LA ENTIDAD
            {' '}
            COMERCIAL es la propietaria y/o titular de los derechos, según corresponda, de los medios de
            {' '}
            acceso y los programas que le permitan hacer uso de los servicios proporcionados por LA ENTIDAD
            {' '}
            COMERCIAL, por lo que el CLIENTE solo podrá transferir, divulgar o dar uso distinto total o
            {' '}
            parcial a dichos medios de acceso y programas, previa autorización por escrito y/o a través
            {' '}
            de los Medios Electrónicos pactados entre las Partes. En caso contrario, el CLIENTE será responsable
            {' '}
            de los daños y perjuicios que LA ENTIDAD COMERCIAL o terceros sufran, lo anterior con independencia
            {' '}
            de las acciones judiciales, administrativas o de cualquier índole que le asistan a LA ENTIDAD COMERCIAL.
            {' '}
            El CLIENTE acepta que es su responsabilidad el uso de la información que se genere a través de
            {' '}
            esta modalidad de comunicación.
          </p>
          <p>
            <b styleName="underline">
              III. CLÁUSULAS COMUNES
            </b>
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA PRIMERA. TERMINACIÓN ANTICIPADA. El CLIENTE.
            </b>
            {' '}
            El CLIENTE podrá realizar, en cualquier tiempo, la terminación anticipada del
            {' '}
            Contrato, para tal efecto, deberá pagar a DIGITT, en los términos establecidos en el Contrato:
            {' '}
            a) el saldo insoluto por principal, así como por accesorios del Crédito; y, b) cualquier
            {' '}
            otro importe que el CLIENTE adeude a ENTIDAD COMERCIAL conforme al Contrato.
            <br />
            Dicho pago deberá realizarse por transferencia electrónica de fondos a la CLABE
            {' '}
            interbancaria especificada para este propósito dentro del perfil individual del usuario en app.digitt.com.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA SEGUNDA. PERIODO DE GRACIA PARA CANCELACIÓN DE CRÉDITO.
            </b>
            {' '}
            El CLIENTE contará con un periodo de gracia de diez (10) días hábiles posteriores a la firma
            {' '}
            del contrato para cancelarlo, sin responsabilidad para él, siempre y cuando no haya hecho
            {' '}
            uso o disposición del crédito, producto o servicio materia del presente contrato. En caso de
            {' '}
            que el CLIENTE cumpla con dicho requisito podrá cancelar sin cobro de comisión alguna.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA TERCERA. CESE DE LA RESPONSABILIDAD DEL CLIENTE.
            </b>
            {' '}
            CESE DE LA RESPONSABILIDAD DEL CLIENTE. En caso de defunción del CLIENTE, a efecto de
            {' '}
            que el saldo del Crédito quede cancelado, la ENTIDAD COMERCIAL deberá ser notificada
            {' '}
            de este hecho por algún familiar mediante la exhibición del acta de defunción original
            {' '}
            correspondiente, la cual podrá exhibirse a través de medios electrónicos a través del envío
            {' '}
            de fotografía o escaneo de la misma, a la dirección de correo electrónico: hola@digitt.com.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA CUARTA. GARANTÍA.
            </b>
            {' '}
            El CLIENTE entregará un pagaré librado a favor de la ENTIDAD COMERCIAL y el cual se
            {' '}
            agregará como Anexo del Contrato. Dicho Pagaré devengará intereses a una tasa igual
            {' '}
            a la del Crédito. Durante el plazo de vigencia del Crédito, el CLIENTE se obliga a que
            {' '}
            el Pagaré sea válido, vinculante y ejecutable, confiriéndole a la ENTIDAD COMERCIAL el
            {' '}
            derecho a exigir el pago del mismo sin necesidad de recurrir a un proceso judicial.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA QUINTA. DOCUMENTOS VINCULADOS AL PRÉSTAMO.
            </b>
            {' '}
            El presente Contrato incluyendo su Carátula, el Pagaré y la documentación presentada
            {' '}
            a la ENTIDAD COMERCIAL para el análisis de crédito se agregan al presente Contrato
            {' '}
            formando parte integrante del mismo y constituyen el único acuerdo entre las Partes.
            {' '}
            A la firma del presente Contrato se firma en duplicado, quedando un ejemplar del Contrato
            {' '}
            y de los demás Documentos Vinculados en poder de cada una de las partes.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA SEXTA. NOTIFICACIONES.
            </b>
            {' '}
            Cualquier aviso, notificación o requerimiento que las partes deban hacerse,
            {' '}
            de conformidad con el presente Contrato, será efectuado a través del correo
            {' '}
            electrónico establecido por el CLIENTE en la Solicitud.
          </p>
          <p>
            En caso de que exista alguna actualización en los datos y/o en el domicilio
            {' '}
            proporcionado por el CLIENTE, deberá notificarlo a la ENTIDAD COMERCIAL por escrito,
            {' '}
            con por lo menos 10 (diez) días naturales de anticipación a la fecha en que
            {' '}
            surta efectos el cambio.
          </p>
          <p>
            Mientras la ENTIDAD COMERCIAL no reciba el aviso referido con antelación, todas
            {' '}
            las comunicaciones, avisos, notificaciones o requerimientos enviados al correo
            {' '}
            señalado por el CLIENTE, se entenderán como válidas y surtirán plenos efectos legales.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA SÉPTIMA. CESIÓN.
            </b>
            {' '}
            El CLIENTE no podrá ceder o de cualquier otra manera negociar los derechos y
            {' '}
            obligaciones a su cargo, derivadas del presente Contrato.
          </p>
          <p>
            De conformidad con el artículo 299 de la Ley General de Títulos y Operaciones
            {' '}
            de Crédito, el CLIENTE en este acto, expresamente autoriza a ENTIDAD COMERCIAL
            {' '}
            para que, en cualquier momento descuente, ceda o de cualquier otra manera negocie,
            {' '}
            ya sea de manera total o parcial, los derechos de Crédito documentados en el presente
            {' '}
            Contrato.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA OCTAVA. MANIFESTACIÓN DEL CLIENTE.
            </b>
            {' '}
            El CLIENTE  declara, para todos los efectos legales a los que haya lugar, previo
            {' '}
            a la firma del presente Contrato, la ENTIDAD COMERCIAL le explicó y le dio a conocer a
            {' '}
            su entera satisfacción: (i) el contenido del mismo y de todos los documentos a suscribir;
            {' '}
            (ii) el monto de las tasas de interés vigentes que cobra previstos en el presente Contrato;
            {' '}
            (iv) le fue dado a conocer el cálculo del CAT de financiamiento expresado en términos
            {' '}
            porcentuales anuales que, para fines informativos y de comparación, incorpora la totalidad
            {' '}
            de los costos y gastos inherentes a los Créditos correspondiente al Crédito.
          </p>
          <p>
            <b styleName="underline">
              VIGÉSIMA NOVENA. DE LAS PARTES.
            </b>
            {' '}
            Para efectos de notificaciones y el cumplimento de sus obligaciones conforme al
            {' '}
            presente contrato, las partes del mismo señalan como sus domicilios los siguientes.
          </p>
          <p>
            EL CLIENTE: El domicilio que señala en la Solicitud de Apertura de
            {' '}
            Crédito correspondiente que forma parte de este Contrato.
          </p>
          <p>
            La ENTIDAD COMERCIAL: José Guadalupe Zuno 1745, Colonia Americana,
            {' '}
            C.P. 44160 Guadalajara, Jalisco.
          </p>
          <p>
            <b styleName="underline">
              TRIGÉSIMA. ACUERDO TOTAL.
            </b>
            {' '}
            El presente Contrato contiene el acuerdo total de las Partes con respecto al
            {' '}
            otorgamiento del Crédito, y reemplaza y cancela todos los acuerdos, negociaciones,
            {' '}
            pactos, documentos, cartas, cotizaciones y/o comunicaciones anteriores entre las
            {' '}
            partes, sean orales y/o escritos, con relación al objeto del presente Contrato.
          </p>
          <p>
            <b styleName="underline">
              TRIGÉSIMA PRIMERA. RENUNCIA DE DERECHOS.
            </b>
            {' '}
            La omisión por parte del CLIENTE en el ejercicio de los derechos previstos en este
            {' '}
            instrumento, en ningún caso tendrá el efecto de una renuncia a los mismos,
            {' '}
            de igual forma el ejercicio singular o parcial por parte de ENTIDAD COMERCIAL de dichos
            {' '}
            derechos, no excluye algún otro derecho, facultad o privilegio a favor del CLIENTE
            {' '}
            de acuerdo con lo previsto en el presente Contrato.
          </p>
          <p>
            <b styleName="underline">
              TRIGÉSIMA SEGUNDA. SUBTÍTULOS.
            </b>
            {' '}
            Los subtítulos en este Contrato son exclusivamente por conveniencia de las Partes y
            {' '}
            para mera referencia, por lo que no se considerarán para efectos de interpretación
            {' '}
            o cumplimiento del mismo.
          </p>
          <p>
            <b styleName="underline">
              TRIGÉSIMA TERCERA. PROFECO.
            </b>
            {' '}
            El número telefónico, dominio de la página de Internet y correo electrónico de
            {' '}
            atención al consumidor de la Procuraduría Federal del Consumidor a la fecha de
            {' '}
            redacción del presente contrato son: 55-68-87-22 en la Ciudad de México y en el área
            {' '}
            metropolitana y 01-800-468-87-22 desde el interior de la República, sin costo para
            {' '}
            el consumidor;
            {' '}
            <a href="https://www.gob.mx/profeco" target="_blank" rel="noopener noreferrer">
              www.profeco.gob.mx
            </a>
            {' '}
            y
            {' '}
            <a href="mailto:asesoria@profeco.gob.mx">
              asesoria@profeco.gob.mx.
            </a>
          </p>
          <p>
            <b>
              IV. AUTORIZACIONES
            </b>
          </p>
          <p>
            <b styleName="underline">
              TRIGESIMA CUARTA. AUTORIZACIÓN PARA LA TRANSFERENCIA DE DATOS A TERCEROS.
            </b>
            {' '}
            El CLIENTE autoriza expresamente a la ENTIDAD COMERCIAL a realizar las siguientes acciones:
          </p>
          <p>
            Compartir su información personal, nombre, domicilio y teléfonos, con prestadores de
            {' '}
            servicios operativos y de maquila, que sean necesarios para la operación del Crédito,
            {' '}
            así como a todos y cada uno de los miembros del grupo económico al que pertenece
            {' '}
            la ENTIDAD COMERCIAL y/o sus afiliadas.
          </p>
          <p>
            Cualquier afiliada de ENTIDAD COMERCIAL puede subsistirse a cualquier obligación
            {' '}
            de ENTIDAD COMERCIAL bajo este Contrato.
          </p>
          <p>
            <b styleName="underline">
              TRIGESIMA QUINTA. AUTORIZACIÓN PARA SOLICITAR Y PROPORCIONAR INFORMACIÓN CREDITICIA.
            </b>
            {' '}
            EL CLIENTE autoriza expresamente a la ENTIDAD COMERCIAL para que solicite, reporte
            {' '}
            y obtenga en cualquier momento y de cualquier Sociedad de Información Crediticia,
            {' '}
            la información sobre su historial crediticio, respecto de las operaciones crediticias y
            {' '}
            otras de naturaleza análoga, que lo integra, tal como se establece en la Solicitud de
            {' '}
            Autorización para Consulta En Sociedades de Información Crediticia autorizadas al
            {' '}
            momento de realizar la Solicitud.
          </p>
          <p>
            Así mismo, autoriza y acepta que el comportamiento de EL CLIENTE a las obligaciones
            {' '}
            de pago que a su cargo establece el presente Contrato, será reportado y actualizado
            {' '}
            por ENTIDAD COMERCIAL, a las Sociedades de Información Crediticia con las claves
            {' '}
            de observación establecidas en el reporte de Préstamo respectivo.
          </p>
          <p>
            <b styleName="underline">
              TRIGESIMA SEXTA. LEYES, JURISDICCIÓN Y COMPETENCIA.
            </b>
            {' '}
            Las Partes expresan e irrevocablemente convienen que, para la interpretación,
            {' '}
            ejecución y cumplimiento del presente Contrato las partes se sujetarán a la
            {' '}
            Ley Federal de Protección al Consumidor, a las Disposiciones de carácter general
            {' '}
            dadas a conocer por la Procuraduría Federal del Consumidor, así como a las demás
            {' '}
            disposiciones aplicables, conviniendo en someterse a las jurisdicción de los
            {' '}
            tribunales de Guadalajara Jalisco, renunciando al fuero que pudiera corresponderles
            {' '}
            en el presente o futuro en razón de su domicilio o por cualquier otra circunstancia.
          </p>
          <p>
            El presente Contrato y los datos de referencia consignados en la Solicitud de Apertura
            {' '}
            de Crédito de este instrumento, constituyen la expresión de la voluntad de las
            {' '}
            partes de quedar obligadas en los términos y condiciones aquí establecidas.
          </p>
          <p>
            <b>
              LEÍDO EL CONTENIDO DEL PRESENTE CONTRATO Y ENTERADAS LAS PARTES DE LAS OBLIGACIONES
              {' '}
              A LAS QUE SE SUJETAN, ASÍ COMO EL ALCANCE LEGAL DE LAS MISMAS, LO FIRMAN EN SEÑAL
              {' '}
              DE CONFORMIDAD Y DEMÁS EFECTOS LEGALES QUE CORRESPONDAN, OTORGANDO SU AUTORIZACIÓN
              {' '}
              EXPRESA PARA LAS ACCIONES SEÑALADAS EN LAS CLÁUSULAS TRIGÉSIMA CUARTA Y TRIGÉSIMA QUINTA.
            </b>
          </p>
          <p>
            <b>
              QUEDANDO EN PODER DE CADA UNA DE LAS PARTES UN EJEMPLAR ORIGINAL DE ESTE CONTRATO PARA
              {' '}
              CONSTANCIA DE LA OPERACIÓN QUE EN ESTE ACTO SE CELEBRA EN LA CIUDAD DE GUADALAJARA
              {' '}
              A LOS
              {' '}
              {today.format('DD')}
              {' '}
              DÍAS DEL MES DE
              {' '}
              {today.format('MMMM').toUpperCase()}
              {' '}
              DE
              {' '}
              {today.format('YYYY')}
              .
            </b>
          </p>
          <p>
            Contrato en proceso de inscripción en el Registro Público de Contrato de
            {' '}
            Adhesión de la Procuraduría Federal del Consumidor.
          </p>
        </div>
      </div>
    </section>
  );
}

AcceptContract.propTypes = {
  className: string,
  error: arrayOf(string),
  publicId: string,
  userInfo: shape({
    id: number,
  }),
  amortizationInfo: shape({
    amortization: shape({}),
  }),
  firstPaymentDate: string,
  loanRate: number,
  debtAmount: number,
  term: number,
};

export default AcceptContract;
