import moment from 'moment';

moment.updateLocale('es', {
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
});

moment.locale('es');

function getShortDate(day, format = 'YYYY/MM/DD') {
  const date = moment(day, format);
  return date.format('DD MMMM');
}

function getFortnights(date, months = 1, format = 'YYYY/MM/DD') {
  const forenights = [];
  let currentDate = moment(date, format);
  let i = 0;
  while (i < months) {
    forenights.push(currentDate.startOf('month'));
    forenights.push(moment([currentDate.year(), currentDate.month(), 16]));
    currentDate = moment(currentDate).add(1, 'M');
    i += 1;
  }

  return forenights;
}

function getPaymentDates() {
  const today = moment();
  const fortnights = getFortnights(today, 3);
  const paymentDates = [];
  for (let i = 0; i < fortnights.length; i += 1) {
    const date = fortnights[i];
    const diff = date.diff(moment([today.year(), today.month(), today.date()]), 'days');
    if (diff > 5) {
      // paymentDates.push({ value: date.format('YYYY-MM-DD'), text: getShortDate(date) });
      paymentDates.push({ value: date.format('YYYY-MM-DD') });
    }

    if (paymentDates.length === 2) {
      break;
    }
  }

  // sort by date number 1 should go before the 16
  paymentDates.sort((a, b) => moment(a.value).date() - moment(b.value).date());
  return paymentDates;
}

function getShorterPaymentDates() {
  const today = moment();
  const fortnights = getFortnights(today, 3);
  const paymentDates = [];
  for (let i = 0; i < fortnights.length; i += 1) {
    const date = fortnights[i];
    const diff = date.diff(moment([today.year(), today.month(), today.date()]), 'days');
    if (diff > 5) {
      paymentDates.push({ value: date.format('YYYY-MM-DD'), text: getShortDate(date) });
    }

    if (paymentDates.length === 2) {
      break;
    }
  }

  return paymentDates;
}

/**
 * Return a descriptive date format
 * @param {Moment} date
 * @return {String}
 */
export const descriptiveDate = date => (
  date.format('DD [de] MMMM [de] YYYY')
);

/**
 *
 * @params {String} date - Example: 21/01/2019
 * @params {String} parseFormat - Example: YYYY-MM-DD
 * @return {String}
 */
export const toResourcesDate = (date, parseFormat = 'YYYY-MM-DD') => (
  moment(date, parseFormat).format('DD/MMM/YYYY').replace('.', '')
);

export default {
  getPaymentDates,
  getFortnights,
  getShortDate,
  getShorterPaymentDates,
};
