import React, { Component } from 'react';
import { bool, oneOf } from 'prop-types';
import classnames from 'classnames';
import headShape from '@images/head-shape.svg';
import cardFrame from '@images/card-frame.svg';
import ine from '@images/ine.png';
import ineBack from '@images/ine-back.png';
import passport from '@images/passport.png';
import reload from '@images/reload.png';
import Button from '../Button';
import { base64toBlobFile, trackCameraEvents } from '../../lib/util';

import './styles.scss';

class DesktopCameraInput extends Component {
  static propTypes = {
    notInitCamera: bool,
    isBack: bool,
    disableButton: bool,
    contentEncoding: oneOf(['blob', 'base64']),
  }

  static defaultProps = {
    notInitCamera: false,
    isBack: false,
    contentEncoding: 'blob',
  }

  state = {
    hasError: false,
    hasPicture: false,
    blockButtons: false,
    file: null,
    showSketch: false,
  }

  videoStream = null;

  _isMounted = false;

  componentDidMount = () => {
    const { notInitCamera } = this.props;
    this._isMounted = true;

    if (!notInitCamera) {
      this.onEnableCamera();
    } else {
      this.setState({
        showSketch: true,
      });
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
    this.onPauseCamera();
  }

  onEnableCamera = () => {
    const { frameType, isBack } = this.props;
    const cameraComponent = document.querySelector('.camera-component');
    const video = cameraComponent.querySelector('video');
    const constraints = {
      video: {
        facingMode: 'environment',
      },
      audio: false
    };

    if (this._isMounted) {
      this.setState({
        blockButtons: true,
      });

      navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.videoStream = stream;
        video.srcObject = stream;

        if (this._isMounted) {
          this.setState({
            hasPicture: false,
            blockButtons: false,
            showSketch: false,
          });
        }
      })
      .catch((e) => {
        if (this._isMounted) {
          this.setState({ hasError: true });
        }
      });
    }

    trackCameraEvents({ frameType, isBack, enableCamera: true });
  }

  onPauseCamera = () => {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  resetIDFlow() {
    this.setState({
      hasError: false,
      hasPicture: false,
      blockButtons: false,
      file: null,
      showSketch: true,
    });
    this.onPauseCamera();
  }

  getPicture = () => {
    const { frameType, isBack, contentEncoding } = this.props;
    const cameraComponent = document.querySelector('.camera-component');
    const canvas = cameraComponent.querySelector('canvas');
    const video = cameraComponent.querySelector('video');
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const image = canvas.toDataURL();

    let file = image.replace('data:image/png;base64,', '')

    if (contentEncoding === 'blob') {
      file = base64toBlobFile(file, 'image/png');
    }

    if (this._isMounted) {
      this.setState({ hasPicture: true, file, });
    }

    trackCameraEvents({ frameType, isBack, enableCamera: false });
  }

  getFrame = () => {
    const { frameType, isBack } = this.props;
    const { showSketch } = this.state;
    const containerClasses = classnames({
      'card-frame-container': true,
      transparent: !showSketch,
    });
    switch(frameType) {
      case 'INE':
        return (
          <div styleName={containerClasses}>
            {showSketch && <img src={isBack ? ineBack : ine} alt="INE" styleName="document" />}
            <img src={cardFrame} alt="Card Frame" styleName="card-frame" />
          </div>
        );
      case 'PASSPORT':
        return (
          <div styleName={containerClasses}>
            {showSketch && <img src={passport} alt="Passport" styleName="document passport" />}
            <img src={cardFrame} alt="Card Frame" styleName="card-frame" />
          </div>
        );
      case 'HEAD':
      default:
        return <img src={headShape} alt="Head Shape" styleName="head-shape" />;
    }
  }

  next = () => {
    const { onSucces } = this.props;
    const { file } = this.state;
    onSucces(file);
  }

  onReload = () => {
    this.setState({
      hasPicture: false,
      file: null,
    });
  }

  render() {
    const { disableButton } = this.props;
    const { hasPicture, hasError, blockButtons, showSketch } = this.state;

    return (
      <div className="camera-component">
        {!hasError && (
          <React.Fragment>
            <video styleName={`video ${!hasPicture ? 'block' : 'none'}`} autoPlay playsInline />
            <canvas width={440} height={330} styleName={`${hasPicture ? 'block' : 'none'}`} />
            {hasPicture && <img onClick={this.onReload} src={reload} alt="reload" styleName="reload" />}
          </React.Fragment>)}
        {!hasError && !hasPicture && this.getFrame()}
        {hasError && (
          <div styleName="error">
            <span>
              Hubo un error tratando de habilitar la cámara. Por favor carga de nuevo esta página y asegúrate de permitir al navegador usar tu cámara.
            </span>
          </div>)}

        {showSketch && (
          <div>
            <Button
              id="id_next_button"
              type="button"
              color="primary"
              onClick={this.onEnableCamera}
              disabled={blockButtons}
            >
              Siguiente
            </Button>
          </div>
        )}
        {!showSketch && !hasPicture && !hasError && (
          <div>
            <Button
              id="take_picture"
              type="button"
              color="primary"
              onClick={this.getPicture}
              disabled={blockButtons}
            >
              Tomar foto
            </Button>
          </div>
        )}
        {hasPicture && (
          <div>
            <Button
              id="id_picture_success"
              type="button"
              color="primary"
              onClick={this.next}
              disabled={disableButton}
            >
              Siguiente
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default DesktopCameraInput;
