function normalizeClabe(value, previousValue) {
  const newValue = value.replace(/\s/g, '');

  if (!newValue) {
    return '';
  }

  const isInteger = /^[0-9]*$/.test(newValue);

  if (!isInteger || newValue.length > 18) {
    return previousValue;
  }

  const formattedValue = newValue.length > 3
    ? [newValue.slice(0, 3), ' ', newValue.slice(3)].join('')
    : newValue;

  return formattedValue;
}

export default {
  normalizeClabe,
};
