import apiUtils from '@utils/apiUtils';
import enums from '../../constants/enums';

function callApi(action) {
  const request = {
    endpoint: action.endpoint || '',
    method: action.method || '',
    body: action.body,
    params: action.params,
    acceptHeader: action.acceptHeader,
    contentType: action.contentType,
  };

  return apiUtils.callApi(request);
}

const jwtApiCall = store => next => (action) => {
  const request = action[enums.middleware.CALL_API];

  if (typeof request === 'undefined') {
    return next(action);
  }

  // TODO: remove store
  return callApi(request, next, store);
};

export default { jwtApiCall };
