import React, { Component } from 'react';
import {
  arrayOf, string, func, number, bool, shape,
} from 'prop-types';
import googleAnalytics from '@lib/googleAnalytics';
import { isMobile } from 'react-device-detect';
import {
  ErrorMessage, DesktopCameraInput, MobileCameraInput,
  OverlayMessage,
} from '@components';
import './style.scss';

class Selfie extends Component {
  state = {
    showOverlay: false,
  }

  cameraElement = null;

  componentDidMount() {
    const { getIdValidationInfo, loan } = this.props;
    googleAnalytics.visitPage('set-selfie-photo-info');
    getIdValidationInfo({ idLoan: loan });
  }

  componentWillUnmount() {
    this.pauseCamera();
  }

  onSucces = (file) => {
    const { uploadIdentityFile, idValidationInfo } = this.props;

    this.setState({
      showOverlay: false,
    });

    uploadIdentityFile({
      file,
      url: idValidationInfo.selfie,
    },
    () => {
      const form = document.querySelector('form');
      const button = document.createElement('button');
      button.type = 'submit';
      form.appendChild(button);
      button.click();
      form.removeChild(button);
    }, () => {
      this.setState({
        showOverlay: true,
      });
    });
  }

  tryAgain = () => {
    window.location.reload();
  }

  setCameraElementRef = (element) => {
    this.cameraElement = element;
  }

  pauseCamera = () => {
    if (this.cameraElement && this.cameraElement.onPauseCamera) {
      this.cameraElement.onPauseCamera();
    }
  }

  render() {
    const {
      className, error, isFetching, isFetchingIdValidationInfo,
    } = this.props;
    const { showOverlay } = this.state;
    return (
      <section styleName="step-form step-06" className={className}>
        <h1>Tómate una selfie:</h1>
        <ErrorMessage error={error} />
        <div styleName="camera-input-container">
          {!isMobile
            && (
              <DesktopCameraInput
                frameType="HEAD"
                onSucces={this.onSucces}
                disableButton={isFetching || isFetchingIdValidationInfo}
                ref={this.setCameraElementRef}
              />
            )}
          {isMobile
            && (
              <MobileCameraInput
                frameType="HEAD"
                onSucces={this.onSucces}
                disableButton={isFetching || isFetchingIdValidationInfo}
              />
            )}
        </div>
        {showOverlay && <div><OverlayMessage onButtonClick={this.tryAgain} /></div>}
      </section>
    );
  }
}

Selfie.propTypes = {
  className: string,
  error: arrayOf(string),
  uploadIdentityFile: func,
  loan: number,
  isFetching: bool,
  isFetchingIdValidationInfo: bool,
  idValidationInfo: shape({}),
  getIdValidationInfo: func,
};

export default Selfie;
