import React from 'react';
import queryString from 'query-string';
import { Router, Switch, Route } from 'react-router-dom';
import DigitalSignature from '@pages/DigitalSignature';
import GuaranteedLoan from '@pages/GuaranteedLoan';

import NewClabe from '@pages/NewClabe';
import ResetPassword from '@pages/ResetPassword';
import ForgotPassword from '@pages/ForgotPassword';

import { Page } from '@components';
import ErrorBoundary from './errorBoundary';

export default params => (
  <Router history={params.history}>
    <ErrorBoundary>
      <Switch>
        <Route exact path="/">
          {
            () => {
              const qs = queryString.parse(window.location.search);

              if (qs.token) {
                params.history.push(`/obtener-credito?token=${qs.token}&email=${qs.email || ''}`);
              } else {
                window.location.assign(process.env.DASHBOARD_APP_URL);
              }

              return '';
            }
          }
        </Route>
        <Page exact path="/obtener-credito" component={DigitalSignature} showMenu />
        <Page exact path="/obtener-credito/:credit" component={DigitalSignature} showMenu />

        <Page exact path="/obtener-credito-garantizado" component={GuaranteedLoan} showMenu />
        <Page exact path="/obtener-credito-garantizado/:credit" component={GuaranteedLoan} showMenu />

        <Page exact path="/recuperar-password" component={ForgotPassword} hideStepTracking />
        <Page exact path="/cambiar-password" component={ResetPassword} hideStepTracking />
        <Page exact path="/:id(\d+)/nueva-clabe" component={NewClabe} hideStepTracking showMenu isNewClabe />
        <Route path="*">
          {
            () => {
              window.location.assign(process.env.DASHBOARD_APP_URL);

              return '';
            }
          }
        </Route>
      </Switch>
    </ErrorBoundary>
  </Router>
);
