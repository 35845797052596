/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { arc } from 'd3-shape';
import { animate } from '../../lib/util';
import './style.scss';

function toRad(deg) {
  return (deg * Math.PI) / 180;
}

class TinyCircle extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    currentStep: PropTypes.number,
    totalSteps: PropTypes.number,
  };
  constructor(props) {
    super(props);
    const { currentStep, totalSteps } = this.props;
    const percentage = currentStep / totalSteps;

    this.state = {
      endChart: 360 * percentage,
    };
  }

  componentWillUpdate(nextProps) {
    const { currentStep, totalSteps } = nextProps;
    const percentage = currentStep / totalSteps;

    if (nextProps.currentStep !== this.props.currentStep) {
      if (typeof this.timer === 'function') {
        this.timer();
      }

      this.timer = animate((value) => {
        this.setState({ endChart: value });
      }, this.state.endChart, 360 * percentage, 1000);
    }
  }

  render() {
    const { id, className, currentStep, role, totalSteps } = this.props;
    const { endChart } = this.state;
    const border = 2;
    const dArc = arc()
      .innerRadius(50 - border)
      .outerRadius(50)
      .cornerRadius(0)
      .startAngle(0)
      .endAngle(toRad(endChart));

    return (<svg id={id} role={role} className={className} styleName="tiny-circle-chart" version="1.1" viewBox="0 0 130 130" preserveAspectRatio="xMinYMin meet">
      <path fill="#6b7c93" strokeWidth="0" opacity="0.2" transform="translate(65,50)" d="M3.061616997868383e-15,-50A50,50,0,1,1,-3.061616997868383e-15,50A50,50,0,1,1,3.061616997868383e-15,-50M-8.817456953860943e-15,-48A48,48,0,1,0,8.817456953860943e-15,48A48,48,0,1,0,-8.817456953860943e-15,-48Z" />
      <path fill="#00b3e6" strokeWidth="0" transform="translate(65,50)" d={dArc()} />
      <text styleName="center-text" x="65" y="63" textAnchor="middle">{currentStep}</text>
      <text x="65" y="120" textAnchor="middle">{currentStep} / {totalSteps}</text>
    </svg>);
  }
}

export default TinyCircle;
