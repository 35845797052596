import React, { Component } from 'react';
import {
  arrayOf, string, bool, func, shape,
} from 'prop-types';
import { isEmpty } from 'lodash-es';
import normalizers from '@utils/normalizers';
import googleAnalytics from '@lib/googleAnalytics';
import { calculateClabeCheckDigit } from '@lib/validate';
import clabe from '@/constants/clabe';
import {
  TextField, Button, ErrorMessage,
} from '@components';
import './style.scss';

class Bank extends Component {
  state = {
    showClabeErrorText: false,
    showErrorOnBeActive: false,
  }

  componentDidMount() {
    googleAnalytics.visitPage('set-bank-account');
  }

  onClabeChange = (e) => {
    const { target: { value } } = e;
    const { change } = this.props;
    const newValue = value.replace(/\s/g, '');
    change('bank', '');
    if (newValue.length >= 3) {
      const prefix = newValue.substr(0, 3);
      const selectedBank = clabe[prefix] || '';
      change('bank', selectedBank);
    }
    if (newValue.length === 18) {
      const lastDigit = parseInt(newValue.substr(17, 18), 10);
      const checkDigit = parseInt(calculateClabeCheckDigit(newValue), 10);
      if (lastDigit !== checkDigit) {
        this.setState({ showClabeErrorText: true });
      } else {
        this.setState({ showClabeErrorText: false });
      }
    }
    if (newValue.length > 0 && newValue.length < 18) {
      this.setState({ showErrorOnBeActive: true });
    } else {
      this.setState({ showErrorOnBeActive: false });
    }
  }

  render() {
    const {
      className, valid, loading, error, formValues, isNewClabe,
    } = this.props;
    const { showClabeErrorText, showErrorOnBeActive } = this.state;
    const { bank } = formValues || {};

    return (
      <section id="bank-page" styleName="step-form step-05" className={className}>
        <h1>
          Ingresa tu
          {isNewClabe ? ' nueva ' : ' '}
          CLABE interbancaria.
        </h1>
        <p className="title-description">
          Ésta deberá ser la CLABE de tu cuenta de débito a la que
          {' '}
          haremos los cargos mensuales del pago de tu crédito Digitt.
        </p>
        <p className="title-description">
          La cuenta deberá estar a tu nombre y le haremos un abono de un centavo para poder verificarla.
        </p>
        <br />
        <p className="title-description">
          <span className="strong">
            NOTA:
            {' '}
          </span>
          Puedes encontrarla en tus estados de cuenta de tu cuenta de débito.
          O también, puedes consultarla a través de tu banca en línea o el app de tu banco.
          Si de plano no la encuentras, comunícate con tu banco y ellos te la tendrán que proporcionar.
        </p>
        <ErrorMessage error={error} />
        <div className="row" styleName="inputs-container">
          <TextField
            styleName="input-field"
            className="col-4"
            labelText="CLABE Interbancaria (18 dígitos)"
            name="clabe"
            id="clabe"
            type="text"
            autoFocus
            onChange={this.onClabeChange}
            normalize={normalizers.normalizeClabe}
            showErrorOnBeActive={showErrorOnBeActive}
          />
          {showClabeErrorText && (
            <div className="col-4" styleName="error error-text error-text-clabe">
              Por favor ingresa una CLABE válida.
            </div>
          )}
          <TextField
            styleName="input-field bank"
            className="col-4"
            labelText="Banco"
            name="bank"
            id="bank"
            type="text"
            isActive={!isEmpty(bank)}
            disabled
          />
          <div styleName="container-btn-next">
            <Button
              id="submit"
              styleName="btn-next"
              type="submit"
              color="primary"
              spinner={loading}
              disabled={loading || !valid || !formValues.clabe}
            >
              Siguiente
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

Bank.propTypes = {
  className: string,
  valid: bool,
  loading: bool,
  error: arrayOf(string),
  change: func,
  formValues: shape({
    bank: string,
  }),
  isNewClabe: bool,
};

export default Bank;
