import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const TableRow = (props) => {
	const {
		className,
		children,
    isOdd,
		isExpanded,
		onClick,
	} = props;

	const classes = classnames({
		'table-row': true,
    'odd': isOdd,
		'expanded': isExpanded,
		[className]: className !== null,
	});

	return (
		<tr styleName={classes} onClick={onClick}>
			{children}
		</tr>
	);
};

TableRow.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
  isExpanded: PropTypes.bool,
  isOdd: PropTypes.bool,
};

TableRow.defaultProps = {
	className: null,
  isExpanded: false,
  isOdd: false,
};

export default TableRow;
