import React, { Component } from 'react';
import {
  shape, string, bool, func, arrayOf,
} from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import googleAnalytics from '@lib/googleAnalytics';
import { Button, ErrorMessage } from '@components';
import './style.scss';
/* eslint-disable prefer-destructuring */

class PaymentDaySelection extends Component {
  componentDidMount() {
    googleAnalytics.visitPage('set-first-payment-date');
  }

  onSelect = (e) => {
    const { change } = this.props;
    let payDay = e.target.dataset.payDay;

    if (!payDay) {
      payDay = e.target.parentElement.dataset.payDay;
    }

    change('firstPaymentDate', payDay);
  }

  render() {
    const {
      className, loading, formValues, error, paymentDayOptions,
    } = this.props;
    return (
      <section id="payment-day-selection" styleName="step-form step-03" className={className}>
        <h1>¿Qué día prefieres tener tu fecha de pago?</h1>
        <p className="title-description">
          Te recomendamos que tomes en cuenta cuando recibes tus ingresos,
          {' '}
          para que sepas cuando te será más fácil pagar.
        </p>
        <ErrorMessage error={error} />
        <div styleName="inputs-container">
          <div id="date-options" className="row" styleName="block-options-container">
            {paymentDayOptions.map((date) => {
              const classes = classnames({
                'block-option': true,
                active: formValues.firstPaymentDate === date,
              });

              const value = moment(date, 'YYYY-MM-DD');
              const zeroFormatted = `0${value.date()}`.slice(-2);

              return (
                <button
                  key={date}
                  type="button"
                  styleName={classes}
                  data-pay-day={date}
                  onClick={this.onSelect}
                >
                  Los días
                  {` ${zeroFormatted} `}
                  de cada mes
                </button>
              );
            })}
          </div>
          <div className="row">
            <Button
              id="submit"
              styleName="btn-next"
              type="submit"
              color="primary"
              spinner={loading}
              disabled={loading || !formValues.firstPaymentDate}
            >
              Siguiente
            </Button>
          </div>
        </div>
      </section>
    );
  }
}

PaymentDaySelection.propTypes = {
  className: string,
  loading: bool,
  formValues: shape({
    firstPaymentDate: string,
  }).isRequired,
  paymentDayOptions: arrayOf(string),
  change: func,
  error: arrayOf(string),
};

export default PaymentDaySelection;
