import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import apiUtils from '@utils/apiUtils';
import dateUtils from '@utils/dateUtils';
import { Button } from '@components';
import Mixpanel from '@lib/mixpanel';
import './style.scss';
/* globals API_URL */

function PaymentDaySelection(props) {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const dates = dateUtils.getPaymentDates();
  const { onValueChange } = props;
  const {
    // eslint-disable-next-line react/prop-types
    className, onNext, loanId,
  } = props;
  const onSelect = (e) => {
    let { payDay } = e.target.dataset;

    if (!payDay) {
      // eslint-disable-next-line prefer-destructuring
      payDay = e.target.parentElement.dataset.payDay;
    }
    setValue(payDay);

    if (onValueChange) {
      onValueChange('firstPaymentDate', payDay);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await apiUtils.callApi({
        endpoint: `${API_URL}/set-loan-first_payment_date`,
        method: 'POST',
        body: {
          id: loanId,
          date: value,
        },
      });
      Mixpanel.track('app_guaranteed_set_payment_day_step');
      onNext();
    } catch (error) {
      // error 400 if not valid 405 if signed
      setLoading(false);
    }
  };

  return (
    <section id="payment-day-selection" styleName="step-form step-03" className={className}>
      <h1>¿Qué día prefieres tener tu fecha de pago?</h1>
      <p className="title-description">
        Te recomendamos que tomes en cuenta cuando recibes tus ingresos,
        {' '}
        para que sepas cuando te será más fácil pagar.
      </p>
      <div styleName="inputs-container">
        <div id="date-options" className="row" styleName="block-options-container">
          {dates.map((date) => {
            const classes = classnames({
              'block-option': true,
              active: value === date.value,
            });
            const format = 'YYYY-MM-DD';
            const dateval = moment(date.value, format);

            const zeroFormatted = `0${dateval.date()}`.slice(-2);

            return (
              <button
                key={date.value}
                type="button"
                styleName={classes}
                data-pay-day={dateval.format(format)}
                onClick={onSelect}
                loading={loading}
              >
                Los días
                {` ${zeroFormatted} `}
                de cada mes
              </button>
            );
          })}
        </div>
        <div className="row">
          <Button
            id="submit"
            styleName="btn-next"
            type="submit"
            color="primary"
            spinner={loading}
            // eslint-disable-next-line react/prop-types
            disabled={loading || value === ''}
            onClick={onSubmit}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </section>
  );
}

PaymentDaySelection.propTypes = {
  onValueChange: PropTypes.func,
  onNext: PropTypes.func,
  loanId: PropTypes.number,
};

export default PaymentDaySelection;
