import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

function Button(props) {
  const {
    className,
    children,
    color,
    disabled,
    onClick,
    spinner,
    ...others
  } = props;

  const classes = classnames({
    btn: true,
    [`btn-${color}`]: !!color,
  });

  return (
    <button
      {...others}
      styleName={classes}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {!spinner && <span>{children}</span>}
      {spinner && <span><i styleName="spinner" /></span>}
    </button>);
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Button;
