import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import useSWR from 'swr';
import { isEmpty } from 'lodash-es';
import loanFetcher from '../../libs/api-loans';
import PaymentDaySelection from './components/PaymentDaySelection';
import Selfie from './components/Selfie';
import CreditCardForm from './components/CreditCardForm';
import IDPicture from './components/IDPicture';
import AmortizationTable from './components/AmortizationTable';
import Bank from './components/Bank';
import Signature from './components/Signature';
import TermsSelection from './components/TermsSelection';
import Contracts from './components/Contracts';
import SetPasswordForm from './components/SetPasswordForm';
import PasswordConfirmation from './components/PasswordConfirmation';
import FinalScreen from './components/FinalScreen';
/* globals API_URL */

const steps = {
  0: PaymentDaySelection,
  1: TermsSelection,
  2: AmortizationTable,
  3: Bank,
  4: CreditCardForm,
  5: Selfie,
  6: IDPicture,
  7: Contracts,
  8: Signature,
  9: PasswordConfirmation,
  10: FinalScreen,
};

function getStep() {
  try {
    const step = parseInt(window.localStorage.getItem('step'), 10) || 0;
    // password confirmation should require the signature again
    if (step === 9) {
      return 8;
    }
    return step;
  } catch (error) {
    return 0;
  }
}

function GuaranteedLoanFlow(props) {
  const {
    loanId,
    token,
    history,
  } = props;
  const [formValues, setFormValues] = useState({});
  const { data, error } = useSWR([`${API_URL}/get-loan-details/${loanId}`, token], loanFetcher(token));
  const [step, setStep] = useState(getStep());
  if (error) {
    return (<div>failed to load</div>);
  }
  if (!data) {
    return <div>cargando...</div>;
  }

  const onValueChange = (key, value) => {
    const newValues = {
      ...formValues,
    };
    newValues[key] = value;
    setFormValues(newValues);
  };

  const onNext = () => {
    setStep(step + 1);
    try {
      if (typeof steps[step + 1] === 'undefined') {
        // reset
        window.localStorage.setItem('step', 0);
      } else {
        window.localStorage.setItem('step', step + 1);
      }
    } catch (err) {
      // ignore error
    }
  };

  if (token === null) {
    history.push('/login');
    return (<div>&nbsp;</div>);
  }
  const StepForm = steps[step];

  return (
    <div>
      <StepForm
        onValueChange={onValueChange}
        onNext={onNext}
        token={token}
        loan={data.loan}
        loanId={data.loan.id}
        loanRequest={data.loan.loan_request}
        user={data.user}
        publicId={data.loan.public_id}
        amortizationInfo={data.loan}
        className="step-form"
        history={history}
        formValues={formValues}
        debtAmount={data.loan.debt_ammount}
        isNewClabe={isEmpty(data.loan.clabe)}
      />
    </div>
  );
}

GuaranteedLoanFlow.propTypes = {
  loanId: PropTypes.number,
  token: PropTypes.string,
};

export default function GuaranteedLoan(props) {
  const {
    history,
    match,
  } = props;
  const { credit } = match.params;
  const qdata = queryString.parse(window.location.search);
  let { token = null } = qdata;
  if (typeof credit === 'undefined' && token) {
    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      if (tokenData.loan) {
        history.push(`/obtener-credito-garantizado/${tokenData.loan}?token=${qdata.token}&email=${qdata.email}`);
        return (<div>&nbsp;</div>);
      }
    } catch (error) {
      history.push('/');
      return (<div>&nbsp;</div>);
    }
  }

  const auth = useSelector(store => store.auth);
  const digitalSignature = useSelector(store => store.digitalSignature);

  token = token || auth.token;
  const {
    loading,
    isFetching,
  } = digitalSignature;
  const { data, error } = useSWR([`${API_URL}/get-loan-details`, token], loanFetcher(token));

  if (token === null) {
    history.push('/');
    return (<div>&nbsp;</div>);
  }

  if (error) {
    if (error.status === 405) {
      return (
        <div>
          <SetPasswordForm
            history={history}
            loading={loading}
            valid={false}
            isFetching={isFetching}
            token={token}
            loanId={credit}
          />
        </div>
      );
    }
    if (error.status === 404) {
      history.push('/');
      return (<div>cargando...</div>);
    }
    return (<div>Hubo un error creando tu contraseña</div>);
  }

  if (!data) {
    return <div>cargando...</div>;
  }

  return (
    <div>
      <GuaranteedLoanFlow
        loanId={credit}
        token={auth.token}
        history={history}
      />
    </div>
  );
}
