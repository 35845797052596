import React, { Component } from 'react';
import { func, bool, shape, string } from 'prop-types';
import { reduxForm } from 'redux-form';
import { Modal, ModalBody, ModalFooter } from '../Modal';
import Button from '../Button';
import TextField from '../TextField';
import Icon from '../Icon';
import ErrorMessage from '../ErrorMessage';

import './style.scss';

@reduxForm({
  form: 'confirmPasswordForm',
  validate: (values) => {
    const errors = {};
    if(!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  }
})
class PasswordModal extends Component {
  state = {
    textPassword: false,
  }

  onSubmit = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  showPassword = () => {
    const { textPassword } = this.state;
    this.setState({ textPassword: !textPassword });
  }

  render() {
    const { handleSubmit, valid, passwordModal: { loading, error }, onForgotPasswordClick } = this.props;
    const { textPassword } = this.state;
    return (
      <Modal id="password-modal" close onClose={this.onClose}>
        <form onSubmit={handleSubmit(this.onSubmit)} styleName="modal-form">
          <ModalBody>
            <p styleName="title">
              Por tu seguridad, necesitamos que ingreses tu contraseña para finalizar el proceso.
            </p>
            <ErrorMessage error={[error]} />
            <div styleName="input-container">
              <TextField
                labelText="Contraseña"
                name="password"
                id="password"
                type={textPassword ? 'text' : 'password'}
                rightIcon={<Icon type={textPassword ? 'viewBlue' : 'view'} height="17" onClick={this.showPassword} />}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              id="submit"
              type="submit"
              color="primary"
              spinner={loading}
              disabled={loading || !valid}
            >
              Aceptar
            </Button>
            <div>
              <div onClick={onForgotPasswordClick} styleName="link">¿Olvidaste tu contraseña?</div>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

PasswordModal.propTypes = {
  handleSubmit: func,
  valid: bool,
  passwordModal: shape({
    loading: bool,
    error: string,
  }),
  onSubmit: func,
  onClose: func.isRequired,
  onForgotPasswordClick: func.isRequired,
};

PasswordModal.defaultProps = {
  close: false,
};

export default PasswordModal;
