import React from 'react';
import { shape, string } from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import './style.scss';

moment.locale('es');

const Terms = (props) => {
  const { paymentsData, firstPaymentDate } = props;

  const date = moment(firstPaymentDate, 'YYYY-MM-DD');

  const {
    hasDifferentAmounts, paymentsCount, monthlyPaymentAmount, lastPaymentAmount,
  } = paymentsData;

  if (hasDifferentAmounts) {
    return (
      <React.Fragment>
        <tr styleName="terms-info">
          <td>
            {`${paymentsCount - 1} meses`}
          </td>
          <td>
            {numeral(monthlyPaymentAmount).format('$0,0.00')}
            <br />
            Pesos Incluye Iva
          </td>
          <td colSpan="3" rowSpan="2">
            El día
            {' '}
            <span styleName="label">{date.format('DD')}</span>
            {' '}
            de cada mes durante todo el plazo del crédito.
          </td>
        </tr>
        <tr styleName="terms-info">
          <td>
            1 mes
          </td>
          <td>
            {numeral(lastPaymentAmount).format('$0,0.00')}
            <br />
            Pesos Incluye Iva
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <tr styleName="terms-info">
      <td>
        {`${paymentsCount} meses`}
      </td>
      <td>
        {numeral(monthlyPaymentAmount).format('$0,0.00')}
        <br />
        Pesos Incluye Iva
      </td>
      <td colSpan="3">
        El día
        {' '}
        <span styleName="label">{date.format('DD')}</span>
        {' '}
        de cada mes durante todo el plazo del crédito.
      </td>
    </tr>
  );
};

Terms.propTypes = {
  firstPaymentDate: string,
  paymentsData: shape({}),
};

export default Terms;
