import querystring from 'querystring';

function mapStateToProps(state, props) {
  const { digitalSignature } = state;
  const {
    location: { search },
  } = props;

  const searchFormatted = search[0] === '?' ? search.substr(1) : search;
  const urlValues = querystring.parse(searchFormatted);

  return {
    digitalSignature,
    initialValues: {
      email: urlValues.email,
    },
    token: urlValues.token,
  };
}

export default mapStateToProps;
